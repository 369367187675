import React from "react";
import {
  Layout,
  Menu,
  Form,
  Button,
  Select,
  Dropdown,
  Row,
  Col,
  Card,
  Modal,
  Table,
  Tooltip,
  Spin,
  notification,
} from "antd";
import {
  CaretDownOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { api } from "../../../apiServices/apiServices";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Constants } from "../../../constants/constants";
import Editor from "../../Editor/Editor";
import { mailRecipients } from "../../../constants/mailRecipients";
import { fileURLToPath } from "url";
const { Option } = Select;
const { Header, Content } = Layout;

class UpcomingAudits extends React.Component<any, any> {
  navigateToLogin(e: any) {
    api.logOut();
  }

  menu = (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          this.props.history.push("/auditordashboard");
        }}
      >
        Auditor Dashboard
      </Menu.Item>
    </Menu>
  );
  formRef = React.createRef<FormInstance>();
  userData: any = "";
  loading: boolean = false;
  pendingStatusProject: any = [];
  allPendingProjects: any = [];

  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
      rolename: "",
      allAuditStatuses: [],
      filteredarray: [],
      popoverDisplay: false,
      toggle: true,
      isEnable: true,
      selectedAuditStatus: null,
      selectedProject: null,
      auditstatuses: [],
      emailSubject: "",
      emailBody: "",
      error: { errormessage: "", auditstatusId: null },
      show: false,
      loading: true,
      visible: false,
      shareVisible: false,
      NC: 0,
      PC: 0,
      OK: 0,
      NA: 0,
      BNC: 0,
      projectInfo: [],
      projectID: "",
      changedQuestions: [],
      isReAudit: "",
      projectDetails: [],
      fileName: "",
      isDisable: true,
      projectsForReports: [],
      emailTemplate: "hi",
      selectedFile: "",
      hidden: 0,
      auditeeName: "",
      auditorName: "",
      auditeeEmail: "",
      resetSelect: false,
      updatedEmailList: [],
      updatedEmail: [],
      projectList: [],
      pendingProjects: [],
      pendingProjectList: [],
      allPendingProjects: [],
      tempPendingProjects: [],
    };
  }

  async componentDidMount() {
    this.getAllProjects();
  }

  getAllProjects = async () => {
    this.setState({ show: true });
    var allAuditStatuses = [];
    this.pendingStatusProject = [];

    allAuditStatuses = await api.getAllAuditStatus();

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token) || {};

      if (
        this.userData?.userRole === Constants.roleId.auditor ||
        this.userData?.userRole === Constants.roleId.admin
      ) {
        this.setState({ isEnable: false });
        var projectlist: any = localStorage.getItem("allProjects");
        this.setState({ projectList: JSON.parse(projectlist) });
        this.setState({ pendingProjectList: JSON.parse(projectlist) });
      }
    }
    this.setState({
      show: false,
      name: this.userData ? this.userData.name : "",
      username: this.userData ? this.userData.email : "",
      rolename: this.userData ? this.userData.roleName : "",
      profilePic: this.userData ? this.userData.profilePic : "",
      allAuditStatuses: allAuditStatuses,
      selectedProject: 0,
      selectedAuditStatus: 0,
      roleId: this.userData ? this.userData.userRole : null,
    });

    this.formRef.current?.setFieldsValue({
      selectedProject: 0,
      selectedAuditStatus: 0,
    });

    this.state.pendingProjectList.map(async (x: any) => {
      if (x.auditstatusId === 1 || x.auditstatusId === 2) {
        this.pendingStatusProject.push(x);
      }
    });
    this.state.projectList.map(async (x: any) => {
      if (x.auditstatusId === 1 || x.auditstatusId === 2) {
        this.allPendingProjects.push(x);
      }
    });
    this.setState({
      allPendingProjects: this.allPendingProjects,
      pendingProjects: this.pendingStatusProject,
      tempPendingProjects: this.pendingStatusProject,
    });
  };

  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    projectName: any,
    Auditee: any,
    auditTypeName: any,
    auditState: any
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        projectName: projectName,
        Auditee: Auditee,
        auditTypeName: auditTypeName,
        auditState: auditState,
      },
    });
  };

  showModal = async () => {
    this.setState({
      visible: true,
    });
  };

  dashBoard = async () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  handleOk = (e: any) => {
    this.setState({
      visible: false,
      selectedValue: null,
      projectDetails: [],

      resetSelect: true,
    });
  };

  handleCancel = (e: any) => {
    this.setState({
      selectedValue: null,
      projectDetails: [],

      visible: false,
      resetSelect: true,
    });
  };
  setUpdatedEmailList = (updatedList: any) => {
    this.setState({
      updatedEmailList: updatedList,
    });
  };

  reportAuditStatushandleChange = async (value: any) => {
    this.setState({ reportauditstatus: value });
  };

  projectListing() {
    return (
      <div className="site-card-wrapper">
        <Row gutter={[16, 24]}>
          {this.state.pendingProjects.map((project: any, index: number) => {
            let auditee = "";
            let projectTeams = JSON.parse(project.ProjectTeam);
            projectTeams?.map((team: any, index: number) => {
              if (Number(project.Auditee) === Number(team.id)) {
                auditee = team.Name;
              }
            });
            this.loading = false;
            return (
              <Col
                key={project.Audit_Id}
                className="gutter-row"
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={6}
              >
                <Card
                  key={project.Audit_Id}
                  size="small"
                  hoverable
                  className="ant-card card-user h-100 p-0 custom-card"
                  title={
                    <div className="d-flex">
                      <div className="flex-fill text-ellipse">
                        <Tooltip
                          title={`${project.Name} - ${
                            project.Audit_Type_Name && project.AuditTypeId < 4
                              ? project.Audit_Type_Name
                              : ""
                          }${
                            project.Audit_State ? " " + project.Audit_State : ""
                          }`}
                        >
                          <span>
                            <b>
                              {project.Name} -{" "}
                              {project.Audit_Type_Name &&
                              project.AuditTypeId < 4
                                ? project.Audit_Type_Name
                                : ""}
                              {project.Audit_State
                                ? "  " + project.Audit_State
                                : ""}
                            </b>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  }
                  bordered={false}
                  loading={this.loading}
                >
                  <div className="d-flex px-3 py-2">
                    {project.AuditDate ? (
                      <div className="flex-fill mr-2">
                        <p className="m-0">
                          <b className="d-block">Audit Date: </b>{" "}
                          {project.AuditDate
                            ? moment(project.AuditDate).format("DD MMM YYYY")
                            : ""}{" "}
                        </p>
                      </div>
                    ) : (
                      <div className="flex-fill mr-2">
                        <p className="m-0">
                          <b className="d-block">Audit Date: </b>{" "}
                          {project.AuditAssignDate
                            ? moment(project.AuditAssignDate).format(
                                "DD MMM YYYY"
                              )
                            : ""}{" "}
                        </p>
                      </div>
                    )}
                    <div className="mr-auto">
                      <p className="m-0">
                        <b className="d-block">Auditee:</b>{" "}
                        {auditee ? auditee : project.Auditee}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="card-footer px-3 py-2">
                    <div className="d-flex">
                      <div className="flex-fill">
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>NC:</b>{" "}
                                <span className="status-count">
                                  {project.NC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>PC:</b>{" "}
                                <span className="status-count pc">
                                  {project.PC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>BNC:</b>{" "}
                                <span className="status-count bnc">
                                  {project.BNC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="flex-auto">
                        <span
                          className="current-status"
                          style={{
                            float: "right",
                            background:
                              project.auditstatusId == 2
                                ? "#ffa64d"
                                : project.auditstatusId == 3
                                ? "#73d13d"
                                : "#ff4d4f",
                          }}
                        >
                          <em>{project.AuditStatus}</em>
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  dashboard = () => {
    this.props.history.push({
      pathname: "/auditordashboard",
    });
  };

  styles = StyleSheet.create({
    page: { backgroundColor: "tomato" },
    section: { color: "white", textAlign: "center", margin: 30 },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  refreshPage = () => {
    this.setState(
      {
        selectedValue: null,
        selectedProject: 0,
        searchText: "",
        projectDetails: [],
        resetSelect: true,
      },
      () => {
        this.getAllProjects();
      }
    );
  };

  projecthandleChange = async (value: any) => {
    let auditProjects: any = [];
    let projectsForReports: any = [];
    this.state.projectList.map((x: any) => {
      if (value === 0) {
        this.setState({
          selectedAuditStatus: 0,
          pendingProjects: this.state.tempPendingProjects,
          selectedProject: value,
        });
      } else if (
        x.project_Id === value &&
        (x.auditstatusId === 1 || x.auditstatusId === 2)
      ) {
        auditProjects.push(x);
        this.setState({
          selectedAuditStatus: 0,
          pendingProjects: auditProjects,
          selectedProject: value,
        });
      }
    });

    this.formRef.current?.setFieldsValue({
      selectedProject: value,
    });

    this.pendingStatusProject = projectsForReports;
  };

  render() {
    this.loading = true;
    let filteredProjects = this.state.allPendingProjects.reduce(
      (accumulator: any[], current: { project_Id: any }) =>
        accumulator.some((x) => x.project_Id === current.project_Id)
          ? accumulator
          : [...accumulator, current],
      []
    );
    return (
      <Layout className="h-100">
        <Spin spinning={this.state.show}>
          <Header className="header">
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="logo is-text">
                  <a onClick={(e) => this.dashBoard()}>Auto Audit</a>
                </div>
              </div>
              <div className="flex-auto">
                <div className="section-log-out">
                  <div className="group-profile d-flex">
                    <div className="d-flex">
                      <Dropdown overlay={this.menu} trigger={["click"]}>
                        <a
                          className="ant-dropdown-link menu-a d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="flex-auto mr-2">
                            <div className="avathar">
                              <img src={this.state.profilePic} alt="user-pic" />
                            </div>
                          </div>
                          <div className="flex-fill mr-2">
                            <p className="login-text m-0">
                              {" "}
                              {this.state.name}{" "}
                              <span>{this.state.rolename}</span>
                            </p>
                          </div>
                          <div className="flex-auto">
                            <CaretDownOutlined />
                          </div>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Header>
          <Layout>
            <div className="page-title">
              <div className="container-fluid">
                <h1>Upcoming Audits</h1>
              </div>
            </div>
            <Content
              className="site-layout-background custom-position"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <div className="container-fluid h-100">
                <div
                  className="h-100"
                  style={{ overflowX: "hidden", overflowY: "auto" }}
                >
                  <div className="main-content">
                    <Form
                      {...Layout}
                      name="control-hooks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      layout="horizontal"
                      labelAlign="left"
                      colon={false}
                    >
                      <Row gutter={16}>
                        <Col span={4}>
                          <Form.Item>
                            <Select
                              placeholder="Project"
                              optionFilterProp="children"
                              allowClear
                              onChange={this.projecthandleChange}
                              value={this.state.selectedProject}
                            >
                              <Option value={0}>All Projects</Option>
                              {filteredProjects.map(
                                (
                                  project: {
                                    project_Id: number;
                                    Name: string;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={project.project_Id}>
                                        {project.Name}
                                      </Option>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <div className="d-inline-block align-middle mb-3 ml-3">
                            <Tooltip title="Refresh List">
                              <Button
                                className="custom-icon-btn"
                                type="primary"
                                icon={<ReloadOutlined />}
                                onClick={this.refreshPage}
                              ></Button>
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    {this.projectListing()}
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </Spin>
      </Layout>
    );
  }
}
export default UpcomingAudits;
