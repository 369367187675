import React from "react";
import { Route, BrowserRouter, Switch, Redirect, Link } from "react-router-dom";
import "reflect-metadata";
import AuditForm from "./Components/CreateAudit/AuditForm";
import AuditorDashboard from "./Components/Dashboard/AuditorDashboard";
import AdminDashboard from "./Components/Dashboard/AdminDashboard";
import AssignAuditor from "./Components/AssignTutor/AssignAuditor";
import EditProject from "./Components/EditProject/EditProject";
import AddNewProject from "./Components/AddNewProject/AddNewProject";
import { NotFoundComponent } from "./Components/NotFound/NotFoundComponent";
import Login from "./Components/Login";
import { api } from "./apiServices/apiServices";
import { notification } from "antd";
import AssignAudits from "./Components/Dashboard/AdminDashboardComponents/AssignAudits";
import AuditReports from "./Components/Dashboard/AdminDashboardComponents/AuditReports";
import UpcomingAudits from "./Components/Dashboard/AdminDashboardComponents/UpcomingAudits";
import ArchivedProjects from "./Components/Dashboard/AdminDashboardComponents/ArchivedProjects";
import AuditsNotStarted from "./Components/Dashboard/AdminDashboardComponents/AuditsNotStarted";

const path = require("path");
export class App extends React.Component {
  render() {
    document.addEventListener("visibilitychange", async (event) => {
      if (document.visibilityState === "visible") {
        let response = await api.ssoLogin();
        if (response?.data?.message === "Success") {
        } else {
          notification.error({
            description: "Unauthorized",
            message: "Error",
          });
          window.location.href =
            process.env.REACT_APP_SSO_SERVER +
            "/sso/auth/login?appId=" +
            process.env.REACT_APP_SSO_APP_ID;
        }
      }
    });
    return (
      <BrowserRouter>
        <Route exact path="/login/*">
          <Redirect to="/" />
        </Route>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/auditordashboard" exact component={AuditorDashboard} />
          <Route path="/admindashboard" exact component={AdminDashboard} />
          <Route path="/auditform" exact component={AuditForm} />
          <Route path="/assignauditor" exact component={AssignAuditor} />
          <Route path="/addnewproject" exact component={AddNewProject} />
          <Route path="/editproject" exact component={EditProject} />
          <Route path="/assignaudits" exact component={AssignAudits} />
          <Route path="/auditreports" exact component={AuditReports} />
          <Route path="/upcomingaudits" exact component={UpcomingAudits} />
          <Route path="/archivedprojects" exact component={ArchivedProjects} />
          <Route path="/auditsnotstarted" exact component={AuditsNotStarted} />
          <Route path="/*" component={NotFoundComponent} />
        </Switch>
      </BrowserRouter>
    );
  }
}
