/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Layout, Button, Spin, notification } from "antd";
import { useEffect } from "react";
import Cookies from 'js-cookie'; 
import jwt_decode from "jwt-decode";
import { api } from "../../apiServices/apiServices";


interface Props{
  signIn: (data:string) => {}
}

function Login(props: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const autoLoginWithSSO = async () => {
    try{
      let response =  await api.ssoLogin();
      if(response?.data?.message === 'Success'){
        setLoading(false);
        const token = Cookies.get('token') as string;
        if(token){
          let decodedToken: any = jwt_decode(token);

          if(decodedToken?.userRole === 2) {
            history.push('/auditordashboard')
          }else if(decodedToken?.userRole === 1) {
            history.push('/admindashboard')
          }
        }
      }
      else{
        setLoading(false)
        setLoginError('Unauthorized');
        setLoading(false);
        notification.error({
            description: 'Not authorized to view this application',
            message: 'Error',
        });
        return;
      }
    }
    catch(err){
      setLoginError(err);
    }
  };

  const onSSOLogin = () => {
    if (
      process.env.REACT_APP_SSO_APP_ID != undefined &&
      process.env.REACT_APP_SSO_SERVER != undefined
    ) {
      setLoading(false);
      window.location.href =
        process.env.REACT_APP_SSO_SERVER +
        '/sso/auth/login?appId=' +
        process.env.REACT_APP_SSO_APP_ID;
    }
  };

  useEffect(() => {
    setLoading(true)
    autoLoginWithSSO();
  }, []);

  return (
    <Layout.Content id='login-container' className='h-100'>
      <div className="align-items-center position-relative d-flex flex-column justify-content-center h-100">
      <Spin className="h-100" spinning={loading} size="large" ></Spin>
      {loginError && <Button className="mt-2" onClick={onSSOLogin}>Try again</Button>}
      </div>
    </Layout.Content>
  );
}

export default Login;
