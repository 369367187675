export const Constants = {
  NC_RED: "#ec7468",
  PC_YELLOW: "#4893bf",
  BNC_ORANGE: "#4dc59f",

  roleId: {
    admin: 1,
    auditor: 2,
    manager: 3,
    auditee: 4,
  },

  auditStatus: {
    new: "New",
    inProgress: "In Progress",
    done: "In Progress",
  },

  UPDATEABLE: "updatable",
  SUCCESS: "success",
  SUCCESS_MESSAGE: "Success",
  AUDIT_SKIP: "Audit skipped successfully.",
  ERROR: "error",
  ERROR_MESSAGE: "Error",
  AUDIT_SKIP_FAIL: "Failed to skip audit",
  CONFIRM_CONTINUE: "Do you want to continue?",
  CONTINUE: "Continue",
  CANCEL_SUCCESS: "Auditor removed successfully",
  CANCEL_ERROR: "Failed to remove auditor",
  ASSIGN_AUDITS: "Assign Audits",
  AUDIT_REPORTS: "Audit Reports",
  UPCOMING_AUDITS: "Upcoming Audits",
  ARCHIVED_PROJECTS: "Archived Projects",
  AUDIT_NEW: 1,
  AUDIT_IN_PROGRESS: 2,
  AUDIT_DONE: 3,
  REQUIRE_REAUDIT: 1,
  AUDIT_COMPLETE: 0,
  AUDITS_STARTED: "Audits Started",
  AUDITS_NOT_STARTED: "Audits Not Started",
  USER_SWITCH:"Successfully updated user role",
  USER_SWITCH_FAIL:"Failed to update user role", 

};
