import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;

export class ApiServices {
  apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = this.apiBaseUrl;
        config.responseType = "json";
        const token = this.getToken();
        if (token) {
          const item = { ...config };
          item.headers.Authorization = `Bearer ${token}`;
          return item;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (responseInter) => {
        return responseInter;
      },
      (error) => {
        if (error?.response?.status === 401) {
          this.signOut();
        }
        setTimeout(() => {
          window.location.href =
            process.env.REACT_APP_SSO_SERVER +
            "/sso/auth/login?appId=" +
            process.env.REACT_APP_SSO_APP_ID;
        }, 2000);

        return error;
      }
    );
  }

  public getToken() {
    let token = Cookies.get("token") as String;
    return token;
  }

  getAllUsers = async () => {
    try {
      let response = await axios.get(`users`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getUsers = async () => {
    try {
      let response = await axios.get(`getUsers`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  userLogin = async (userData: any) => {
    try {
      let response = await axios.post(`auth/login`, userData);
      return response.data;
    } catch (err) {
      let response = { error: true };
      return response;
    }
  };

  userLogout = async () => {
    try {
      let response = await axios.get(`auth/logout`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getProjectsByAuditstatus = async (auditstatusId: number) => {
    try {
      let response = await axios.get(
        `auditstatus/projectByAuditStatus/${auditstatusId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getAllProjects = async (uid: number) => {
    try {
      let response = await axios.get(`project/getAllProjects/${uid}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getAllProject = async () => {
    try {
      let response = await axios.get(`project/getAllProjects`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllAuditStatus = async () => {
    try {
      let response = await axios.get(`auditstatus/getAllAuditStatus`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllAuditType = async () => {
    try {
      let response = await axios.get(`audittype/getAllAuditType`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllAuditTypeDetails = async () =>{
    try {
      let response = await axios.get(`/getAllAuditTypeDetails`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  }

  getCategoryByAuditType = async (audittypeId: number, projectId: number) => {
    try {
      let response = await axios.get(
        `audittype/getCategoryByAuditType/${audittypeId}` +
          `?projectId=${projectId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getCategoryByType = async (audittypeId: number, projectTypeId: number) => {
    try {
      let response = await axios.get(
        `audittype/getCategoryByType/${audittypeId}` +
          `?projectAuditTypeId=${projectTypeId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  addCategory = async (categoryData: any) => {
    try {
      let response = await axios.post(`category/addCategory`, categoryData);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  updateCategory = async (categoryId: number, categoryData: any) => {
    try {
      let response = await axios.put(
        `category/updateCategory/${categoryId}`,
        categoryData
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  deleteCategory = async (categoryId: any) => {
    try {
      let response = await axios.delete(
        `category/deleteCategory/${categoryId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getQuestionByCategory = async (categoryId: string, projectId: number) => {
    try {
      let response = await axios.get(
        `category/getQuestionByCategory/${categoryId}` +
          `?projectId=${projectId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getQuestionByCategoryType = async (
    categoryId: string,
    projectAuditTypeId: number
  ) => {
    try {
      let response = await axios.get(
        `category/getQuestionByCategoryType/${categoryId}` +
          `?projectAuditTypeId=${projectAuditTypeId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  addQuestionByCategory = async (questionData: any) => {
    try {
      let response = await axios.post(
        `category/addQuestionByCategory`,
        questionData
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  updateQuestion = async (questionId: number, questionData: any) => {
    try {
      let response = await axios.put(
        `category/updateQuestion/${questionId}`,
        questionData
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  deleteQuestion = async (questionId: any) => {
    try {
      let response = await axios.delete(
        `category/deleteQuestion/${questionId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAuditAssignments = async (projectId: number) => {
    try {
      let response = await axios.get(
        `project/getAuditAssignments/${projectId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllDoneProjects = async () => {
    try {
      let response = await axios.get(`project/getAllDoneProjects`);
      return response.data;
    } catch (err) {
      console.log(err.response);
      throw err;
    }
  };

  getAllQuestionAnswers = async (auditId: any) => {
    try {
      let response = await axios.get(
        `category/getAllQuestionAnswers/${auditId}`
      );
      return response.data;
    } catch (err) {
      console.log(err.response);
      throw err;
    }
  };

  getReport = async (auditId: number) => {
    try {
      let response = await axios.get(`category/getReport/${auditId}`);
      return response.data;
    } catch (err) {
      console.log(err.response);
      throw err;
    }
  };

  updateAnswers = async (userData: any) => {
    try {
      let response = await axios.post(`updateAnswers`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  submitProject = async (userData: any) => {
    try {
      let response = await axios.post(`submitProject`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  assignAuditor = async (userData: any) => {
    try {
      let response = await axios.post(`assignAuditor`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  assignOtherAudits = async (userData: any) => {
    try {
      let response = await axios.post(`assignOtherAudits`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  addAuditType = async (userData: any) => {
    try {
      let response = await axios.post(`addAuditType`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  assignAuditType = async (userData:any)=>{
    try{
      let response = await axios.post(`asignAuditType`,userData);
     
      return response.data;
    } catch(err) {
      throw err;
    }
  };

  removeAuditAssign =async(userdata:any)=>{
    try{
      let response = await axios.put(`/auditChecklist/removeAssignAudits`,userdata);
      return response.data;
    }catch(err){
      throw err;
    }
  }

  updateAditTypeName = async (auditTypeId: number, auditName: any) => {
    try {
      let response = await axios.put(`updateAuditType/${auditTypeId}`, {
        name: auditName,
      });
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  deleteAditTypeName = async (auditData: any) => {
    try {
      let response = await axios.put(`deleteAuditType/${auditData.Id}`, {
        name: auditData.Name,
      });
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllTaProjects = async () => {
    try {
      let response = await axios.get(`projects/getAllTaProjects/`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getTaProjectById = async (uid: number) => {
    try {
      let response = await axios.get(`projects/getTaProjectById/${uid}`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getTaArchivedProjects = async () => {
    try {
      let response = await axios.get(`projects/getTaArchivedProjects/`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getUserById = async (uid: number) => {
    try {
      let response = await axios.get(`projects/getTaUserById/${uid}`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAuditProjects = async () => {
    try {
      let response = await axios.get(`project/getAuditProject/`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAuditee = async (projectId: number) => {
    try {
      let response = await axios.get(`project/getAuditee/${projectId}`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAuditProjectbyId = async (projectId: number) => {
    try {
      let response = await axios.get(
        `project/getAuditProjectbyId/${projectId}`
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  saveAddedProject = async (data: any) => {
    try {
      let response = await axios.post(`project/saveProject/`, data);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  updateProject = async (data: any) => {
    try {
      let response = await axios.post(`project/updateProject/`, data);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllProjectTypes = async () => {
    try {
      let response = await axios.get(`project/getAllProjectTypes`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAllLocations = async () => {
    try {
      let response = await axios.get(`project/getAllLocations`);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  updateQuestionsListByCategory = async (questions: any) => {
    try {
      let response = await axios.put(
        `category/updateQuestionsList/`,
        questions
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  updateCategoryListByOrder = async (categories: any) => {
    try {
      let response = await axios.put(
        `category/updateCategoryList/`,
        categories
      );
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  ssoLogin = async () => {
    let response = await axios.post(`auth/fakelogin`, null);
    return response;
  };

  signOut = async () => {
    let response = await axios.post(`auth/signout`, null);
    return response;
  };

  logOut = async () => {
    await this.signOut();
    Cookies.remove("token", { domain: process.env.REACT_APP_DOMAIN_NAME });
    if (
      process.env.REACT_APP_SSO_APP_ID != undefined &&
      process.env.REACT_APP_SSO_SERVER != undefined
    ) {
      window.location.href =
        process.env.REACT_APP_SSO_SERVER +
        "/sso/auth/login?appId=" +
        process.env.REACT_APP_SSO_APP_ID;
    }
  };

  sendEmail = async (data: any) => {
    try {
      let response = await axios.post(`/sendEmail`, data);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };
  getReauditQuestions = async (project_id: number) => {
    try {
      let response = await axios.post(`/getReauditQuestions`, {
        id: project_id,
      });
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };

  getAuditeeId = async (project_id: number) => {
    try {
      let response = await axios.post(`/getAuditeeId`, {
        id: project_id,
      });
      return response.data;
    } catch (err) {
      console.log("err ", err);
    }
  };
  skipAudit = async (userData: any) => {
    try {
      if (userData === null) {
        throw new Error("Invalid user data. Cannot be null.");
      }
      let response = await axios.post(`skipAudit`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  cancelAuditor = async (userData: any) => {
    try {
      let response = await axios.post(`cancelAuditor`, userData);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  auditStartedCount = async (projectData: any) => {
    try {
      let response = await axios.post(`auditStartedCount`, {
        projectData: projectData,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  auditNotStartedProjects = async () => {
    try {
      let response = await axios.get(`projects/auditNotStartedProjects/`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  getAllUsersQA = async (): Promise<any> => {
    try {
      const response = await axios.get(`getAllUsersQA`);
      if (response === null || response === undefined) {
        throw new Error("Received null or undefined response");
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  
  switchUser = async (data: any) => {
    try {
      if (!data) {
        throw new Error("Data is null or undefined");
      }
  
      let response = await axios.post(`users/switchUser/`, data);
      return response.data;
    } catch (err) {
      console.log("err ", err);
      throw err;
    }
  };
  

}

export const api = new ApiServices();
