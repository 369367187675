import React from "react";
import {
  Layout,
  Menu,
  Input,
  Button,
  Select,
  Dropdown,
  Table,
  Tooltip,
  Spin,
  Modal,
  Row,
  Col,
  Typography,
  Popconfirm,
  Form,
  InputNumber,
  Space,
  notification,
  Alert,
} from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  DownOutlined,
  PlusOutlined,
  EditFilled,
  FileAddFilled,
  ReloadOutlined,
  WarningFilled,
  AppstoreOutlined,
  ContainerOutlined,
  PieChartOutlined,
  CloudServerOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  EditOutlined,
  DeleteOutlined,
  EditTwoTone,
  CaretDownOutlined,
} from "@ant-design/icons";
import { api } from "../../../apiServices/apiServices";
import { Link } from "react-router-dom";
import moment from "moment";
// import CheckListComponent from "./Content";
import configData from "../../../config/fieldsConfig.json";
import { ColumnProps } from "antd/es/table";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { ColumnType } from "antd/lib/table";
import form, { FormInstance } from "antd/lib/form";

const { Option } = Select;
const { Search } = Input;
const { Header, Content, Sider } = Layout;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Object;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input maxLength={20} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              pattern: new RegExp(/^[^\s][A-Za-z\s]*$/),
              message: "Please add a meaningful name",
            },
            { max: 19, message: "Maximum 20 characters allowed" },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const columns: ColumnProps<any>[] = [
  {
    title: "Project",
    dataIndex: "Project",
    key: "Project",
    sorter: (a: { Project: string }, b: { Project: string }) =>
      a.Project.localeCompare(b.Project),
    sortDirections: ["descend", "ascend"],
    render: (Project: string) => assignAudits.reauditSign(Project),
  },
  {
    title: "Process Stage",
    dataIndex: "AuditType",
    key: "AuditType",
    sorter: (a: { AuditType: string }, b: { AuditType: string }) => {
      if (!a.AuditType || !b.AuditType) {
        return 0;
      }
      if (a.AuditType === "Not Planned" && b.AuditType !== "Not Planned") {
        return -1;
      } else if (
        a.AuditType !== "Not Planned" &&
        b.AuditType === "Not Planned"
      ) {
        return 1;
      } else {
        return a.AuditType.localeCompare(b.AuditType);
      }
    },
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
  },
  {
    title: "Audit Date",
    dataIndex: "AuditDate",
    key: "AuditDate",
    sorter: (a: { AuditDate: Date }, b: { AuditDate: Date }) =>
      moment(a.AuditDate).unix() - moment(b.AuditDate).unix(),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Auditee",
    dataIndex: "Auditee",
    key: "Auditee",
    sorter: (a: { Auditee: string }, b: { Auditee: string }) =>
      a.Auditee.localeCompare(b.Auditee),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Status",
    dataIndex: "color",
    key: "address 2",
    width: 50,
    align: "center",
  },
  {
    title: "",
    dataIndex: "action",
    key: "address 4",
    width: 150,
  },
];

class AssignAudits extends React.Component<any, any> {
  navigateToLogin(e: any) {
    api.logOut();
  }

  menu = (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          this.props.history.push("/auditordashboard");
        }}
      >
        Auditor Dashboard
      </Menu.Item>
    </Menu>
  );

  userData: any = "";
  formRef = React.createRef<FormInstance>();
  constructor(props: any) {
    super(props);
    this.state = {
      allProjects: [],
      allProjectsCopy: [],
      projectDetails: [],
      allAuditTypes: [],
      auditTypesDetails: [],
      username: "",
      rolename: "",
      profilePic: "",
      hrAuditCount: 0,
      systemAuditCount: 0,
      selectedStatus: 0,
      searchText: "",
      menuSelectedValue: "",
      sortedInfo: null,
      openModal: false,
      typeNameNew: "",
      editingKey: null,
      loading: false,
      isValid: false,
    };
  }

  async componentDidMount() {
    this.getAllAuditLists();
    this.getAuditTypeDetails();
    this.setState({ menuSelectedValue: configData[1].key });
  }

  reauditSign = (projectname: any) => {
    return (
      <div className="d-inline-block mr-3 align-middle pointer warning-info">
        <div className="if-reaudit">
          <Tooltip title="Re-audit Needed">
            <WarningFilled />
          </Tooltip>
        </div>
        {projectname}
      </div>
    );
  };

  getAuditTypeDetails = async () => {
    
    let projectAuditTypes = await api.getAllProjectTypes()
    let auditTypeDetails = await api.getAllAuditTypeDetails()
    
    this.setState({
      auditTypesDetails: auditTypeDetails,
      projectAuditTypeList: projectAuditTypes
    }
    )}

  addAuditType = async (name: string) => {
    await api.addAuditType({ auditType: name }).then((addName: any) => {
      const key = "updatable";
      if (addName) {
        notification.success({
          key,
          message: "Success",
          description: "Audit type added successfully.",
        });
      } else {
        notification.error({
          key,
          message: "Error",
          description: "Failed to add audit type",
        });
      }
    });
    this.getAllAuditLists();
    this.setState({ typeNameNew: "" });
  };

  typeNameChange = async (e: any) => {
    const value = e.target.value;
    this.setState({ isValid: /^[^\s][A-Za-z\s]*$/.test(value) });

    this.setState({ typeNameNew: e.target.value });
  };

  navigateToNewProject(e: any) {
    this.props.history.push("/addnewproject");
  }

  navigateToEditProject(projectId: number, taProjectId: number) {
    this.props.history.push({
      pathname: "/editproject",
      state: { projectId: projectId, TaProjectId: taProjectId },
    });
  }
  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    allauditType: any,
    auditTypesDetails:any,
    projectname: any,
    auditor: any,
    auditee: any,
    auditassignId: any,
    aa_date: any,
    auditstate: any,
    auditStatus: any,
    projAuditTypeId:any,
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        AllAuditType: allauditType,
        auditTypesDetails: auditTypesDetails,
        Project_Name: projectname,
        Auditor: auditor,
        Auditee: auditee,
        AuditAssignID: auditassignId,
        Audit_Assign_Date: aa_date,
        Audit_State: auditstate,
        AuditStatus: auditStatus,
        selectedProjectAuditTypeId:projAuditTypeId,
      },
    });
  };

  searchHandler = async (e: any) => {
    this.setState({ searchText: e.target.value });
  };

  isEditing = (record: any) => {
    return record.Id == this.state.editingKey;
  };

  edit = (record: any) => {
    this.formRef.current?.setFieldsValue({
      Id: "",
      name: "",
      ...record,
    });
    this.setState({ editingKey: record.Id });
  };

  delete = async (record: any) => {
    await api.deleteAditTypeName(record).then((deleteChanges: any) => {
      const key = "updatable";
      if (deleteChanges) {
        notification.success({
          key,
          message: "Success",
          description: "Audit type deleted successfully.",
        });
      } else {
        notification.error({
          key,
          message: "Error",
          description: "Failed to delete audit type",
        });
      }
    });
    this.setState({ editingKey: null });
    this.setState({ loading: true });
    this.getAllAuditLists();
  };

  cancel = () => {
    this.setState({ editingKey: null });
  };

  save = async (key: number) => {
    const selectedType = await this.formRef.current?.validateFields();
    await api
      .updateAditTypeName(key, selectedType.Name)
      .then((updateName: any) => {
        const key = "updatable";
        if (updateName) {
          notification.success({
            key,
            message: "Success",
            description: "Audit type updated successfully.",
          });
        } else {
          notification.error({
            key,
            message: "Error",
            description: "Failed to update audit type",
          });
        }
      });
    this.setState({ editingKey: null });
    this.setState({ loading: true });
    this.getAllAuditLists();
  };

  auditTypeColums: ColumnType<any>[] = [
    {
      title: "Sl.no",
      dataIndex: "Index",
      key: "Id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filtered: true,
    },

    {
      dataIndex: "operation",
      render: (_: any, record: any) => {
        const editable = this.isEditing(record);

        return editable ? (
          <span>
            <Typography.Link
              // onClick={() => this.save(record.Id)}
              style={{ marginRight: 8 }}
            >
              <Popconfirm
                title="Sure to save?"
                onConfirm={() => this.save(record.Id)}
              >
                Save
              </Popconfirm>
            </Typography.Link>

            <Typography.Link style={{ color: "#2c2c2c" }} onClick={this.cancel}>
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <Space>
            <Button
              icon={<EditTwoTone />}
              type="text"
              disabled={this.state.editingKey !== null}
              onClick={() => this.edit(record)}
            />

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.delete(record)}
            >
              <Typography.Link style={{ color: "#2c2c2c" }}>
                <DeleteOutlined />
              </Typography.Link>
            </Popconfirm>
            {/* <Button 
          icon={<DeleteOutlined />}
          type="text"
          onClick={()=>this.delete(record)}
          /> */}
          </Space>
        );
      },
    },
  ];

  mergedColumns: any = this.auditTypeColums.map((col) => {
    if (!col.filtered) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: this.isEditing(record),
      }),
    };
  });

  async searchProject(value: any) {
    let auditDate = 0;
    var auditProjects: any = [];
    this.setState({
      searchText: value,
      hrAuditCount: 0,
      systemAuditCount: 0,
    });
    this.state.allProjects.map((project: any) => {
      const idCheck = (): boolean => {
        if (this.state.selectedStatus !== 0) {
          return this.state.selectedStatus === project.AuditTypeId;
        } else {
          return true;
        }
      };
      if (project.Displayed === 0) {
        auditDate = project.AuditDate;
      }
      if (project.Displayed === 1) {
        let auditee = "";
        let projectTeam = JSON.parse(project.ProjectTeam);
        projectTeam?.map((item: any) => {
          if (Number(project.Auditee) === Number(item.id)) {
            auditee = item.Name;
          }
        });

        if (project.AuditTypeId === 4) {
          if (project.LocationId === 1)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
          else if (project.LocationId === 2)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
        } else if (project.AuditTypeId === 5) {
          if (project.LocationId === 1)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
          else if (project.LocationId === 2)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
        }
        if (
          idCheck() &&
          project.Name.toUpperCase().includes(value.toUpperCase())
        ) {
          auditProjects.push({
            Project: project.Name,
            AuditType: project.AuditTypeId
              ? project.Audit_Type_Name +
                (project.Audit_State ? " " + project.Audit_State : "")
              : "Not Planned",
            AuditDate: auditDate
              ? moment(auditDate).format("DD MMM YYYY")
              : project.AuditAssignDate == null
              ? "Not Planned"
              : moment(project.AuditAssignDate).format("DD MMM YYYY"),
            Auditee: auditee ? auditee : "Not Assigned",
            color: (
              <div>
                <Tooltip
                  title={
                    project.auditstatusId == 1
                      ? "New"
                      : project.auditstatusId == 2
                      ? "In Progress"
                      : "Done"
                  }
                >
                  <span
                    className="color-status"
                    style={
                      project.auditstatusId == 2
                        ? { backgroundColor: "#ffa64d" }
                        : project.auditstatusId == 3
                        ? { backgroundColor: "#73d13d" }
                        : { backgroundColor: "#ff4d4f" }
                    }
                  />
                </Tooltip>
              </div>
            ),
            action: (
              <div className="icon-group">
                <React.Fragment>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    {project.AuditTypeId === 4 || project.AuditTypeId === 5 ? (
                      ""
                    ) : (
                      <div
                        onClick={(e) => {
                          this.navigateToEditProject(
                            project.project_Id,
                            project.TaProjectId
                          );
                        }}
                      >
                        <ReloadOutlined title="Reload">
                          <EditFilled />
                        </ReloadOutlined>
                      </div>
                    )}
                  </div>

                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    <div
                      onClick={(e) =>
                        this.navigate(
                          "/assignauditor",
                          project.auditstatusId,
                          project.project_Id,
                          project.AuditTypeId,
                          project.ProjectTeam,
                          project.NC,
                          project.PC,
                          project.BNC,
                          project.NA,
                          project.OK,
                          project.Reaudit,
                          project.Audit_Id,
                          this.state.allAuditTypes,
                          this.state.auditTypesDetails,
                          project.Name,
                          project.Auditor,
                          project.Auditee,
                          project.Audit_Assign_ID,
                          project.AuditAssignDate,
                          project.Audit_State,
                          project.AuditStatus,
                          project.ProjAuditTypeId,
                        )
                      }
                    >
                      <Tooltip title="Assign Auditor">
                        <FileAddFilled />
                      </Tooltip>
                    </div>
                  </div>
                  <div hidden>
                    {project.Reaudit === 1 && project.AuditStatus === "Done" ? (
                      <WarningFilled />
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              </div>
            ),
          });
        }
      }
    });
    this.setState({
      projectDetails: auditProjects,
    });
  }

  refreshPage = () => {
    this.setState(
      {
        sortedInfo: null,
        selectedStatus: 0,
        searchText: "",
      },
      () => {
        this.getAllAuditLists();
      }
    );
  };

  getAllAuditLists = async () => {
    this.setState({ show: true });
    let auditDate = 0;
    let dashboarddata: any = [];
    let allAuditStatus: never[] = [];

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token);
    }
    allAuditStatus = await api.getAllAuditType();
    await api
      .getAllProject()
      .then((allProject) => {
        this.setState({
          show: false,
          allProjects: allProject,
          allProjectsCopy: allProject,
          projectDetails: allProject,
          hrAuditCount: 0,
          systemAuditCount: 0,
        });
      })
      .then(() => {
      
        this.state.allProjects.map((project: any, index: number) => {
          
          if (project.Displayed === 0) {
            auditDate = project.AuditDate;
          }
          if (project.Displayed === 1) {
            let auditee =
              project.AuditTypeId === 4 || project.AuditTypeId === 5
                ? project.Auditee
                : "";
            let projectTeam = JSON.parse(project.ProjectTeam);
            projectTeam?.map((item: any) => {
              if (Number(project.Auditee) === Number(item.id)) {
                auditee = item.Name;
              }
            });

            if (project.AuditTypeId === 4) {
              if (project.LocationId === 1)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
              else if (project.LocationId === 2)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
            } else if (project.AuditTypeId === 5) {
              if (project.LocationId === 1)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
              else if (project.LocationId === 2)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
            }

            dashboarddata.push({
              Project: project.Name,
              AuditType: project.AuditTypeId
                ? project.Audit_Type_Name +
                  (project.Audit_State ? " " + project.Audit_State : "")
                : "Not Planned",
              AuditDate: auditDate
                ? moment(auditDate).format("DD MMM YYYY")
                : project.AuditAssignDate == null
                ? "Not Planned"
                : moment(project.AuditAssignDate).format("DD MMM YYYY"),
              Auditee: auditee ? auditee : "Not Assigned",
              color: (
                <div>
                  <Tooltip
                    title={
                      project.auditstatusId == 1
                        ? "New"
                        : project.auditstatusId == 2
                        ? "In Progress"
                        : "Done"
                    }
                  >
                    <span
                      className="color-status"
                      style={
                        project.auditstatusId == 2
                          ? { backgroundColor: "#ffa64d" }
                          : project.auditstatusId == 3
                          ? { backgroundColor: "#73d13d" }
                          : { backgroundColor: "#ff4d4f" }
                      }
                    />
                  </Tooltip>
                </div>
              ),
              action: (
                <div className="icon-group">
                  <React.Fragment>
                    <div className="d-inline-block mr-3 align-middle pointer icon-card">
                      {project.AuditTypeId === 4 ||
                      project.AuditTypeId === 5 ? (
                        ""
                      ) : (
                        <div
                          onClick={(e) => {
                            this.navigateToEditProject(
                              project.project_Id,
                              project.TaProjectId
                            );
                          }}
                        >
                          <ReloadOutlined title="Reload">
                            <EditFilled />
                          </ReloadOutlined>
                        </div>
                      )}
                    </div>

                    <div className="d-inline-block mr-3 align-middle pointer icon-card">
                      <div
                        onClick={(e) =>
                          this.navigate(
                            "/assignauditor",
                            project.auditstatusId,
                            project.project_Id,
                            project.AuditTypeId,
                            project.ProjectTeam,
                            project.NC,
                            project.PC,
                            project.BNC,
                            project.NA,
                            project.OK,
                            project.Reaudit,
                            project.Audit_Id,
                            this.state.allAuditTypes,
                            this.state.auditTypesDetails,
                            project.Name,
                            project.Auditor,
                            project.Auditee,
                            project.Audit_Assign_ID,
                            project.AuditAssignDate,
                            project.Audit_State,
                            project.AuditStatus,
                            project.ProjAuditTypeId,
                          )
                        }
                      >
                        <Tooltip title="Assign Auditor">
                          <FileAddFilled />
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      {project.Reaudit === 1 &&
                      project.AuditStatus === "Done" ? (
                       <></>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                </div>
              ),
            });
          }
        });
      })
      .then(() => {
        this.setState({
          projectDetails: dashboarddata,
          allAuditTypes: allAuditStatus,
          name: this.userData ? this.userData.name : "",
          username: this.userData ? this.userData.email : "",
          rolename: this.userData ? this.userData.roleName : "",
          profilePic: this.userData ? this.userData.profilePic : "",
        });
      });
    this.setState({ loading: false });
  };

  getProjectAuditTypes = async () => {
    this.setState({ show: true });
    let projectAuditTypes = await api.getAllProjectTypes();
    await this.setState({ show: false, projectAuditTypes: projectAuditTypes });
  };

  projectAuditTypeHandleChange = (value: any) => {
    this.setState({ selectedProjectAuditType: value });
  };

  audittypehandleChange = async (value: any) => {
    let auditDate = 0;
    var auditProjects: any = [];
    this.setState({
      selectedStatus: value,
      hrAuditCount: 0,
      systemAuditCount: 0,
    });
    this.state.allProjects.map((project: any) => {
      if (project.Displayed === 0) {
        auditDate = project.AuditDate;
      }
      if (project.Displayed === 1) {
        let auditee = "";
        let projectTeam = JSON.parse(project.ProjectTeam);
        projectTeam?.map((item: any) => {
          if (Number(project.Auditee) === Number(item.id)) {
            auditee = item.Name;
          }
        });

        if (project.AuditTypeId === 4) {
          if (project.LocationId === 1)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
          else if (project.LocationId === 2)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
        } else if (project.AuditTypeId === 5) {
          if (project.LocationId === 1)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
          else if (project.LocationId === 2)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
        }

        if (value == 0) {
          auditProjects.push({
            Project: project.Name,
            AuditType: project.AuditTypeId
              ? project.Audit_Type_Name +
                (project.Audit_State ? " " + project.Audit_State : "")
              : "Not Planned",
            AuditDate: auditDate
              ? moment(auditDate).format("DD MMM YYYY")
              : project.AuditAssignDate == null
              ? "Not Planned"
              : moment(project.AuditAssignDate).format("DD MMM YYYY"),
            Auditee: auditee ? auditee : "Not Assigned",
            color: (
              <div>
                <Tooltip
                  title={
                    project.auditstatusId == 1
                      ? "New"
                      : project.auditstatusId == 2
                      ? "In Progress"
                      : "Done"
                  }
                >
                  <span
                    className="color-status"
                    style={
                      project.auditstatusId == 2
                        ? { backgroundColor: "#ffa64d" }
                        : project.auditstatusId == 3
                        ? { backgroundColor: "#73d13d" }
                        : { backgroundColor: "#ff4d4f" }
                    }
                  />
                </Tooltip>
              </div>
            ),
            action: (
              <div className="icon-group">
                <React.Fragment>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    {project.AuditTypeId === 4 || project.AuditTypeId === 5 ? (
                      ""
                    ) : (
                      <div
                        onClick={(e) => {
                          this.navigateToEditProject(
                            project.project_Id,
                            project.TaProjectId
                          );
                        }}
                      >
                        <ReloadOutlined title="Reload">
                          <EditFilled />
                        </ReloadOutlined>
                      </div>
                    )}
                  </div>

                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    <div
                      onClick={(e) =>
                        this.navigate(
                          "/assignauditor",
                          project.auditstatusId,
                          project.project_Id,
                          project.AuditTypeId,
                          project.ProjectTeam,
                          project.NC,
                          project.PC,
                          project.BNC,
                          project.NA,
                          project.OK,
                          project.Reaudit,
                          project.Audit_Id,
                          this.state.allAuditTypes,
                          this.state.auditTypesDetails,
                          project.Name,
                          project.Auditor,
                          project.Auditee,
                          project.Audit_Assign_ID,
                          project.AuditAssignDate,
                          project.Audit_State,
                          project.AuditStatus,
                          project.ProjAuditTypeId
                        )
                      }
                    >
                      <Tooltip title="Assign Auditor">
                        <FileAddFilled />
                      </Tooltip>
                    </div>
                  </div>
                  <div hidden>
                    {project.Reaudit === 1 && project.AuditStatus === "Done" ? (
                      <WarningFilled />
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              </div>
            ),
          });
        } else if (project.AuditTypeId === value) {
          auditProjects.push({
            Project: project.Name,
            AuditType: project.AuditTypeId
              ? project.Audit_Type_Name +
                (project.Audit_State ? " " + project.Audit_State : "")
              : "Not Planned",
            AuditDate: auditDate
              ? moment(auditDate).format("DD MMM YYYY")
              : project.AuditAssignDate == null
              ? "Not Planned"
              : moment(project.AuditAssignDate).format("DD MMM YYYY"),
            Auditee: auditee ? auditee : "Not Assigned",
            color: (
              <div>
                <Tooltip
                  title={
                    project.auditstatusId == 1
                      ? "New"
                      : project.auditstatusId == 2
                      ? "In Progress"
                      : "Done"
                  }
                >
                  <span
                    className="color-status"
                    style={
                      project.auditstatusId == 2
                        ? { backgroundColor: "#ffa64d" }
                        : project.auditstatusId == 3
                        ? { backgroundColor: "#73d13d" }
                        : { backgroundColor: "#ff4d4f" }
                    }
                  />
                </Tooltip>
              </div>
            ),
            action: (
              <div className="icon-group">
                <React.Fragment>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    {project.AuditTypeId === 4 || project.AuditTypeId === 5 ? (
                      ""
                    ) : (
                      <div
                        onClick={(e) => {
                          this.navigateToEditProject(
                            project.project_Id,
                            project.TaProjectId
                          );
                        }}
                      >
                        <ReloadOutlined title="Reload">
                          <EditFilled />
                        </ReloadOutlined>
                      </div>
                    )}
                  </div>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    <div
                      onClick={(e) =>
                        this.navigate(
                          "/assignauditor",
                          project.auditstatusId,
                          project.project_Id,
                          project.AuditTypeId,
                          project.ProjectTeam,
                          project.NC,
                          project.PC,
                          project.BNC,
                          project.NA,
                          project.OK,
                          project.Reaudit,
                          project.Audit_Id,
                          this.state.allAuditTypes,
                          this.state.auditTypesDetails,
                          project.Name,
                          project.Auditor,
                          project.Auditee,
                          project.Audit_Assign_ID,
                          project.AuditAssignDate,
                          project.Audit_State,
                          project.AuditStatus,
                          project.ProjAuditTypeId,
                        )
                      }
                    >
                      <Tooltip title="Assign Auditor">
                        <FileAddFilled />
                      </Tooltip>
                    </div>
                  </div>
                  <div hidden>
                    {project.Reaudit === 1 && project.AuditStatus === "Done" ? (
                      <WarningFilled />
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              </div>
            ),
          });
        }
      }
    });
    this.setState({
      projectDetails: auditProjects,
    });
  };

  openSubmitNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Project added successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to add project",
      });
    }
  };

  dashBoard = async () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  auditSummaryDashboard = async () => {
    this.props.history.push({
      pathname: "/assignaudits",
    });
  };

  changeHandler = (event: any) => {
    let projectDetails = this.state.projectDetails;
    projectDetails[event.target.name] = event.target.value;
    this.setState({ projectDetails: projectDetails });
  };

  render() {
    return (
      <Layout>
        <Header className="header">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <div className="logo is-text">
                <a onClick={(e) => this.dashBoard()}>Auto Audit</a>
              </div>
            </div>
            <div className="flex-auto">
              <div className="section-log-out">
                <div className="group-profile d-flex">
                  <div className="d-flex">
                    <Dropdown overlay={this.menu} trigger={["click"]}>
                      <a
                        className="ant-dropdown-link menu-a d-flex align-items-center"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="flex-auto mr-2">
                          <div className="avathar">
                            <img src={this.state.profilePic} alt="user-pic" />
                          </div>
                        </div>
                        <div className="flex-fill mr-2">
                          <p className="login-text m-0">
                            {" "}
                            {this.state.name} <span>{this.state.rolename}</span>
                          </p>
                        </div>
                        <div className="flex-auto">
                          <CaretDownOutlined />
                        </div>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div className="page-title">
          <div>
            <h1>Audit Summary</h1>
          </div>
        </div>
        <Content
          className="site-layout-background custom-position"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="h-100">
            <div className="main-content h-100">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto mb-3">
                  <Row align="middle" justify="end" gutter={[8, 8]}>
                    <Col flex="auto">
                      <Space>
                        <Select
                          style={{ width: 200 }}
                          placeholder="Select Process Stage"
                          optionFilterProp="children"
                          onSelect={this.audittypehandleChange}
                          defaultValue={0}
                          value={this.state.selectedStatus}
                        >
                          <Option value={0}>All</Option>
                          {this.state.allAuditTypes.map(
                            (
                              auditstatus: {
                                Id: number;
                                Name: string;
                              },
                              index: number
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <Option value={auditstatus.Id}>
                                    {auditstatus.Name}
                                  </Option>
                                </React.Fragment>
                              );
                            }
                          )}
                        </Select>
                        <Search
                          className="custom-field"
                          placeholder="Search Project"
                          value={this.state.searchText}
                          onChange={this.searchHandler}
                          onSearch={(value) => this.searchProject(value)}
                          style={{ width: 250 }}
                          allowClear
                        />
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={(e) => this.navigateToNewProject(e)}
                        >
                          Add New Project
                        </Button>
                        <Tooltip title="Refresh List">
                          <Button
                            className="custom-icon-btn"
                            type="primary"
                            icon={<ReloadOutlined />}
                            onClick={this.refreshPage}
                          />
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                </div>

                <Spin
                  spinning={this.state.show}
                  style={{
                    alignContent: "center",
                    alignSelf: "center",
                    marginTop: "15%",
                  }}
                />
                <div className="flex-fill overflow-auto">
                  {!this.state.show && (
                    <Table
                      columns={columns}
                      dataSource={this.state.projectDetails}
                      rowClassName={(record: any) =>
                        record.action?.props?.children.props.children[2].props
                          .children !== ""
                          ? "warning-row"
                          : ""
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Content>

        <Modal
          title="Add Audit Type"
          centered
          visible={this.state.openModal}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.setState({ openModal: false })}
          width={500}
          footer={false}
          // bodyStyle={{ height: 500 }}
        >
          <Form>
            {/* <Row
                    justify="start"
                    gutter={8}
                    style={{ marginBottom: "16px" }}
                  >
                    <Col> */}
            <Form.Item
              name={"Audit Type Name"}
              rules={[
                {
                  pattern: new RegExp(/^[^\s][A-Za-z\s]*$/),
                  message: "Please add a meaningful name",
                },
                { max: 20, message: "Maximum 20 characters allowed" },
              ]}
            >
              {
                <Row gutter={8}>
                  <Col>
                    <Input
                      style={{ width: "200px" }}
                      placeholder="New Audit Type"
                      maxLength={20}
                      value={this.state.typeNameNew}
                      onChange={this.typeNameChange}
                    />
                  </Col>
                  <Col>
                    <Tooltip title="Add" placement="top">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className="custom-icon-btn"
                        onClick={() =>
                          this.addAuditType(this.state.typeNameNew)
                        }
                        disabled={!this.state.isValid}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              }
            </Form.Item>
          </Form>

          <Form ref={this.formRef} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={this.mergedColumns}
              dataSource={this.state.allAuditTypes}
              loading={this.state.show}
              pagination={{ onChange: this.cancel }}
            />
          </Form>
        </Modal>
      </Layout>
    );
  }
}
export default AssignAudits;
const assignAudits = new AssignAudits({});
