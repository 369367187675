import React from "react";
import {
  Layout,
  Menu,
  Space,
  Form,
  Input,
  Button,
  Select,
  Dropdown,
  Row,
  Col,
  DatePicker,
  notification,
  Modal,
  Spin,
} from "antd";
import {
  CaretDownOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import { api } from "../../apiServices/apiServices";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Constants } from "../../constants/constants";
const { confirm } = Modal;

const { Option } = Select;
const { Header, Content } = Layout;
const dateFormat = "DD MMM YYYY";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 24, span: 24 },
};

class AssignAuditor extends React.Component<any, any> {
  menu = (
    <Menu onClick={(e) => this.navigateToLogin(e)}>
      <Menu.Item key="0">Logout</Menu.Item>
    </Menu>
  );
  userData: any = "";
  formRef = React.createRef<FormInstance>();
  constructor(props: any) {
    super(props);
    this.state = {
      allProjects: [],
      allAuditTypes: [],
      auditTypesDetails:[],
      projectname: "",
      auditor: null,
      auditee: [],
      audittype: "",
      Assign_Date: "",
      projectTeam: [],
      userDetails: [],
      auditAssign_ID: "",
      reaudit: "",
      selectedProjectId: "",
      selectedaudittypeId: "",
      selectedProjectAuditTypeId: Number,
      auditeeList: [],
      selectedAuditType: null,
      auditIdselected: Number,
      processState: "",
      auditPhaseCount: 0,
      auditStatus: "",
      locationDetails: [],
      selectedLocation: 0,
      selectedLocationName: "",
      processStateTag: "Process State",
      excecutionPhaseCount: 0,
      phaseCountEditable: false,
      skipReaudit: false,
      name: "",
      username: "",
      rolename: "",
      profilePic: "",
      auditTypeName: "",
      show: true,
      selected_Date:""
    };
  }

  async componentDidMount() {
    let users: any[];
    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token);
    }
    users = await api.getUsers();
    if (this.props.location.state.projectId) {
      let auditee = await api.getAuditee(this.props.location.state.projectId);
      let auditeeConversion = JSON.parse(auditee[0].ProjectTeam);
      await this.setState({
        allAuditTypes: this.props.location.state.AllAuditType,
        auditTypesDetails:this.props.location.state.auditTypesDetails,
        projectname: this.props.location.state.Project_Name,
        audittype: this.props.location.state.AuditTypeId,
        auditor: this.props.location.state.Auditor,
        auditee: this.props.location.state.Auditee,
        Assign_Date: this.props.location.state.Audit_Assign_Date,
        auditAssign_ID: this.props.location.state.AuditAssignID,
        reaudit: this.props.location.state.ReAudit,
        selectedProjectId: this.props.location.state.projectId,
        selectedaudittypeId: this.props.location.state.AuditTypeId,
        selectedProjectAuditTypeId:this.props.location.state.selectedProjectAuditTypeId,
        projectTeam: JSON.parse(this.props.location.state.ProjectTeam),
        userDetails: users,
        auditeeList: auditeeConversion,
        name: this.userData ? this.userData.name : "",
        username: this.userData ? this.userData.email : "",
        rolename: this.userData ? this.userData.roleName : "",
        profilePic: this.userData ? this.userData.profilePic : "",
        processState: this.props.location.state.Audit_State,
        auditStatus: this.props.location.state.AuditStatus,
      });
      this.setProcessState();
      this.formRef.current?.setFieldsValue({
        audittype: this.state.audittype,
        auditor: this.state.auditor,
        auditee: this.state.auditee,
        Assign_Date: this.state.date,
        processState: this.state.processState,
      });
    } else {
      let locations = await api.getAllLocations();
      await this.setState({
        locationDetails: locations,
        name: this.userData ? this.userData.name : "",
        username: this.userData ? this.userData.email : "",
        rolename: this.userData ? this.userData.roleName : "",
        profilePic: this.userData ? this.userData.profilePic : "",
        allAuditTypes: this.props.location.state.AllAuditType,
        userDetails: users,
        Assign_Date: this.props.location.state.Audit_Assign_Date,
      });
      this.setProcessState();
      this.formRef.current?.setFieldsValue({
        audittype: this.state.audittype,
        auditor: this.state.auditor,
        auditee: this.state.auditee,
        Assign_Date: this.state.date,
        processState: this.state.processState,
      });
    }
    this.setState({ show: false });
  }

  setProcessState = () => {
    const statusSplit = this.state.processState?.split(" ");
    let auditName: any = this.state?.allAuditTypes?.find(
      (auditType: any) => auditType.Id === this.state?.audittype
    )?.Name;

    if (this.state.processState !== null) {
      this.setState({
        processStateTag: "Process State",
        excecutionPhaseCount: Number(statusSplit[1]),
      });
    }

    if (!this.state.auditor && !this.state.auditee) {
      this.setState({
        audittype: this.state.audittype,
        processState: "Audit",
        phaseCountEditable: false,
        skipReaudit: false,
      });
    } else if (
      this.state.audittype === 1 &&
      this.state.reaudit === 0 &&
      this.state.auditStatus === "Done"
    ) {
      this.setState({
        audittype: this.state.audittype + 1,
        selectedaudittypeId: this.state.selectedaudittypeId + 1,
        processStateTag: "Process State Phase",
        processState: `Phase 1`,
        excecutionPhaseCount: 1,
        phaseCountEditable: false,
        skipReaudit: false,
      });
    } else if (
      this.state.audittype === 1 &&
      this.state.reaudit === 0 &&
      this.state.auditStatus === "Done" &&
      this.state.processState === "Re-audit"
    ) {
      this.setState({
        audittype: this.state.audittype + 1,
        selectedaudittypeId: this.state.selectedaudittypeId + 1,
        processStateTag: "Process State Phase",
        processState: `Phase ${Number(statusSplit[1])}`,
        excecutionPhaseCount: 1,
        phaseCountEditable: false,
        skipReaudit: false,
      });
    } else if (
      this.state.audittype === 1 &&
      this.state.reaudit === 1 &&
      this.state.auditStatus === "Done" &&
      this.state.processState === "Re-audit"
    ) {
      this.setState({
        audittype: this.state.audittype,
        processState: `Re-audit`,
        phaseCountEditable: false,
        skipReaudit: false,
      });
    } else if (
      this.state.audittype === 1 &&
      this.state.reaudit === 1 &&
      this.state.auditStatus === "Done"
    ) {
      this.setState({
        audittype: this.state.audittype,
        processState: `Re-audit`,
        phaseCountEditable: false,
        skipReaudit: false,
      });
    } else if ((this.state.audittype === 2 || this.state.audittype >= 9) && this.state.auditStatus == "Done") {
      if (this.state.reaudit === Constants.REQUIRE_REAUDIT) {
        if (statusSplit[3] === "Re-audit") {
          this.setState({
            audittype: this.state.audittype,
            selectedaudittypeId: this.state.selectedaudittypeId,
            processStateTag: "Process State Phase",
            processState: `Phase ${Number(statusSplit[1]) + Number(1)}`,
            excecutionPhaseCount: Number(statusSplit[1]) + Number(1),
            phaseCountEditable: false,
            skipReaudit: true,
          });
        } else {
          this.setState({
            processState: `Phase ${Number(statusSplit[1])} - Re-audit`,
            phaseCountEditable: false,
            skipReaudit: false,
          });
        }
      } else if (this.state.reaudit === Constants.AUDIT_COMPLETE) {
        this.setState({
          audittype: this.state.audittype,
          selectedaudittypeId: this.state.selectedaudittypeId,
          processStateTag: "Process State Phase",
          processState: `Phase ${Number(statusSplit[1]) + Number(1)}`,
          excecutionPhaseCount: Number(statusSplit[1]) + Number(1),
          phaseCountEditable: false,
          skipReaudit: false,
        });
      }
    } else if (
      this.state.audittype === 3 &&
      this.state.reaudit === 1 &&
      this.state.auditStatus === "Done"
    ) {
      this.setState({ processState: "Re-audit", phaseCountEditable: false });
    } else if (
      (this.state.audittype === 4 || this.state.audittype === 5) &&
      this.state.auditStatus === "Done"
    ) {
      if (this.state.reaudit === 1) {
        if (statusSplit[3] === "Re-audit") {
          this.setState({
            audittype: this.state.audittype,
            selectedaudittypeId: this.state.selectedaudittypeId,
            processState: `${moment(this.state.Assign_Date).format(
              "MMM YYYY"
            )}`,
            phaseCountEditable: false,
          });
        } else {
          this.setState({
            processState: `${this.state.processState} - Re-audit`,
            phaseCountEditable: false,
          });
        }
      } else if (this.state.reaudit === 0) {
        this.setState({
          audittype: this.state.audittype,
          selectedaudittypeId: this.state.selectedaudittypeId,
          processState: `${moment(this.state.Assign_Date).format("MMM YYYY")}`,
          phaseCountEditable: false,
        });
      }
    } else {
      if (this.state.audittype === 2 || this.state.audittype >= 9) {
        this.setState({
          processStateTag: "Process State Phase",
          processState: this.state.processState,
          phaseCountEditable: false,
        });
      }
    }
  };

  disabledDate(current: any) {
    return moment().add(-1, "days") >= current;
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  openNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Audit details saved successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to save audit details",
      });
    }
  };

  async removeConfirmPopup(e: any) {
    confirm({
      title: Constants.CONFIRM_CONTINUE,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          Assigned Auditor will be removed
        </div>
      ),
      okText: Constants.CONTINUE,
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      cancelText: "Cancel",
      centered: true,
      onOk: async () => {
        await this.removeAuditorDetails().then(async (e) => {
          await this.delay(500);
        });
      },
      onCancel: () => {},
    });
  }
  openSubmitNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Auditor assigned successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to assign auditor",
      });
    } else if (type == "duplicate") {
      notification.error({
        key,
        message: "Error",
        description: "Duplicate entry found",
      });
    } else if (type == "phase_validation") {
      notification.error({
        key,
        message: "Error",
        description: "Process state must be a number",
      });
    }
  };

  openCancelNotification = (type: any) => {
    const key = Constants.UPDATEABLE;
    if (type == Constants.SUCCESS) {
      notification.success({
        key,
        message: Constants.SUCCESS_MESSAGE,
        description: Constants.CANCEL_SUCCESS,
      });
    } else if (type == Constants.ERROR) {
      notification.error({
        key,
        message: Constants.ERROR_MESSAGE,
        description: Constants.CANCEL_ERROR,
      });
    }
  };

  navigateToLogin(e: any) {
    api.logOut();
  }

  dashboard = () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  auditSummaryDashboard = async () => {
    this.props.history.push({
      pathname: "/assignaudits",
    });
  };

  auditorhandleChange = async (value: any) => {
    this.setState({ auditor: value });
  };

  auditeehandleChange = async (value: any) => {
    switch (value) {
      case "HR Audit":
        this.setState({ auditee: "HR Admin" });
        break;

      case "System Audit":
        this.setState({ auditee: "System Admin" });
        break;
      default:
        this.setState({ auditee: value });
        break;
    }
  };

  auditTypeHandleChange = (value: any) => {
    let auditName: any = this.state?.allAuditTypes?.find(
      (auditType: any) => auditType.Id === value
    )?.Name;
    if (value === 1 || value === 3) {
      if (this.state.reaudit === 1 && (value==this.state.auditType)) {
        this.setState({
          processState: "Re-audit",
          audittype: value,
          phaseCountEditable: false,
        });
        this.formRef.current?.setFieldsValue({
          processState: "Re-audit",
        });
      } else {
        this.setState({
          processState: "Audit",
          audittype: value,
          phaseCountEditable: false
        });
        this.formRef.current?.setFieldsValue({
          processState: "Audit",
        });
      }
    } else if (value === 2) {
     
      this.setState({
        processState: (this.state.excecutionPhaseCount )
          ? `Phase ${this.state.excecutionPhaseCount}`
          : `Phase 1`,
        excecutionPhaseCount: (this.state.excecutionPhaseCount)
          ? Number(this.state.excecutionPhaseCount)
          : 1,
        processStateTag: "Process State Phase",
        audittype: value,
        phaseCountEditable: false,
      });

      this.formRef.current?.setFieldsValue({
        processState: this.state.excecutionPhaseCount
          ? `Phase ${this.state.excecutionPhaseCount}`
          : `Phase 1`,
        excecutionPhaseCount: this.state.excecutionPhaseCount
          ? Number(this.state.excecutionPhaseCount)
          : 1,
        processStateTag: "Process State Phase",
        audittype: value,
        phaseCountEditable: true,
      });

      this.formRef.current?.setFieldsValue({
        processState: (this.state.excecutionPhaseCount )
          ? `Phase ${this.state.excecutionPhaseCount}`
          : `Phase 1`,
      });
    }else if (value >= 9) {
      
      this.setState({
        processState: (this.state.excecutionPhaseCount )
          ? `Phase ${this.state.excecutionPhaseCount}`
          : `Phase 1`,
        excecutionPhaseCount: (this.state.excecutionPhaseCount )
          ? Number(this.state.excecutionPhaseCount)
          : 1,
        processStateTag: "Process State Phase",
        audittype: value,
        phaseCountEditable: true,
      });

      this.formRef.current?.setFieldsValue({
        processState: (this.state.excecutionPhaseCount )
          ? `Phase ${this.state.excecutionPhaseCount}`
          : `Phase 1`,
      });
    }  else if (value.value === 4 || value.value === 5) {
      this.setState({
        processState: moment(this.state.Assign_Date)
          .subtract(1, "months")
          .format("MMM YYYY"),
        audittype: value,
        phaseCountEditable: false,
      });
      this.formRef.current?.setFieldsValue({
        processState: moment(this.state.Assign_Date)
          .subtract(1, "months")
          .format("MMM YYYY"),
      });
    }
    this.setState({
      auditIdselected: value.key,
      selectedAuditType: value.label,
      selectedaudittypeId: value.value ? value.value : value,
      selectedAuditee:
        value?.value === 4
          ? "HR Admin"
          : value?.value === 5
          ? "System Admin"
          : "",
    });
  };

  datehandleChange = async (value: any) => {
    this.setState({ Assign_Date: value })
  }

  locationHandleChange = (value: number, option: any) => {
    this.setState({
      selectedLocation: value,
      selectedLocationName: option.children,
    });
  };

  validatePhaseCount = () => {
    if (
      !/^\d+$/.test(this.state.excecutionPhaseCount) &&
      this.state.audittype != 1
    ) {
      this.openSubmitNotification("phase_validation");
      return false;
    } else return true;
  };

  insertAuditorDetails = async () => {
    let data = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditAssign_ID,
      questionId: this.state.selectedQuestionId,
      projectId: this.state.selectedProjectId,
      auditId: this.state.selectedAuditId,
      auditor: this.state.auditor,
      auditee: this.state.auditee,
      reaudit: this.state.reaudit,
      auditassigndate: this.state.Assign_Date
 ? moment(this.state.Assign_Date).format("DD MMM YYYY")
: moment().format("DD MMM YYYY"),
      auditStatus: this.state.auditStatus === "In Progress" ? 2 : 1,
      count: 0,
      processState: this.state.processState,
      skipReaudit: this.state.skipReaudit,
    };
    var projectSubmit = await api.assignAuditor(data);

    if (projectSubmit) {
      this.openSubmitNotification("success");
      this.auditSummaryDashboard();
    } else {
      this.openSubmitNotification("error");
    }
  };

  removeAuditorDetails = async () => {
    let auditorCancelData = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditAssign_ID,
      questionId: this.state.selectedQuestionId,
      projectId: this.state.selectedProjectId,
      auditId: this.state.selectedAuditId,
      auditor: null,
      auditee: this.state.auditee,
      reaudit: this.state.reaudit,
      auditassigndate:  moment().format("DD MMM YYYY"),
      auditStatus: this.state.auditStatus === "In Progress" ? 2 : 1,
      count: 0,
      processState: this.state.processState,
      skipReaudit: this.state.skipReaudit,
    };
    var removeAuditor = await api.cancelAuditor(auditorCancelData);

    if (removeAuditor) {
      this.openCancelNotification("success");
      this.auditSummaryDashboard();
    } else {
      this.openCancelNotification("error");
    }
  };

  assignAuditor = async (e: any) => {
    let auditName: any = this.state?.allAuditTypes?.find(
      (auditType: any) => auditType.Id === this.state?.audittype
    )?.Name;

    if (
      ((this.state.audittype === 2 || this.state.audittype >= 9  || this.state.audittype === 1) &&
        this.state.reaudit === 0) ||
      this.state.phaseCountEditable === true
    ) 
    // if (
    //   (((this.state.audittype === 2 && this.state.reaudit === 0 )
    //   || (this.state.audittype === 1 && this.state.reaudit === 0))))
      {

      if (this.validatePhaseCount() && this.state.reaudit === 0 && this.state.audittype === 2) {
        this.setState(
          {
            processState: `Phase ${this.state.excecutionPhaseCount}`,
          },
          () => {
            this.insertAuditorDetails();
          }
        );
      }else if (this.validatePhaseCount() && this.state.reaudit === 0 && this.state.audittype === 1) {
        this.setState(
          {
            processState: "Audit",
          },
          () => {
            this.insertAuditorDetails();
          }
        );
      }
       else if ( this.validatePhaseCount() && this.state.reaudit === 1 && this.state.audittype === 1) {
        this.setState(
          {
            processState: "Re-audit",
          },
          () => {
            this.insertAuditorDetails();
          }
        );
      }
      else{
        this.insertAuditorDetails();
      }
    } else {

      this.insertAuditorDetails();
    }
  };

  async assignOtherAudits(e: any) {
    let data = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditAssign_ID,
      projectname:
        this.state.selectedAuditType + " - " + this.state.selectedLocationName,
      auditId: this.state.selectedAuditId,
      auditor: this.state.auditor,
      auditee: this.state.auditee,
      reaudit: this.state.reaudit,
      auditIdselected: this.state.auditIdselected,
      auditassigndate: this.state.Assign_Date
? moment(this.state.Assign_Date).format("DD MMM YYYY") : moment().format("DD MMM YYYY"),
      value: 0,
      processState: this.state.processState,
      location: this.state.selectedLocation,
    };

    await api.getAllProject().then(async (allProjects) => {
      let duplicatedEntry = allProjects.find(
        (project: any, index: number) =>
          project.AuditTypeId === this.state.selectedaudittypeId &&
          project.LocationId === this.state.selectedLocation
      );

      if (duplicatedEntry) {
        this.openSubmitNotification("duplicate");
      } else {
        let projectSubmit = await api.assignOtherAudits(data);
        if (projectSubmit) {
          this.openSubmitNotification("success");
          this.dashboard();
        } else {
          this.openSubmitNotification("error");
        }
      }
    });
  }

  phaseChangeHandler = async (e: any) => {
    const targetValue = e.target.value?.split(" ");
    this.setState({ excecutionPhaseCount: targetValue[1] });
  };

  render() {
    return (
      <Spin
        spinning={this.state.show}
        style={{
          alignContent: "center",
          alignSelf: "center",
          marginTop: "15%",
        }}
      >
        <Layout className="h-100">
          <Header className="header">
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="logo is-text">
                  <a onClick={(e) => this.dashboard()}>Auto Audit</a>
                </div>
              </div>
              <div className="flex-auto">
                <div className="section-log-out">
                  <div className="group-profile d-flex">
                    <div className="d-flex">
                      <Dropdown overlay={this.menu} trigger={["click"]}>
                        <a
                          className="ant-dropdown-link menu-a d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="flex-auto mr-2">
                            <div className="avathar">
                              {/* <UserOutlined /> */}
                              <img src={this.state.profilePic} alt="user-pic" />
                            </div>
                          </div>
                          <div className="flex-fill mr-2">
                            <p className="login-text m-0">
                              {" "}
                              {this.state.name}{" "}
                              <span>{this.state.rolename}</span>
                            </p>
                          </div>
                          <div className="flex-auto">
                            <CaretDownOutlined />
                          </div>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Header>
          <Layout>
            <div className="page-title">
              <div className="container">
                <h1>
                  {this.state.projectname ? this.state.projectname + " -" : ""}{" "}
                  Assign Auditor{" "}
                </h1>
              </div>
            </div>
            <Content
              className="site-layout-background custom-position"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <div className="container">
                <div className="card p-4 form-group-area mb-4">
                  <div className="main-content">
                    <Form {...layout} name="basic" ref={this.formRef}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Auditor"
                            name="auditor"
                            rules={[
                              {
                                required: true,
                                message: "Auditor is required",
                              },
                            ]}
                          >
                            <Select
                              onChange={this.auditorhandleChange}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Select Auditor"
                              value={this.state.auditor}
                            >
                              {this.state.userDetails.map(
                                (
                                  auditstatus: { Id: number; Name: string },
                                  index: number
                                ) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={auditstatus.Id}>
                                        {auditstatus.Name}
                                      </Option>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Auditee"
                            //  name="auditee"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Auditee is required",
                              },
                            ]}
                          >
                            {this.props.location.state.projectId ? (
                              this.props.location.state.AuditTypeId === 4 ? (
                                <Select disabled value={"HR Admin"}>
                                  <Option selected value={"HR Admin"}>
                                    HR Admin
                                  </Option>
                                </Select>
                              ) : this.props.location.state.AuditTypeId ===
                                5 ? (
                                <Select disabled value={"System Admin"}>
                                  <Option selected value={"System Admin"}>
                                    System Admin
                                  </Option>
                                </Select>
                              ) : (
                                <Select
                                  onChange={this.auditeehandleChange}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder="Select Auditee"
                                  value={
                                    this.state.auditee !== null
                                      ? Number(this.state.auditee)
                                      : this.state.auditee
                                  }
                                >
                                  {this.state.auditeeList
                                    ?.filter((value: any) => {
                                      return value.Isactive == 1;
                                    })
                                    .reduce(
                                      (
                                        removeDuplicateId: any[],
                                        auditee: any
                                      ) => {
                                        if (
                                          !removeDuplicateId.some(
                                            (option: any) =>
                                              option.id === auditee.id
                                          )
                                        ) {
                                          removeDuplicateId.push(auditee);
                                        }
                                        return removeDuplicateId;
                                      },
                                      []
                                    )
                                    .map((auditee: any) => {
                                      return (
                                        <React.Fragment key={auditee.id}>
                                          <Option
                                            key={auditee.id}
                                            value={Number(auditee.id)}
                                          >
                                            {auditee.Name}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    })}
                                </Select>
                              )
                            ) : this.state.selectedAuditType != null ? (
                              <Select
                                onChange={this.auditeehandleChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Select Auditee"
                                value={Number(this.state.auditee)}
                              >
                                <Option value={this.state.selectedAuditee}>
                                  {this.state.selectedAuditee}
                                </Option>
                              </Select>
                            ) : (
                              <Select
                                onChange={this.auditeehandleChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Select Auditee"
                                disabled
                              >
                                <Option value={this.state.selectedAuditee}>
                                  {this.state.selectedAuditee}
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Audit Type"
                            name="audittype"
                            rules={[
                              {
                                required: true,
                                message: "Audit Type is required",
                              },
                            ]}
                          >
                            {this.props.location.state.projectId ? (
                              this.props.location.state.AuditTypeId === 4 ? (
                                <Select disabled value={"HR Audit"}>
                                  <Option
                                    selected
                                    value={
                                      this.props.location.state.AuditTypeId
                                    }
                                  >
                                    HR Audit
                                  </Option>
                                </Select>
                              ) : this.props.location.state.AuditTypeId ===
                                5 ? (
                                <Select disabled value={"System Audit"}>
                                  <Option
                                    selected
                                    value={
                                      this.props.location.state.AuditTypeId
                                    }
                                  >
                                  System Audit
                                </Option>
                              </Select>
                            ) : (
                              <Select
                                onChange={this.auditTypeHandleChange}
                                placeholder="Select Audit Type"
                                value={this.state.audittype}
                              >
                                {this.state.auditTypesDetails.filter((item:any )=> item.projectTypeId === this.state.selectedProjectAuditTypeId).map(
                                  (
                                    auditstatus: { auditTypeId: number; auditTypeName: string },
                                    index: number
                                  ) => {
                                    
                                    return (
                                      <React.Fragment key={index}>
                                        <Option value={Number(auditstatus.auditTypeId)}>
                                          {auditstatus.auditTypeName}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </Select>
                            )
                          ) : (
                            <Select
                              onChange={this.auditTypeHandleChange}
                              labelInValue={true}
                              placeholder="Select Audit Type"
                            >
                              {this.state.allAuditTypes
                                .filter((audit: any) => {
                                  return (
                                    audit.Name == "HR Audit" ||
                                    audit.Name == "System Audit"
                                  );
                                })
                                .map((audit: any) => {
                                  return (
                                    <Option value={Number(audit.Id)}>
                                      {audit.Name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          name="date"
                          label="Date"
                          rules={[
                            {
                              required: true,
                              message: "Date is required",
                            },
                          ]}
                        >
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={12}
                          >
                             <DatePicker className="w-100" allowClear={false} value={this.state.Assign_Date !== null ? moment(this.state.Assign_Date) : moment()} size="large" format={dateFormat} onChange={this.datehandleChange} disabledDate={this.disabledDate} />
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          // name="state"
                          name="processState"
                          label={this.state.processStateTag}
                          rules={[
                            {
                              required: true,
                              message: "Process State is required",
                            },
                            {
                              max: 10,
                              message: "Maximum 10 characters allowed",
                            },
                          ]}
                        >
                          <Input
                            // disabled={this.state.audittype === 2 && this.state.reaudit === 0 || this.state.reaudit === 1 ? false : true}
                            disabled={
                              this.state.phaseCountEditable === true
                                ? false
                                : true
                            }
                            name="processState"
                            maxLength={11}
                            placeholder={this.state.processStateTag}
                            value={this.state.processState}
                            onChange={this.phaseChangeHandler}
                          />
                        </Form.Item>
                      </Col>
                      {this.props.location.state.projectId ? (
                        ""
                      ) : (
                        <Col span={12}>
                          <Form.Item
                            label="Location"
                            name="location"
                            rules={[
                              {
                                required: true,
                                message: "Location is required",
                              },
                            ]}
                          >
                            <Select
                              onChange={this.locationHandleChange}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Select Location"
                              // value={this.state.selectedLocation}
                            >
                              {this.state.locationDetails.map(
                                (
                                  location: {
                                    Loc_ID: number;
                                    Loc_Name: string;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={location.Loc_ID}>
                                        {location.Loc_Name}
                                      </Option>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <div className="text-center mt-4">
                      <div className="d-inline-block mx-2">
                        <Button
                          className="custom-btn mr-2"
                          onClick={(e) => this.auditSummaryDashboard()}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                      </div>
                      {this.props.location.state.projectId ? (
                        <div className="d-inline-block mx-2">
                          <Button
                            className="custom-btn"
                            type="primary"
                            onClick={(e) => this.assignAuditor(e)}
                            disabled={
                              !this.state.auditee ||
                              this.state.auditor === null ||
                              !this.state.audittype
                            }
                          >
                            {" "}
                            Assign{" "}
                          </Button>
                        </div>
                      ) : (
                        <div className="d-inline-block mx-2">
                          <Button
                            className="custom-btn"
                            type="primary"
                            onClick={(e) => this.assignOtherAudits(e)}
                            disabled={
                              this.state.auditee.length > 0 &&
                              this.state.auditor !== null &&
                              this.state.selectedLocation !== 0
                                ? false
                                : true
                            }
                          >
                            {" "}
                            Assign{" "}
                          </Button>
                        </div>
                      )}

                        {this.state.auditStatus === "New" && (
                          <div className="d-inline-block mx-2">
                            <Button
                              className="custom-btn mr-2"
                              onClick={(e) => this.removeConfirmPopup(e)}
                              disabled={
                                this.state.auditee &&
                                this.state.auditor !== null
                                  ? false
                                  : true
                              }
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}
export default AssignAuditor;
