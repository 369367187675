import React from "react";
import {
  Layout,
  Menu,
  Form,
  Button,
  Select,
  Dropdown,
  Row,
  Col,
  Card,
  Modal,
  Table,
  Tooltip,
  Spin,
  notification,
} from "antd";
import {
  CaretDownOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { api } from "../../../apiServices/apiServices";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Constants } from "../../../constants/constants";
import Editor from "../../Editor/Editor";
import { mailRecipients } from "../../../constants/mailRecipients";
import { log } from "@antv/g2plot/lib/utils";
const { Option } = Select;
const { Header, Content } = Layout;

class AuditReports extends React.Component<any, any> {
  navigateToLogin(e: any) {
    api.logOut();
  }

  menu = (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          this.props.history.push("/auditordashboard");
        }}
      >
        Auditor Dashboard
      </Menu.Item>
    </Menu>
  );
  formRef = React.createRef<FormInstance>();
  userData: any = "";
  loading: boolean = false;
  doneStatusProject: any = [];
  projectReport: any = [];
  allDoneProjects: any = [];

  projectcolumns = [
    {
      title: "Code",
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: "Project",
      dataIndex: "Project",
      key: "Project",
    },
    {
      title: "Audit Type",
      dataIndex: "AuditType",
      key: "AuditType",
    },
    {
      title: "Audit Status",
      dataIndex: "AuditStatus",
      key: "AuditStatus",
    },
    {
      title: "Auditor",
      dataIndex: "Auditor",
      key: "Auditor",
    },
    {
      title: "NC",
      dataIndex: "NC",
      key: "NC",
    },
    {
      title: "PC",
      dataIndex: "PC",
      key: "PC",
    },
    {
      title: "BNC",
      dataIndex: "BNC",
      key: "BNC",
    },
    {
      title: "Created Date",
      dataIndex: "AuditAssignDate",
      key: "AuditAssignDate",
    },
  ];
  columns = [
    {
      title: "Category",
      dataIndex: "Category_Name",
      key: "Category_Name",
    },
    {
      title: "Question",
      dataIndex: "Question",
      key: "Question",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
      key: "Remarks",
    },
    {
      title: "Responsibility",
      dataIndex: "Responsibility",
      key: "Responsibility",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
    {
      title: "Action Status",
      dataIndex: "Actionstatus",
      key: "Actionstatus",
    },
    {
      title: "Target Date",
      dataIndex: "Target",
      key: "Target",
    },
  ];

  projectSummaryColumns = [
    {
      title: "Code",
      dataKey: "Code",
    },
    {
      title: "Project",
      dataKey: "Project",
    },
    {
      title: "Audit Type",
      dataKey: "AuditType",
    },
    {
      title: "Audit Status",
      dataKey: "AuditStatus",
    },
    {
      title: "Auditor",
      dataKey: "Auditor",
    },
    {
      title: "NC",
      dataKey: "NC",
    },
    {
      title: "PC",
      dataKey: "PC",
    },
    {
      title: "BNC",
      dataKey: "BNC",
    },
    {
      title: "Created Date",
      dataKey: "AuditAssignDate",
    },
  ];

  reportColumns = [
    {
      title: "Category",
      dataKey: "Category_Name",
    },
    {
      title: "Question",
      dataKey: "Question",
    },
    {
      title: "Status",
      dataKey: "Status",
    },
    {
      title: "Remarks",
      dataKey: "Remarks",
    },
    {
      title: "Responsibility",
      dataKey: "Responsibility",
    },
    {
      title: "Action",
      dataKey: "Action",
    },
    {
      title: "Action Status",
      dataKey: "Actionstatus",
    },
    {
      title: "Target Date",
      dataKey: "Target",
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
      rolename: "",
      allAuditStatuses: [],
      filteredarray: [],
      popoverDisplay: false,
      toggle: true,
      isEnable: true,
      selectedAuditStatus: null,
      selectedProject: null,
      auditstatuses: [],
      emailSubject: "",
      emailBody: "",
      error: { errormessage: "", auditstatusId: null },
      show: false,
      loading: true,
      visible: false,
      shareVisible: false,
      NC: 0,
      PC: 0,
      OK: 0,
      NA: 0,
      BNC: 0,
      reportProject: "",
      reportauditstatus: "",
      reportData: [],
      projectInfo: [],
      projectID: "",
      changedQuestions: [],
      isReAudit: "",
      projectDetails: [],
      fileName: "",
      isDisable: true,
      projectsForReports: [],
      emailTemplate: "hi",
      selectedFile: "",
      hidden: 0,
      auditeeName: "",
      auditorName: "",
      auditeeEmail: "",
      resetSelect: false,
      updatedEmailList: [],
      updatedEmail: [],
      projectList: [],
      doneProjects: [],
      doneProjectList: [],
      allDoneProjects: [],
      tempDoneProjects: [],
    };
  }

  async componentDidMount() {
    this.getAllProjects();
  }

  getAllProjects = async () => {
    this.setState({ show: true });
    this.doneStatusProject = [];
    var allAuditStatuses = [];

    allAuditStatuses = await api.getAllAuditStatus();

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token) || {};

      if (
        this.userData?.userRole === Constants.roleId.auditor ||
        this.userData?.userRole === Constants.roleId.admin
      ) {
        this.setState({ isEnable: false });
        var projectlist: any = localStorage.getItem("allProjects");
        this.setState({ projectList: JSON.parse(projectlist) });
        const currentDate = new Date();
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );

        const filteredArr = JSON.parse(projectlist).filter(
          (obj: { auditDate: string | number | Date; Displayed: number }) => {
            const objDate = new Date(obj.auditDate);
            return (
              objDate >= currentMonthStart &&
              objDate <= currentDate &&
              obj.Displayed == 0
            );
          }
        );
        filteredArr.sort(
          (a: any, b: any) =>
            new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
        );

        this.setState({ doneProjectList: filteredArr });
      }
    }
    this.setState({
      show: false,
      name: this.userData ? this.userData.name : "",
      username: this.userData ? this.userData.email : "",
      rolename: this.userData ? this.userData.roleName : "",
      profilePic: this.userData ? this.userData.profilePic : "",
      allAuditStatuses: allAuditStatuses,
      selectedProject: 0,
      selectedAuditStatus: 0,
      roleId: this.userData ? this.userData.userRole : null,
    });

    this.formRef.current?.setFieldsValue({
      selectedProject: 0,
      selectedAuditStatus: 0,
    });

    this.state.doneProjectList.map(async (x: any) => {
      if (x.auditstatusId === 3 && x.Displayed == 0) {
        this.doneStatusProject.push(x);
      }
    });
    this.state.projectList.map(async (x: any) => {
      if (x.auditstatusId === 3 && x.Displayed == 0) {
        this.allDoneProjects.push(x);
      }
    });
    this.setState({
      allDoneProjects: this.allDoneProjects,
      doneProjects: this.doneStatusProject,
      tempDoneProjects: this.doneStatusProject,
    });
  };

  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    projectName: any,
    Auditee: any,
    auditTypeName: any,
    auditState: any,
    parent: any
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        projectName: projectName,
        Auditee: Auditee,
        auditTypeName: auditTypeName,
        auditState: auditState,
        parent: "auditreports",
      },
    });
  };

  showModal = async () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e: any) => {
    this.setState({
      visible: false,
      selectedValue: null,
      projectDetails: [],
      reportData: [],
      resetSelect: true,
    });
  };

  handleCancel = (e: any) => {
    this.setState({
      selectedValue: null,
      projectDetails: [],
      reportData: [],
      visible: false,
      resetSelect: true,
    });
  };

  reportProjecthandleChange = async (value: any, id: any) => {
    this.setState({
      show: true,
      selectedValue: value,
      isDisable: true,
      resetSelect: false,
      projectDetails: [],
      reportData: [],
    });
    this.setState({
      projectID: id.id,
    });

    var report: any = [];
    var headerdata: any = [];
    await api
      .getReport(value)
      .then((data: any) => {
        this.setState({ show: false });
        data.map((x: any) => {
          let responsibility = "";
          let answerValue = JSON.parse(x.Answer);
          let projectTeams = JSON.parse(x.ProjectTeam);
          projectTeams?.map((team: any) => {
            if (Number(answerValue.responsibility) === Number(team.id)) {
              responsibility = team.Name;
            }
          });
          let targetDate = answerValue.target
            ? moment(answerValue.target).format("DD MMM YYYY")
            : "";
          report.push({
            Category_Name: x.Category_Name,
            Question: x.Question,
            Status: answerValue.status,
            Responsibility: responsibility
              ? responsibility
              : answerValue.responsibility,
            Actionstatus: answerValue.actionstatus,
            Action: answerValue.action,
            Target: targetDate,
            Remarks: answerValue.remarks,
          });
        });
      })
      .then(() => {
        this.state.projectList.map((project: any, index: number) => {
          if (project.Audit_Id == value && project.auditstatusId == 3) {
            this.setState({
              fileName: `${project.Name} - ${
                project.Audit_Type_Name && project.AuditTypeId < 4
                  ? project.Audit_Type_Name
                  : ""
              }${project.Audit_State ? " " + project.Audit_State : ""}`,
            });
            headerdata.push({
              Id: project.TaProjectId,
              Code: project.Code,
              Project: project.Name,
              AuditType:
                project.Audit_Type_Name +
                (project.Audit_State ? " - " + project.Audit_State : ""),
              AuditStatus: project.AuditStatus,
              Auditor: project.name,
              NC: project.NC,
              PC: project.PC,
              BNC: project.BNC,
              AuditAssignDate: moment(project.AuditAssignDate).format(
                "DD MMM YYYY"
              ),
            });
          }
        });
      })
      .then(() => {
        this.setState({
          reportData: report,
          projectDetails: headerdata,
          isDisable: false,
        });
      });
  };

  reportAuditStatushandleChange = async (value: any) => {
    this.setState({ reportauditstatus: value });
  };

  projectListing() {
    return (
      <div className="site-card-wrapper">
        <Row gutter={[16, 24]}>
          {this.state.doneProjects.map((project: any, index: number) => {
            let auditee = "";
            let projectTeams = JSON.parse(project.ProjectTeam);
            projectTeams?.map((team: any, index: number) => {
              if (Number(project.Auditee) === Number(team.id)) {
                auditee = team.Name;
              }
            });
            this.loading = false;
            return (
              <Col
                key={project.Audit_Id}
                className="gutter-row"
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={6}
              >
                <Card
                  key={project.Audit_Id}
                  size="small"
                  hoverable
                  className="ant-card card-user h-100 p-0 custom-card"
                  title={
                    <div className="d-flex">
                      <div className="flex-fill text-ellipse">
                        <Tooltip
                          title={`${project.Name} - ${
                            project.Audit_Type_Name && project.AuditTypeId < 4
                              ? project.Audit_Type_Name
                              : ""
                          }${
                            project.Audit_State ? " " + project.Audit_State : ""
                          }`}
                        >
                          <span>
                            <b>
                              {project.Name} -{" "}
                              {project.Audit_Type_Name &&
                              project.AuditTypeId < 4
                                ? project.Audit_Type_Name
                                : ""}
                              {project.Audit_State
                                ? "  " + project.Audit_State
                                : ""}
                            </b>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  }
                  bordered={false}
                  loading={this.loading}
                  onClick={(e) => {
                    this.navigate(
                      `/auditform`,
                      project.auditstatusId,
                      project.project_Id,
                      project.AuditTypeId,
                      project.ProjectTeam,
                      project.NC,
                      project.PC,
                      project.BNC,
                      project.NA,
                      project.OK,
                      project.Reaudit,
                      project.Audit_Id,
                      project.Name,
                      project.Auditee,
                      project.Audit_Type_Name,
                      project.Audit_State,
                      "auditreports"
                    );
                  }}
                >
                  <div className="d-flex px-3 py-2">
                    {project.AuditDate ? (
                      <div className="flex-fill mr-2">
                        <p className="m-0">
                          <b className="d-block">Audit Date: </b>{" "}
                          {project.AuditDate
                            ? moment(project.AuditDate).format("DD MMM YYYY")
                            : ""}{" "}
                        </p>
                      </div>
                    ) : (
                      <div className="flex-fill mr-2">
                        <p className="m-0">
                          <b className="d-block">Audit Date: </b>{" "}
                          {project.AuditAssignDate
                            ? moment(project.AuditAssignDate).format(
                                "DD MMM YYYY"
                              )
                            : ""}{" "}
                        </p>
                      </div>
                    )}
                    <div className="mr-auto">
                      <p className="m-0">
                        <b className="d-block">Auditee:</b>{" "}
                        {auditee ? auditee : project.Auditee}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="card-footer px-3 py-2">
                    <div className="d-flex">
                      <div className="flex-fill">
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>NC:</b>{" "}
                                <span className="status-count">
                                  {project.NC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>PC:</b>{" "}
                                <span className="status-count pc">
                                  {project.PC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-block align-middle mr-3">
                          {project.auditstatusId == 3 ? (
                            <div className="">
                              <p className="m-0">
                                <b>BNC:</b>{" "}
                                <span className="status-count bnc">
                                  {project.BNC}
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="flex-auto">
                        <span
                          className="current-status"
                          style={{
                            float: "right",
                            background:
                              project.auditstatusId == 2
                                ? "#ffa64d"
                                : project.auditstatusId == 3
                                ? "#73d13d"
                                : "#ff4d4f",
                          }}
                        >
                          <em>{project.AuditStatus}</em>
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  projecthandleChange = async (value: any) => {
    let auditProjects: any = [];
    let projectsForReports: any = [];

    this.state.doneProjectList.map((x: any) => {
      if (value === "0") {
        if (x.auditstatusId === 3) {
          projectsForReports.push(x);
        }
      } else if (x.auditstatusId === 3 && x.project_Id === value) {
        projectsForReports.push(x);
      }
    });
    this.state.projectList.map((x: any) => {
      if (value === 0) {
        this.setState({
          selectedAuditStatus: 0,
          doneProjects: this.state.tempDoneProjects,
          selectedProject: value,
        });
      } else if (
        x.project_Id === value &&
        x.auditstatusId === 3 &&
        x.Displayed === 0
      ) {
        auditProjects.push(x);
        this.setState({
          selectedAuditStatus: 0,
          doneProjects: auditProjects,
          selectedProject: value,
        });
      }
    });
    this.formRef.current?.setFieldsValue({
      selectedProject: value,
    });

    this.doneStatusProject = projectsForReports;
  };

  dashboard = () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  styles = StyleSheet.create({
    page: { backgroundColor: "tomato" },
    section: { color: "white", textAlign: "center", margin: 30 },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  download = async () => {
    this.setState({ show: true });
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const Component: any = document.getElementById("report");
    const doc = new jsPDF(orientation, unit, size);
    doc.html(Component);
    autoTable(doc, {
      startY: 50,
      headStyles: {
        fontSize: 8,
        fillColor: "#2b7d86",
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      bodyStyles: {
        fontSize: 8,
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      columnStyles: { AuditeeAssignDate: { minCellWidth: 60 } },
      alternateRowStyles: {
        fillColor: "#FFFFFF",
      },
      body: this.state.projectDetails,
      columns: this.projectSummaryColumns,
    });

    autoTable(doc, {
      startY: 125,
      headStyles: {
        fontSize: 8,
        fillColor: "#2b7d86",
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      bodyStyles: {
        fillColor: "#dcf7fa",
        fontSize: 8,
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      columnStyles: {
        Remarks: { minCellWidth: 70 },
        Target: { minCellWidth: 60 },
      },
      alternateRowStyles: {
        fillColor: "#FFFFFF",
      },
      rowPageBreak: "avoid",
      body: this.state.reportData,
      columns: this.reportColumns,
    });
    doc.save(`${this.state.fileName}.pdf`);
    this.setState({ show: false });
  };

  refreshPage = () => {
    this.setState(
      {
        selectedValue: null,
        selectedProject: 0,
        searchText: "",
        projectDetails: [],
        reportData: [],
        resetSelect: true,
      },
      () => {
        this.getAllProjects();
      }
    );
  };
  removeItem = (index: number) => {
    const updatedList = this.state.updatedEmailList;
    updatedList.splice(index, 1);

    this.setState({ updatedEmailList: updatedList });
  };

  render() {
    this.loading = true;
    let filteredProjects = this.state.allDoneProjects.reduce(
      (accumulator: any[], current: { project_Id: any }) =>
        accumulator.some((x) => x.project_Id === current.project_Id)
          ? accumulator
          : [...accumulator, current],
      []
    );
    return (
      <Layout className="h-100">
        <Spin spinning={this.state.show}>
          <Header className="header">
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <div className="logo is-text">
                  <a onClick={(e) => this.dashboard()}>Auto Audit</a>
                </div>
              </div>
              <div className="flex-auto">
                <div className="section-log-out">
                  <div className="group-profile d-flex">
                    <div className="d-flex">
                      <Dropdown overlay={this.menu} trigger={["click"]}>
                        <a
                          className="ant-dropdown-link menu-a d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="flex-auto mr-2">
                            <div className="avathar">
                              <img src={this.state.profilePic} alt="user-pic" />
                            </div>
                          </div>
                          <div className="flex-fill mr-2">
                            <p className="login-text m-0">
                              {" "}
                              {this.state.name}{" "}
                              <span>{this.state.rolename}</span>
                            </p>
                          </div>
                          <div className="flex-auto">
                            <CaretDownOutlined />
                          </div>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Header>
          <Layout>
            <Layout>
              <div className="page-title">
                <div className="container-fluid">
                  <h1>Audit Reports</h1>
                </div>
              </div>
              <Content
                className="site-layout-background custom-position"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Modal
                  title="Report"
                  visible={this.state.visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  width="1000px"
                >
                  <Row gutter={16}>
                    <Col flex="auto">
                      <Form.Item name="project1">
                        <Select
                          key={this.state.resetSelect.toString()}
                          value={
                            this.state.resetSelect
                              ? undefined
                              : this.state.selectedValue
                          }
                          placeholder="Project - Audit Type"
                          allowClear
                          onChange={this.reportProjecthandleChange}
                          disabled={this.state.show}
                        >
                          {this.state.doneProjects.map(
                            (project: any, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <Option
                                    value={project.Audit_Id}
                                    id={project.project_Id}
                                  >
                                    {project.Name}{" "}
                                    {project.Audit_Type_Name &&
                                    project.AuditTypeId < 4
                                      ? " - " + project.Audit_Type_Name
                                      : ""}{" "}
                                    {project.Audit_State
                                      ? "- " + project.Audit_State
                                      : ""}{" "}
                                  </Option>
                                </React.Fragment>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      style={{
                        display: this.state.selectedValue ? "block" : "none",
                      }}
                    >
                      <Button
                        className="custom-btn mr-10"
                        target="_blank"
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={(e) => this.download()}
                        disabled={this.state.isDisable}
                      >
                        Download Report{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Spin spinning={this.state.show}>
                    <div
                      id="report"
                      style={{
                        display: this.state.selectedValue ? "block" : "none",
                      }}
                    >
                      <Document>
                        <Page wrap size="A4" style={this.styles.body}>
                          <View wrap={false}>
                            <Text style={this.styles.text}>
                              <div className="group-item">
                                <Table
                                  dataSource={this.state.projectDetails}
                                  columns={this.projectcolumns}
                                  size="middle"
                                  pagination={false}
                                />
                              </div>
                              <div className="group-item">
                                <Table
                                  dataSource={this.state.reportData}
                                  columns={this.columns}
                                  size="middle"
                                  pagination={false}
                                />
                              </div>
                            </Text>
                            <Text
                              style={this.styles.pageNumber}
                              render={({ pageNumber, totalPages }) =>
                                `${pageNumber} / ${totalPages}`
                              }
                              fixed
                            />
                          </View>
                        </Page>
                      </Document>
                    </div>
                  </Spin>
                </Modal>

                <div className="container-fluid h-100">
                  <div
                    className="h-100"
                    style={{ overflowX: "hidden", overflowY: "auto" }}
                  >
                    <div className="main-content">
                      <Form
                        {...Layout}
                        name="control-hooks"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        labelAlign="left"
                        colon={false}
                      >
                        <Row gutter={16}>
                          <Col span={4}>
                            <Form.Item>
                              <Select
                                placeholder="Project"
                                optionFilterProp="children"
                                allowClear
                                onChange={this.projecthandleChange}
                                value={this.state.selectedProject}
                              >
                                <Option value={0}>All Projects</Option>
                                {filteredProjects.map(
                                  (
                                    project: {
                                      project_Id: number;
                                      Name: string;
                                    },
                                    index: number
                                  ) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <Option value={project.project_Id}>
                                          {project.Name}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <div className="d-inline-block align-middle">
                              <Form.Item name="report">
                                <Button
                                  className="custom-btn"
                                  type="primary"
                                  onClick={this.showModal}
                                >
                                  Report
                                </Button>
                              </Form.Item>
                            </div>
                            <div className="d-inline-block align-middle mb-3 ml-3">
                              <Tooltip title="Refresh List">
                                <Button
                                  className="custom-icon-btn"
                                  type="primary"
                                  icon={<ReloadOutlined />}
                                  onClick={this.refreshPage}
                                ></Button>
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      {this.projectListing()}
                    </div>
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </Spin>
      </Layout>
    );
  }
}
export default AuditReports;
