import React from "react";
import {
  Layout,
  Menu,
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import { api } from "../../apiServices/apiServices";
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;
const { Header, Content, Sider } = Layout;
const menu = (
  <Menu>
    <Menu.Item key="0">Logout</Menu.Item>
  </Menu>
);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class AddNewProject extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allTaProjects: [],
      selectedProject: null,
      projectDetails: {
        ProjectId: Number,
        ProjectName: "",
        ProjectCode: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        CurrentDate: null,
        IsActive: Number,
        TeamMembers: [],
        projectAuditType: null,
      },
      teamMembersName: [],
      isDisable: true,
      projectAuditTypes: [],
      selectedProjectAuditType: null,
    };
  }

  async componentDidMount() {
    try {
      var projects = await api.getAllTaProjects();
      var auditProjects = await api.getAuditProjects();
      var filteredArray = projects.filter((project: any) => {
        if (project.holdDate == null) {
          if (
            project.status === "initiated" ||
            project.status === "in-progress"
          ) {
            return !auditProjects.some((auditProject: any) => {
              return project.ProjectId == auditProject.TaProjectId;
            });
          }
        } else return;
      });
      this.setState(
        {
          allTaProjects: filteredArray,
        },
        () => {
          this.getProjectAuditTypes();
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  projectHandleChange = async (value: any) => {
    this.setState({ show: true, selectedProject: value }, async () => {
      let aboutProject = await api.getTaProjectById(this.state.selectedProject);
      this.setState({ show: false });
      let teamMembersList = aboutProject[0]?.teamMembers;
      let teamMembers = teamMembersList.map((e: any) => {
        let team = {
          id: parseInt(e.employeeDetails.id),
          Name: e.employeeDetails.fullName,
          Isactive: 1,
        };
        return team;
      });
      let removeDulpicate = Array.from(
        new Set(teamMembers.map((e: any) => e.id))
      );
      let idToNameMap: any = {};
      teamMembers.forEach((e: any) => {
        if (removeDulpicate.includes(e.id) && e.Name) {
          idToNameMap[e.id] = e.Name;
          removeDulpicate = removeDulpicate.filter((id) => id !== e.id);
        }
      });

      let projectDetails = {
        ProjectId: aboutProject[0].ProjectId,
        ProjectName: aboutProject[0].ProjectName,
        ProjectCode: aboutProject[0].ProjectCode,
        Description: aboutProject[0].Description,
        StartDate: aboutProject[0].StartDate
          ? moment.utc(new Date(aboutProject[0].StartDate)).format("YYYY/MM/DD")
          : "",
        EndDate: aboutProject[0].EndDate
          ? moment.utc(new Date(aboutProject[0].EndDate)).format("YYYY/MM/DD")
          : "",
        CurrentDate: moment().format("YYYY/MM/DD"),
        IsActive: 1,
        TeamMembers: teamMembers,
      };
      this.setState({
        projectDetails: projectDetails,
        teamMembersName: idToNameMap,
      });
    });
  };

  getProjectAuditTypes = async () => {
    this.setState({ show: true });
    let projectAuditTypes = await api.getAllProjectTypes();
    await this.setState({ show: false, projectAuditTypes: projectAuditTypes });
  };

  projectAuditTypeHandleChange = (value: any) => {
    this.setState({ selectedProjectAuditType: value });
  };

  saveAddedProject = (e: any) => {
    this.setState(
      {
        isDisable: true,
        projectDetails: {
          ...this.state.projectDetails,
          projectAuditType: this.state.selectedProjectAuditType,
        },
      },
      async () => {
        await api
          .saveAddedProject(this.state.projectDetails)
          .then((saveChanges: any) => {
            if (saveChanges) {
              this.openSubmitNotification("success");
              this.auditSummaryDashboard();
            } else {
              this.openSubmitNotification("error");
            }
          });
      }
    );
  };

  openSubmitNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Project added successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to add project",
      });
    }
  };

  dashBoard = async () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  auditSummaryDashboard = async () => {
    this.props.history.push({
      pathname: "/assignaudits",
    });
  };

  changeHandler = (event: any) => {
    let projectDetails = this.state.projectDetails;
    projectDetails[event.target.name] = event.target.value;
    this.setState({ projectDetails: projectDetails });
  };

  render() {
    return (
      <Layout className="h-100">
        <Header className="header">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <div className="logo is-text">
                <a onClick={(e) => this.dashBoard()}>Auto Audit</a>
              </div>
            </div>
            <div className="flex-auto">
              <div className="section-log-out">
                <div className="group-profile d-flex">
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <Layout>
          <Layout>
            <div className="page-title">
              <div className="container">
                <h1>Add Project to Audit </h1>
              </div>
            </div>
            <Content
              className="site-layout-background custom-position"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Spin spinning={this.state.show}>
                <div className="container">
                  <div className="card p-4 form-group-area mb-4">
                    <div className="main-content">
                      <Form {...layout} name="basic">
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Project"
                              name="project"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Cant proceed without selecting project",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select project"
                                allowClear
                                onChange={this.projectHandleChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.allTaProjects.map(
                                  (project: any) => {
                                    return (
                                      <Select.Option
                                        key={project.ProjectId}
                                        value={project.ProjectId}
                                      >
                                        {project.ProjectName}
                                      </Select.Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Project Type"
                              name="projectAuditType"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Cant proceed without selecting project  type",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select project type"
                                allowClear
                                onChange={this.projectAuditTypeHandleChange}
                              >
                                {this.state.projectAuditTypes.map(
                                  (type: any) => {
                                    return (
                                      <Select.Option
                                        key={type.project_audit_type_id}
                                        value={type.project_audit_type_id}
                                      >
                                        {type.project_audit_type_name}
                                      </Select.Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          {/*<Col span={12}>*/}
                          {/*  <Form.Item*/}
                          {/*    label="Project Name"*/}
                          {/*    rules={[{ required: true, message: 'Please input your project name!' }]}*/}
                          {/*  >*/}
                          {/*    <Input*/}
                          {/*      name="ProjectName"*/}
                          {/*      placeholder="Project Name"*/}
                          {/*      value={this.state.projectDetails.ProjectName}*/}
                          {/*      disabled*/}
                          {/*    />*/}
                          {/*  </Form.Item>*/}
                          {/*</Col>*/}
                          <Col span={12}>
                            <Form.Item
                              label="Project Code"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your project code!",
                                },
                              ]}
                            >
                              <Input
                                name="ProjectCode"
                                placeholder="Project Code"
                                disabled
                                value={this.state.projectDetails.ProjectCode}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label="Start Date"
                              rules={[{ required: true }]}
                            >
                              <Input
                                name="StartDate"
                                placeholder="Start Date"
                                value={
                                  moment
                                    .utc(this.state.projectDetails?.StartDate)
                                    .format("DD MMM YYYY") === "Invalid date"
                                    ? "Start Date"
                                    : moment
                                        .utc(
                                          this.state.projectDetails?.StartDate
                                        )
                                        .format("DD MMM YYYY")
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="End Date"
                              rules={[{ required: true }]}
                            >
                              <Input
                                name="EndDate"
                                placeholder="End Date"
                                disabled
                                value={
                                  moment
                                    .utc(this.state.projectDetails?.EndDate)
                                    .format("DD MMM YYYY") === "Invalid date"
                                    ? "End Date"
                                    : moment
                                        .utc(this.state.projectDetails?.EndDate)
                                        .format("DD MMM YYYY")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Project Description"
                              rules={[{ required: true }]}
                            >
                              <Input.TextArea
                                className="custom-text-area-input"
                                name="Description"
                                autoSize
                                placeholder="Description here"
                                value={this.state.projectDetails.Description}
                                onChange={this.changeHandler}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Project Team "
                              rules={[{ required: true }]}
                            >
                              <TextArea
                                placeholder="Team members are"
                                className="custom-text-area-input"
                                name="TeamMembers"
                                autoSize
                                disabled
                                value={Object.values(
                                  this.state.teamMembersName
                                ).join(" , ")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div className="text-right mt-3">
                              <Button
                                className="custom-btn mr-2"
                                onClick={this.auditSummaryDashboard}
                              >
                                {" "}
                                Cancel{" "}
                              </Button>
                              <Button
                                className="custom-btn"
                                type="primary"
                                onClick={(e) => this.saveAddedProject(e)}
                                disabled={
                                  this.state.selectedProject &&
                                  this.state.selectedProjectAuditType
                                    ? false
                                    : true
                                }
                              >
                                {" "}
                                Save{" "}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
export default AddNewProject;
