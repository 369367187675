import React, { Fragment, Component } from "react";
import { Redirect} from "react-router-dom";
import { Button } from 'antd';
import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import nodata from "../../Images/pagenotfound.jpg";

export interface State {
    navigate:boolean
}
export class NotFoundComponent extends Component<{}, State>{
    constructor(props:any){
        super(props);
        this.state={
            navigate:false
        }
    }
    onButtonClick=()=>{
        this.setState({navigate:true})
    }
    render(){
    if(this.state.navigate){
        sessionStorage.removeItem('toPath');
        return  <Redirect to={{pathname:'/'}}/>
    }
     return(
        <Fragment>
          <title>Not Found!!</title>
          <div className="d-flex h-100 flex-column">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                {/* <div className="nodata-image">
                <img src={require("../assets/images/nodata.png")} alt="logo" />
                </div> */}
                {/* <h2 className="text-center">404 <p>Path not found</p></h2>
                <p className="text-center">We're sorry, the page you requested could not found. Please go back to the home page.</p>
                <button className="btn btn-primary rounded btn-sm position-relative" onClick={this.onButtonClick}>Back to Home</button> */}

                <div className="no-data-group text-center">
                   <div className="no-data-image text-center">
                   <img src={nodata} alt="nodata" />
                   </div>
                   <h2 className="text-center mt-2"> 404 Path not found </h2>
                   <p className="text-center mb-2">We're sorry, the page you requested could not found. Please go back to the home page.</p>
                   {/* <button className="btn btn-primary rounded btn-sm position-relative" ><span className="icon14"></span>Back to Home</button> */}
                   <Button type="primary" onClick={this.onButtonClick}><span className="mr-2"><ArrowLeftOutlined /></span>Back to Home</Button>
                </div>
            </div>
            </div>
        </Fragment>
     )
}
}