import React from "react";
import { Row, Col, Input, Form, notification, Select, Empty } from "antd";
import "antd/dist/antd.css";
import { Modal } from "antd";
import { api } from "../../apiServices/apiServices";
import moment from "moment";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class EditProject extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: true,
      projectDetails: {
        ProjectId: Number,
        ProjectName: "",
        ProjectCode: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        IsActive: Number,
        TeamMembers: [],
        activeMembers: [],
        projectAuditType: null,
        modalVisible:false
      },
      teamMembersName: [],
      projectAuditTypes: [],
      selectedProjectAuditType: null,
      modalVisible:true
    };
  }

  async componentDidMount() {
    this.getProjectAuditTypes();
    if (this.props.location.state.TaProjectId) {
      var TaProject = await api.getTaProjectById(
        this.props.location.state.TaProjectId
      );
      if(TaProject.length==0){
        // this.props.history.push({
        //   pathname: "/editproject"
        // });
        this.setState({modalVisible:false});
      }else{
        this.setState({modalVisible:true});
      
      let teamMembersList = await TaProject[0]?.teamMembers;
      var auditProject = await api.getAuditProjectbyId(
        this.props.location.state.TaProjectId
      );
      let teamMembersListWithStatus = teamMembersList?.map((e: any) => {
        let team = {
          id: e.employeeDetails.id,
          Name: e.employeeDetails.fullName,
          Isactive: 1,
        };
        return team;
      });
    
      let teamMembersAudit = JSON.parse(auditProject[0].ProjectTeam);

      //finding the wheather the already existed inactive member become active and if so make it active

      teamMembersAudit = teamMembersListWithStatus?.map((project: any) => {
        for (let i = 0; auditProject < teamMembersAudit.length; i++) {
          if (
            teamMembersAudit[i].id == project.id &&
            teamMembersAudit[i].Isactive !== project.Isactive
          ) {
            teamMembersAudit[i].Isactive = 0;
          }
        }
        return project;
      });

      // filtering newmembers
      var newMembers = teamMembersListWithStatus?.filter((project: any) => {
        return !teamMembersAudit.some((auditProject: any) => {
          return project.id === auditProject.id;
        });
      });

      //adding newmember to the list
      let totalMembers = teamMembersAudit?.concat(newMembers);
  
      //finding wheather an active member become inactive and changing status
      totalMembers?.map((auditProject: any) => {
        let inActive = !TaProject.some((project: any) => {
          return auditProject.id === project.Id;
        });
        if (inActive) {
          auditProject.Isactive = 1;
        }
        return auditProject;
      });
      let activeMembers = totalMembers?.filter((e:any, index:any, self:any) => {
        return e.Isactive === 1 && index === self.findIndex((t:any) => t.id === e.id);
      });
      
      let Startdate = moment
        .utc(new Date(TaProject[0]?.StartDate))
        .format("YYYY/MM/DD");
      let Enddate = moment
        .utc(new Date(TaProject[0]?.EndDate))
        .format("YYYY/MM/DD");
      let projectDetails = {
        ProjectId: auditProject[0]?.AuditProjectId,
        TaProjectId: TaProject[0]?.ProjectId,
        ProjectName: TaProject[0]?.ProjectName,
        ProjectCode: TaProject[0]?.ProjectCode,
        Description: TaProject[0]?.Description,
        StartDate: Startdate,
        EndDate: Enddate,
        IsActive: 1,
        TeamMembers: totalMembers,
        activeMembers: activeMembers,
      };
      this.setState({
        projectDetails: projectDetails,
        selectedProjectAuditType: auditProject[0].projectAuditTypeId,
        //  teamMembersName: totalMembersName
      });
    }
  }
  }
  openSubmitNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Project refresh successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to refresh project",
      });
    }
  };


  auditSummaryDashboard = async () => {
    this.props.history.push({
      pathname: "/assignaudits",
    });
  };

  handleOk = async (e: any) => {
    this.setState(
      {
        projectDetails: {
          ...this.state.projectDetails,
          projectAuditType: this.state.selectedProjectAuditType,
        },
      },
      async () => {
        let saveChanges = await api.updateProject(this.state.projectDetails);
        if (saveChanges) {
          this.openSubmitNotification("success");
          this.auditSummaryDashboard();
        } else {
          this.openSubmitNotification("error");
        }
      }
    );
  };

  handleCancel = (e: any) => {
    this.auditSummaryDashboard();
  };

  getProjectAuditTypes = async () => {
    let projectAuditTypes = await api.getAllProjectTypes();
    await this.setState({ projectAuditTypes: projectAuditTypes });
  };

  projectAuditTypeHandleChange = (value: any) => {
    this.setState({ selectedProjectAuditType: value });
  };

  render() {
    return(
      <>{this.state.modalVisible?
        (<Modal
          title="Refresh Project"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={600}
          className="refresh-popup"
          maskClosable={false}
        >
          {/* <div className="model-header">
            <h1 className="form-popup">{this.state.projectDetails.ProjectName}</h1>
          </div> */}
          <div className="model-body">
            <div className="form-body">
              <Form {...layout} name="Refresh project">
                <Row gutter={16} className="mb-0">
                  <Col span={12}>
                    <Form.Item
                      label="Project Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter project name",
                        },
                      ]}
                      className="mb-3"
                    >
                      <Input
                        name="projectname"
                        value={this.state.projectDetails.ProjectName}
                        placeholder="Project Name"
                        disabled
                      />
                      {/* <p>{this.state.projectDetails.ProjectName}</p> */}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Project Type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter project audit type",
                        },
                      ]}
                      className="mb-3"
                    >
                      <Select
                        placeholder="Select project audit type"
                        allowClear
                        onChange={this.projectAuditTypeHandleChange}
                        value={this.state.selectedProjectAuditType}
                      >
                        {this.state.projectAuditTypes.map((type: any) => {
                          return (
                            <Select.Option
                              key={type.project_audit_type_id}
                              value={type.project_audit_type_id}
                            >
                              {type.project_audit_type_name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Start Date"
                      rules={[
                        { required: true, message: "Please enter start date" },
                      ]}
                      className="mb-3"
                    >
                      {/* <p>{this.state.projectDetails.StartDate}</p> */}
                      <Input
                        name="startdate"
                        value={moment
                          .utc(this.state.projectDetails.StartDate)
                          .format("DD MMM YYYY")}
                        placeholder="Start Date"
                        disabled
                      />
                      {/* <DatePicker  /> */}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="End Date"
                      rules={[
                        { required: true, message: "Please enter end date" },
                      ]}
                      className="mb-3"
                    >
                      {/* <p>{this.state.projectDetails.EndDate}</p> */}
                      <Input
                        name="enddate"
                        value={moment
                          .utc(this.state.projectDetails.EndDate)
                          .format("DD MMM YYYY")}
                        placeholder="End Date"
                        disabled
                      />
                      {/* <DatePicker  />  */}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Project Team"
                      rules={[
                        {
                          required: true,
                          message: "Please enter project Team",
                        },
                      ]}
                    >
                      <ul className="list-team">
                        {this.state?.projectDetails?.activeMembers.map(
                          (x: any) => {
                            return <li>{x.Name}</li>;
                          }
                        )}
                      </ul>

                      {/* <Input
                        placeholder="Project Team"
                        name="projectTeam"
                        value={this.state.teamMembersName} /> */}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>)
        :(<Modal
        title="Refresh Project"
        visible={this.state.visible}
        // onOk={this.handleOk}
        okButtonProps={{style:{display:'none'}}}
        onCancel={this.handleCancel}
        width={600}
        className="refresh-popup"
        maskClosable={false}>
        
      <Empty
        description={"This project doesnt exist in kite"}
      />
   </Modal>)
  }
      </>
    );
  }
}
export default EditProject;
