import React from "react";
import {
  Layout,
  Menu,
  Form,
  Button,
  Select,
  Dropdown,
  Row,
  Col,
  Card,
  Modal,
  Table,
  Tooltip,
  Spin,
  notification,
  Input,
} from "antd";
import {
  CaretDownOutlined,
  DownloadOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { api } from "../../apiServices/apiServices";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Constants } from "../../constants/constants";
import Editor from "../Editor/Editor";
import { mailRecipients } from "../../constants/mailRecipients";
import { async } from "q";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
const { Option } = Select;
const { Header, Content } = Layout;
class AuditorDashboard extends React.Component<any, any> {
  formRef = React.createRef<FormInstance>();
  userData: any = "";
  loading: boolean = false;
  doneStatusProject: any = [];
  projectReport: any = [];

  projectcolumns = [
    {
      title: "Code",
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: "Project",
      dataIndex: "Project",
      key: "Project",
    },
    {
      title: "Audit Type",
      dataIndex: "AuditType",
      key: "AuditType",
    },
    {
      title: "Audit Status",
      dataIndex: "AuditStatus",
      key: "AuditStatus",
    },
    {
      title: "Auditor",
      dataIndex: "Auditor",
      key: "Auditor",
    },
    {
      title: "NC",
      dataIndex: "NC",
      key: "NC",
    },
    {
      title: "PC",
      dataIndex: "PC",
      key: "PC",
    },
    {
      title: "BNC",
      dataIndex: "BNC",
      key: "BNC",
    },
    {
      title: "Created Date",
      dataIndex: "AuditAssignDate",
      key: "AuditAssignDate",
    },
  ];
  columns = [
    {
      title: "Category",
      dataIndex: "Category_Name",
      key: "Category_Name",
    },
    {
      title: "Question",
      dataIndex: "Question",
      key: "Question",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
      key: "Remarks",
    },
    {
      title: "Responsibility",
      dataIndex: "Responsibility",
      key: "Responsibility",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
    {
      title: "Action Status",
      dataIndex: "Actionstatus",
      key: "Actionstatus",
    },
    {
      title: "Target Date",
      dataIndex: "Target",
      key: "Target",
    },
  ];

  projectSummaryColumns = [
    {
      title: "Code",
      dataKey: "Code",
    },
    {
      title: "Project",
      dataKey: "Project",
    },
    {
      title: "Audit Type",
      dataKey: "AuditType",
    },
    {
      title: "Audit Status",
      dataKey: "AuditStatus",
    },
    {
      title: "Auditor",
      dataKey: "Auditor",
    },
    {
      title: "NC",
      dataKey: "NC",
    },
    {
      title: "PC",
      dataKey: "PC",
    },
    {
      title: "BNC",
      dataKey: "BNC",
    },
    {
      title: "Created Date",
      dataKey: "AuditAssignDate",
    },
  ];

  reportColumns = [
    {
      title: "Category",
      dataKey: "Category_Name",
    },
    {
      title: "Question",
      dataKey: "Question",
    },
    {
      title: "Status",
      dataKey: "Status",
    },
    {
      title: "Remarks",
      dataKey: "Remarks",
    },
    {
      title: "Responsibility",
      dataKey: "Responsibility",
    },
    {
      title: "Action",
      dataKey: "Action",
    },
    {
      title: "Action Status",
      dataKey: "Actionstatus",
    },
    {
      title: "Target Date",
      dataKey: "Target",
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
      rolename: "",
      allProjects: [],
      allAuditStatuses: [],
      filteredarray: [],
      popoverDisplay: false,
      toggle: true,
      isEnable: true,
      selectedAuditStatus: null,
      selectedProject: null,
      auditstatuses: [],
      emailSubject: "",
      emailBody: "",
      error: { errormessage: "", auditstatusId: null },
      show: false,
      loading: true,
      visible: false,
      shareVisible: false,
      NC: 0,
      PC: 0,
      OK: 0,
      NA: 0,
      BNC: 0,
      reportProject: "",
      reportauditstatus: "",
      reportData: [],
      projectInfo: [],
      projectID: "",
      changedQuestions: [],
      isReAudit: "",
      projectDetails: [],
      fileName: "",
      isDisable: true,
      projectsForReports: [],
      emailTemplate: "hi",
      selectedFile: "",
      hidden: 0,
      auditeeName: "",
      auditorName: "",
      auditeeEmail: "",
      resetSelect: false,
      updatedEmailList: [],
      updatedEmail: [],
      enteredEmail: "",
      shareShow: false,
      editAuditeeEmail: false,
      tempAuditeeEmail: "",
    };
  }

  async componentDidMount() {
    this.getAllProjects();
  }

  getAllProjects = async () => {
    this.setState({ show: true });
    var allProjects: any = [];
    var allAuditStatuses = [];

    allAuditStatuses = await api.getAllAuditStatus();

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token) || {};

      if (
        this.userData?.userRole === Constants.roleId.auditor ||
        this.userData?.userRole === Constants.roleId.admin
      ) {
        this.setState({ isEnable: false });

        allProjects = await api.getAllProjects(this.userData?.userId);
      }
    }
    // if (localStorage.getItem('userDetails')) {
    //     if ((this.userData.userRole) === 2) {
    //         this.setState({ isEnable: false });

    //         allProjects = await api.getAllProjects(this.userData.user.id);
    //     }
    // }
    this.setState({
      show: false,
      name: this.userData ? this.userData.name : "",
      username: this.userData ? this.userData.email : "",
      rolename: this.userData ? this.userData.roleName : "",
      profilePic: this.userData ? this.userData.profilePic : "",
      allProjects: allProjects,
      filteredarray: allProjects,
      allAuditStatuses: allAuditStatuses,
      selectedProject: 0,
      selectedAuditStatus: 0,
      roleId: this.userData ? this.userData.userRole : null,
    });

    this.formRef.current?.setFieldsValue({
      selectedProject: 0,
      selectedAuditStatus: 0,
    });

    this.state.allProjects.map(async (x: any) => {
      if (x.auditstatusId === 3) {
        this.doneStatusProject.push(x);
      }
    });
  };
  menu = () => (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      {this.state.roleId === Constants.roleId.admin ? (
        <Menu.Item
          onClick={() => {
            this.props.history.push("/admindashboard");
          }}
          key="1"
        >
          Admin Dashboard
        </Menu.Item>
      ) : null}
    </Menu>
  );

  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    projectName: any,
    Auditee: any,
    auditTypeName: any,
    auditState: any,
    parent: any
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        projectName: projectName,
        Auditee: Auditee,
        auditTypeName: auditTypeName,
        auditState: auditState,
        parent: "auditordashboard",
      },
    });
  };

  showModal = async () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e: any) => {
    this.setState({
      visible: false,
      selectedValue: null,
      projectDetails: [],
      reportData: [],
      resetSelect: true,
    });
  };

  handleCancel = (e: any) => {
    this.setState({
      selectedValue: null,
      projectDetails: [],
      reportData: [],
      visible: false,
      resetSelect: true,
    });
  };
  setUpdatedEmailList = (updatedList: any) => {
    this.setState({
      updatedEmailList: updatedList,
    });
  };

  shareReportHandler = async () => {
    this.showShareModal();
    this.setState({ shareShow: true });
    setTimeout(() => {
      this.setState({ shareShow: false });
    }, 4000);
    const newList: { id: any }[] = [];
    var projectInfo = await api.getTaProjectById(
      this.state.projectDetails[0].Id
    );
    var questions = await api.getReauditQuestions(this.state.projectID);
    var result = await api.getAuditeeId(this.state.projectID);
    const auditeeID = result[0].Auditee;
    if (projectInfo === null) {
      notification.error({
        message: "Error",
        description: "Something went wrong",
      });
      this.setState({
        shareVisible: false,
      });
    } else if (projectInfo.length === 0) {
      notification.error({
        message: "Error",
        description: "Project does not exist in Kite",
      });
      this.setState({
        shareVisible: false,
      });
    } else {
      const emailList = projectInfo[0]?.teamMembers?.map(
        (item: any, index: number) => ({
          email: item.employeeDetails.emailAddress,
        })
      );
      mailRecipients.forEach((recipient) => {
        if (
          !emailList.some(
            (existingRecipient: { email: string }) =>
              existingRecipient.email === recipient.email
          )
        ) {
          emailList.push(recipient);
        }
      });

      emailList.map((x: any) => {
        if (
          newList.length === 0 ||
          !newList.some((obj: any) => String(obj.email) === String(x.email))
        ) {
          newList.push(x);
        }
      });

      var updatedEmail = newList.filter(
        (email: any) => email.email !== this.state.auditeeEmail
      );

      this.setUpdatedEmailList(newList);
      this.setState({
        updatedEmail: updatedEmail,
      });
      const memberDetails = projectInfo[0].teamMembers.find(
        (member: { employeeDetails: { id: any } }) =>
          member.employeeDetails.id == auditeeID
      );
      if (memberDetails == undefined) {
        notification.error({
          message: "Error",
          description: "Auditee not present in current project team",
        });
        this.setState({
          shareVisible: false,
        });
      } else {
        let filteredQuestions = questions.map((x: any) => ({
          question: x.question,
          status: "OK",
          remarks: x.remarks,
        }));
        this.setState({
          auditeeName: memberDetails?.employeeDetails?.fullName,
          auditeeEmail: memberDetails?.employeeDetails?.emailAddress,
          tempAuditeeEmail: memberDetails?.employeeDetails?.emailAddress,
          changedQuestions: filteredQuestions,
          isReAudit: this.state.projectDetails[0].AuditType.includes("Re-audit")
            ? 1
            : 0,
          projectInfo: projectInfo,
        });
      }
    }
  };

  showShareModal = async () => {
    this.setState({
      shareVisible: true,
    });
  };

  handleShareOk = async (e: any) => {
    this.setState(
      {
        shareVisible: false,
      },
      () => {
        if (this.state.tempAuditeeEmail !== this.state.auditeeEmail) {
          this.setState(
            {
              auditeeName: this.state.auditeeEmail.split(".")[0],
            },
            () => {
              this.continueHandleShareOk();
            }
          );
        } else {
          this.continueHandleShareOk();
        }
      }
    );
  };

  continueHandleShareOk = async () => {
    const formData = new FormData();
    if (this.state.selectedFile) {
      formData.append("file", this.state.selectedFile);
    }
    const auditTemplateDetailsAllStatus = this.state.reportData.map(
      (report: any) => ({
        question: report.Question,
        status: report.Status,
        remarks: report.Remarks,
      })
    );

    const auditTemplateDetails = auditTemplateDetailsAllStatus.filter(
      (obj: { status: string }) =>
        obj.status == "NC" || obj.status == "PC" || obj.status == "BNC"
    );
    const form = this.formRef.current;
    form?.setFieldsValue({
      subject: `QA ${this.state.projectDetails[0]?.AuditType} - ${this.state.projectDetails[0]?.Project} ${this.state.projectDetails[0]?.Code}`,
    });
    const emailNotes = form?.getFieldValue("notes");
    formData.append("projectName", this.state.projectDetails[0].Project);
    formData.append("auditType", this.state.projectDetails[0].AuditType);
    formData.append("NC", this.state.projectDetails[0]?.NC);
    formData.append("PC", this.state.projectDetails[0]?.PC);
    formData.append("BNC", this.state.projectDetails[0]?.BNC);
    formData.append("auditee", this.state.auditeeName);
    formData.append("auditor", this.state.projectDetails[0].Auditor);
    formData.append(
      "auditTemplateDetails",
      JSON.stringify(auditTemplateDetails)
    );
    formData.append("emailSubject", form?.getFieldValue("subject"));
    formData.append(
      "emailRecipients",
      JSON.stringify(this.state.updatedEmailList)
    );
    formData.append("toEmail", this.state.auditeeEmail);
    formData.append("emailNotes", emailNotes);
    if (this.state.isReAudit == 1) {
      formData.append(
        "changedQuestions",
        JSON.stringify(this.state.changedQuestions)
      );
    }
    var emailResult = await api.sendEmail(formData);
    if (form) {
      form.resetFields(["notes"]);
    }
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
    if (emailResult.message === "Mail sent succesfully") {
      notification.success({
        message: "Success",
        description: "Mail sent succesfully",
      });
    }
  };

  handleShareCancel = (e: any) => {
    this.setState({
      shareVisible: false,
    });
  };

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    this.setState({
      hidden: 1,
      selectedFile: file,
    });
  };

  navigateToLogin(e: any) {
    // localStorage.clear();
    // this.props.history.push('/');
    api.logOut();
  }

  handleChange = async (value: any) => {
    let filterArrayProjects: any = [];
    let auditstatusProjects: any = [];
    this.state.allProjects.map((x: any) => {
      if (this.state.selectedProject) {
        if (
          value === 0 &&
          Number(x.project_Id) === Number(this.state.selectedProject)
        ) {
          filterArrayProjects.push(x);
        } else if (
          x.auditstatusId === value &&
          Number(x.project_Id) === Number(this.state.selectedProject)
        ) {
          filterArrayProjects.push(x);
        }
      } else {
        if (value === 0) {
          filterArrayProjects.push(x);
        } else if (x.auditstatusId === value) {
          filterArrayProjects.push(x);
        }
      }
    });
    this.setState({
      filteredarray: filterArrayProjects,
      selectedAuditStatus: value,
    });
  };

  reportProjecthandleChange = async (value: any, id: any) => {
    this.setState({
      show: true,
      selectedValue: value,
      isDisable: true,
      resetSelect: false,
      projectDetails: [],
      reportData: [],
    });
    this.setState({
      projectID: id.id,
    });
    var report: any = [];
    var headerdata: any = [];
    await api
      .getReport(value)
      .then((data: any) => {
        this.setState({ show: false });
        data.map((x: any) => {
          let responsibility = "";
          let answerValue = JSON.parse(x.Answer);
          let projectTeams = JSON.parse(x.ProjectTeam);
          projectTeams?.map((team: any) => {
            if (Number(answerValue.responsibility) === Number(team.id)) {
              responsibility = team.Name;
            }
          });
          let targetDate = answerValue.target
            ? moment(answerValue.target).format("DD MMM YYYY")
            : "";
          report.push({
            Category_Name: x.Category_Name,
            Question: x.Question,
            Status: answerValue.status,
            Responsibility: responsibility
              ? responsibility
              : answerValue.responsibility,
            Actionstatus: answerValue.actionstatus,
            Action: answerValue.action,
            Target: targetDate,
            Remarks: answerValue.remarks,
          });
        });
      })
      .then(() => {
        this.state.filteredarray.map((project: any, index: number) => {
          if (project.Audit_Id == value && project.auditstatusId == 3) {
            this.setState({
              fileName: `${project.Name} - ${
                project.Audit_Type_Name && project.AuditTypeId < 4
                  ? project.Audit_Type_Name
                  : ""
              }${project.AuditState ? " " + project.AuditState : ""}`,
            });
            headerdata.push({
              Id: project.TaProjectId,
              Code: project.Code,
              Project: project.Name,
              AuditType:
                project.Audit_Type_Name +
                (project.AuditState ? " - " + project.AuditState : ""),
              AuditStatus: project.AuditStatus,
              Auditor: project.name,
              NC: project.NC,
              PC: project.PC,
              BNC: project.BNC,
              AuditAssignDate: moment(project.AuditAssignDate).format(
                "DD MMM YYYY"
              ),
            });
          }
        });
      })
      .then(() => {
        this.setState({
          reportData: report,
          projectDetails: headerdata,
          isDisable: false,
        });
      });
  };

  reportAuditStatushandleChange = async (value: any) => {
    this.setState({ reportauditstatus: value });
  };

  projecthandleChange = async (value: any) => {
    let auditProjects: any = [];
    let projectsForReports: any = [];

    this.state.allProjects.map((x: any) => {
      if (value === "0") {
        if (x.auditstatusId === 3) {
          projectsForReports.push(x);
        }
      } else if (x.auditstatusId === 3 && x.project_Id === value) {
        projectsForReports.push(x);
      }
    });

    this.state.allProjects.map((x: any) => {
      if (value == 0) {
        auditProjects.push(x);
      } else if (x.project_Id == value) {
        auditProjects.push(x);
      }
    });

    this.setState({
      selectedAuditStatus: 0,
      filteredarray: auditProjects,
      selectedProject: value,
    });
    this.formRef.current?.setFieldsValue({
      selectedProject: value,
    });

    this.doneStatusProject = projectsForReports;
  };
  projectListing() {
    return (
      <div className="site-card-wrapper">
        <Row gutter={[16, 24]}>
          {this.state.filteredarray.map((project: any, index: number) => {
            if (project.AssignmentDisplay == 1) {
              let auditee = "";
              let projectTeams = JSON.parse(project.ProjectTeam);
              projectTeams?.map((team: any, index: number) => {
                if (Number(project.Auditee) === Number(team.id)) {
                  auditee = team.Name;
                }
              });
              this.loading = false;
              return (
                <Col
                  key={project.Audit_Id}
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                >
                  <Card
                    key={project.Audit_Id}
                    size="small"
                    hoverable
                    className="ant-card card-user h-100 p-0 custom-card"
                    title={
                      <div className="d-flex">
                        <div className="flex-fill text-ellipse">
                          <Tooltip
                            title={`${project.Name} - ${
                              project.Audit_Type_Name && project.AuditTypeId < 4
                                ? project.Audit_Type_Name
                                : ""
                            }${
                              project.AuditState ? " " + project.AuditState : ""
                            }`}
                          >
                            <span>
                              <b>
                                {project.Name} -{" "}
                                {project.Audit_Type_Name &&
                                project.AuditTypeId < 4
                                  ? project.Audit_Type_Name
                                  : ""}
                                {project.AuditState
                                  ? "  " + project.AuditState
                                  : ""}
                              </b>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    }
                    bordered={false}
                    loading={this.loading}
                    onClick={(e) => {
                      this.navigate(
                        "/auditform",
                        project.auditstatusId,
                        project.project_Id,
                        project.AuditTypeId,
                        project.ProjectTeam,
                        project.NC,
                        project.PC,
                        project.BNC,
                        project.NA,
                        project.OK,
                        project.Reaudit,
                        project.Audit_Id,
                        project.Name,
                        project.Auditee,
                        project.Audit_Type_Name,
                        project.AuditState,
                        "auditordashboard"
                      );
                    }}
                  >
                    <div className="d-flex px-3 py-2">
                      {project.AuditDate ? (
                        <div className="flex-fill mr-2">
                          <p className="m-0">
                            <b className="d-block">Audit Date: </b>{" "}
                            {project.AuditDate
                              ? moment(project.AuditDate).format("DD MMM YYYY")
                              : ""}{" "}
                          </p>
                        </div>
                      ) : (
                        <div className="flex-fill mr-2">
                          <p className="m-0">
                            <b className="d-block">Audit Date: </b>{" "}
                            {project.AuditAssignDate
                              ? moment(project.AuditAssignDate).format(
                                  "DD MMM YYYY"
                                )
                              : ""}{" "}
                          </p>
                        </div>
                      )}
                      <div className="mr-auto">
                        <p className="m-0">
                          <b className="d-block">Auditee:</b>{" "}
                          {auditee ? auditee : project.Auditee}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="card-footer px-3 py-2">
                      <div className="d-flex">
                        <div className="flex-fill">
                          <div className="d-inline-block align-middle mr-3">
                            {project.auditstatusId == 3 ? (
                              <div className="">
                                <p className="m-0">
                                  <b>NC:</b>{" "}
                                  <span className="status-count">
                                    {project.NC}
                                  </span>{" "}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="d-inline-block align-middle mr-3">
                            {project.auditstatusId == 3 ? (
                              <div className="">
                                <p className="m-0">
                                  <b>PC:</b>{" "}
                                  <span className="status-count pc">
                                    {project.PC}
                                  </span>{" "}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="d-inline-block align-middle mr-3">
                            {project.auditstatusId == 3 ? (
                              <div className="">
                                <p className="m-0">
                                  <b>BNC:</b>{" "}
                                  <span className="status-count bnc">
                                    {project.BNC}
                                  </span>{" "}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="flex-auto">
                          <span
                            className="current-status"
                            style={{
                              float: "right",
                              background:
                                project.auditstatusId == 2
                                  ? "#ffa64d"
                                  : project.auditstatusId == 3
                                  ? "#73d13d"
                                  : "#ff4d4f",
                            }}
                          >
                            <em>{project.AuditStatus}</em>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
      </div>
    );
  }

  dashboard = () => {
    this.props.history.push({
      pathname: "/auditordashboard",
    });
  };

  styles = StyleSheet.create({
    page: { backgroundColor: "tomato" },
    section: { color: "white", textAlign: "center", margin: 30 },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  download = async () => {
    this.setState({ show: true });
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const Component: any = document.getElementById("report");
    const doc = new jsPDF(orientation, unit, size);
    doc.html(Component);
    autoTable(doc, {
      startY: 50,
      headStyles: {
        fontSize: 8,
        fillColor: "#2b7d86",
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      bodyStyles: {
        fontSize: 8,
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      columnStyles: { AuditeeAssignDate: { minCellWidth: 60 } },
      alternateRowStyles: {
        fillColor: "#FFFFFF",
      },
      body: this.state.projectDetails,
      columns: this.projectSummaryColumns,
    });

    autoTable(doc, {
      startY: 125,
      headStyles: {
        fontSize: 8,
        fillColor: "#2b7d86",
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      bodyStyles: {
        fillColor: "#dcf7fa",
        fontSize: 8,
        halign: "left",
        valign: "middle",
        cellPadding: 7,
      },
      columnStyles: {
        Remarks: { minCellWidth: 70 },
        Target: { minCellWidth: 60 },
      },
      alternateRowStyles: {
        fillColor: "#FFFFFF",
      },
      rowPageBreak: "avoid",
      body: this.state.reportData,
      columns: this.reportColumns,
    });
    doc.save(`${this.state.fileName}.pdf`);
    this.setState({ show: false });
  };

  refreshPage = () => {
    this.setState(
      {
        selectedValue: null,
        selectedProject: 0,
        searchText: "",
        projectDetails: [],
        reportData: [],
        resetSelect: true,
      },
      () => {
        this.getAllProjects();
      }
    );
  };

  handleEmailClick = () => {
    this.setState((prevState: { isInputVisible: any }) => ({
      isInputVisible: !prevState.isInputVisible,
    }));
  };

  handleEmailChange = (e: { target: { value: any } }) => {
    this.setState({ enteredEmail: e.target.value });
  };

  handleEnterKeyPress = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { enteredEmail } = this.state;
      if (enteredEmail.trim() !== "") {
        this.setState((prevState: { updatedEmailList: any }) => ({
          updatedEmailList: [
            ...prevState.updatedEmailList,
            { email: enteredEmail },
          ],
          enteredEmail: "",
        }));
      } else {
        notification.error({
          message: "Error",
          description: "Email cannot be empty",
        });
      }
    }
  };

  handleEditAuditeeEmail = () => {
    this.setState({ editAuditeeEmail: true });
  };

  handleAuditeeEmailChange = (e: { target: { value: any } }) => {
    this.setState({ auditeeEmail: e.target.value });
  };

  handleAuditeeEmailBlur = () => {
    this.setState({ editAuditeeEmail: false });
  };

  removeItem = (index: number) => {
    const updatedList = [...this.state.updatedEmailList];
    updatedList.splice(index, 1);
    this.setState({ updatedEmailList: updatedList });
  };
  render() {
    this.loading = true;
    let filteredProjects = this.state.allProjects.reduce(
      (accumulator: any[], current: { project_Id: any }) =>
        accumulator.some((x) => x.project_Id === current.project_Id)
          ? accumulator
          : [...accumulator, current],
      []
    );

    return (
      <Layout className="h-100">
        <Header className="header">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <div className="logo is-text">
                <a onClick={(e) => this.dashboard()}>Auto Audit</a>
              </div>
            </div>
            <div>
              <Modal
                title="Report"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width="1000px"
              >
                <Row gutter={16}>
                  <Col flex="auto">
                    <Form.Item name="project1">
                      <Select
                        // key={this.state.selectedValue}
                        key={this.state.resetSelect.toString()}
                        value={
                          this.state.resetSelect
                            ? undefined
                            : this.state.selectedValue
                        }
                        placeholder="Project - Audit Type"
                        allowClear
                        onChange={this.reportProjecthandleChange}
                        disabled={this.state.show}
                      >
                        {[...this.doneStatusProject]
                          .sort((a, b) => {
                            const dateA: any = new Date(a.AuditDate);
                            const dateB: any = new Date(b.AuditDate);
                            if (dateA === null) return -1;
                            if (dateB === null) return 1;
                            return dateB - dateA;
                          })
                          .map((project: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <Option
                                  value={project.Audit_Id}
                                  id={project.project_Id}
                                >
                                  {project.Name}{" "}
                                  {project.Audit_Type_Name &&
                                  project.AuditTypeId < 4
                                    ? " - " + project.Audit_Type_Name
                                    : ""}{" "}
                                  {project.AuditState
                                    ? "- " + project.AuditState
                                    : ""}{" "}
                                </Option>
                              </React.Fragment>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display: this.state.selectedValue ? "block" : "none",
                    }}
                  >
                    <Button
                      className="custom-btn mr-10"
                      target="_blank"
                      type="primary"
                      shape="round"
                      icon={<DownloadOutlined />}
                      onClick={(e) => this.download()}
                      disabled={this.state.isDisable}
                    >
                      Download Report{" "}
                    </Button>
                  </Col>
                  <Col
                    style={{
                      display: this.state.selectedValue ? "block" : "none",
                    }}
                  >
                    <Button
                      className="custom-btn "
                      target="_blank"
                      type="primary"
                      shape="round"
                      // icon={<DownloadOutlined />}
                      onClick={this.shareReportHandler}
                      disabled={this.state.isDisable}
                    >
                      Share Report
                    </Button>
                  </Col>
                </Row>
                <Modal
                  title="Share Report"
                  visible={this.state.shareVisible}
                  onOk={this.handleShareOk}
                  onCancel={this.handleShareCancel}
                  className="refresh-popup"
                  width={600}
                  okButtonProps={{
                    style: {
                      cursor:
                        this.state.hidden == 0 ? "not-allowed" : "default",
                    },
                    disabled: this.state.hidden == 0 ? true : false,
                  }}
                >
                  <Spin spinning={this.state.shareShow}>
                    <Form layout="vertical" ref={this.formRef}>
                      <Form.Item label="To:" className="custom-form-item">
                        <ul className="list-team">
                          <li>
                            {this.state.editAuditeeEmail ? (
                              <Input
                                value={this.state.auditeeEmail}
                                onChange={this.handleAuditeeEmailChange}
                                onBlur={this.handleAuditeeEmailBlur}
                                onPressEnter={this.handleAuditeeEmailBlur}
                                autoFocus
                              />
                            ) : (
                              <>
                                {this.state.auditeeEmail}
                                <li
                                  onClick={this.handleEditAuditeeEmail}
                                  style={{ marginLeft: "10px" }}
                                >
                                  <EditOutlined />
                                </li>
                              </>
                            )}
                          </li>
                        </ul>
                      </Form.Item>
                      <Form.Item label="Cc:" className="custom-form-item">
                        <ul className="list-team">
                          {this.state.updatedEmailList.map(
                            (x: any, index: number) => (
                              <li key={index}>
                                {x.email}
                                <span
                                  className="btn-close"
                                  onClick={() => this.removeItem(index)}
                                >
                                  x
                                </span>
                              </li>
                            )
                          )}
                          {this.state.isInputVisible && (
                            <li>
                              <input
                                type="email"
                                value={this.state.enteredEmail}
                                onChange={this.handleEmailChange}
                                onKeyDown={this.handleEnterKeyPress}
                              />
                            </li>
                          )}
                          {this.state.isInputVisible ? (
                            <li
                              className="add-email"
                              onClick={this.handleEmailClick}
                            >
                              Close
                            </li>
                          ) : (
                            <li
                              className="add-email"
                              onClick={this.handleEmailClick}
                            >
                              + Add
                            </li>
                          )}
                        </ul>
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        className="custom-form-item"
                      >
                        <Text>
                          {`QA ${this.state.projectDetails[0]?.AuditType} - ${this.state.projectDetails[0]?.Project} ${this.state.projectDetails[0]?.Code}`}
                        </Text>
                      </Form.Item>
                      <Form.Item label="Notes" name="notes" initialValue={""}>
                        <Editor
                          value={""}
                          onChange={undefined}
                          placeholder={""}
                        />
                      </Form.Item>
                      <Form.Item label="Attachments" required={true}>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={this.handleFileChange}
                        />
                      </Form.Item>
                    </Form>
                  </Spin>
                </Modal>
                <Spin spinning={this.state.show}>
                  <div
                    id="report"
                    style={{
                      display: this.state.selectedValue ? "block" : "none",
                    }}
                  >
                    <Document>
                      <Page wrap size="A4" style={this.styles.body}>
                        <View wrap={false}>
                          <Text style={this.styles.text}>
                            <div className="group-item">
                              <Table
                                dataSource={this.state.projectDetails}
                                columns={this.projectcolumns}
                                size="middle"
                                pagination={false}
                              />
                            </div>
                            <div className="group-item">
                              <Table
                                dataSource={this.state.reportData}
                                columns={this.columns}
                                size="middle"
                                pagination={false}
                              />
                            </div>
                          </Text>
                          <Text
                            style={this.styles.pageNumber}
                            render={({ pageNumber, totalPages }) =>
                              `${pageNumber} / ${totalPages}`
                            }
                            fixed
                          />
                        </View>
                      </Page>
                    </Document>
                  </div>
                </Spin>
              </Modal>
            </div>
            <div className="flex-auto">
              <div className="section-log-out">
                <div className="group-profile d-flex">
                  <div className="d-flex">
                    <Dropdown overlay={this.menu} trigger={["click"]}>
                      <a
                        className="ant-dropdown-link menu-a d-flex align-items-center"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="flex-auto mr-2">
                          <div className="avathar">
                            {/* <UserOutlined /> */}
                            <img src={this.state.profilePic} alt="user-pic" />
                          </div>
                        </div>
                        <div className="flex-fill mr-2">
                          <p className="login-text m-0">
                            {" "}
                            {this.state.name} <span>{this.state.rolename}</span>
                          </p>
                        </div>
                        <div className="flex-auto">
                          <CaretDownOutlined />
                        </div>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <Spin spinning={this.state.show}>
          <Layout>
            <Layout>
              <div className="page-title">
                <div className="container-fluid">
                  <h1>Audits</h1>
                </div>
              </div>

              <Content
                className="site-layout-background custom-position"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <div className="container-fluid h-100">
                  <div
                    className="h-100"
                    style={{ overflowX: "hidden", overflowY: "auto" }}
                  >
                    <div className="main-content">
                      <Form
                        {...Layout}
                        name="control-hooks"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        labelAlign="left"
                        colon={false}
                      >
                        <Row gutter={16}>
                          <Col span={4}>
                            <Form.Item>
                              <Select
                                placeholder="Project"
                                optionFilterProp="children"
                                allowClear
                                onChange={this.projecthandleChange}
                                value={this.state.selectedProject}
                              >
                                <Option value={0}>All Projects</Option>
                                {filteredProjects.map(
                                  (
                                    project: {
                                      project_Id: number;
                                      Name: string;
                                    },
                                    index: number
                                  ) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <Option value={project.project_Id}>
                                          {project.Name}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item>
                              <Select
                                placeholder="Audit Status"
                                allowClear
                                optionFilterProp="children"
                                onChange={this.handleChange}
                                value={this.state.selectedAuditStatus}
                              >
                                <Option value={0}>All Audit Statuses</Option>
                                {this.state.allAuditStatuses.map(
                                  (
                                    auditstatus: { Id: number; Name: string },
                                    index: number
                                  ) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <Option value={auditstatus.Id}>
                                          {auditstatus.Name}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={16}>
                            <div className="d-inline-block align-middle">
                              <Form.Item name="report">
                                <Button
                                  className="custom-btn"
                                  type="primary"
                                  onClick={this.showModal}
                                >
                                  Report
                                </Button>
                              </Form.Item>
                            </div>
                            <div className="d-inline-block align-middle mb-3 ml-3">
                              <Tooltip title="Refresh List">
                                <Button
                                  className="custom-icon-btn"
                                  type="primary"
                                  icon={<ReloadOutlined />}
                                  onClick={this.refreshPage}
                                ></Button>
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      {this.projectListing()}
                    </div>
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </Spin>
      </Layout>
    );
  }
}
export default AuditorDashboard;
