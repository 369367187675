import React from "react";
import {
  Layout,
  Menu,
  Input,
  Select,
  Tooltip,
  Dropdown,
  Button,
  Space,
  Col,
  Row,
  Spin,
} from "antd";
import { api } from "../../../apiServices/apiServices";
import moment from "moment";
import configData from "../../../config/fieldsConfig.json";
import { ColumnProps } from "antd/es/table";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Table, { ColumnType } from "antd/lib/table";
import form, { FormInstance } from "antd/lib/form";
import {
  CaretDownOutlined,
  EditFilled,
  FileAddFilled,
  PlusOutlined,
  ReloadOutlined,
  WarningFilled,
} from "@ant-design/icons";

const { Option } = Select;
const { Search } = Input;
const { Header, Content, Sider } = Layout;

const menu = (
  <Menu>
    <Menu.Item key="0">Logout</Menu.Item>
  </Menu>
);

const columns: ColumnProps<any>[] = [
  {
    title: "Project",
    dataIndex: "name",
    key: "Project",
    sorter: (a: { name: string }, b: { name: string }) =>
      a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: (a: { type: string }, b: { type: string }) => {
      if (a.type == "billable" && b.type == "non_billable") {
        return -1;
      } else if (a.type == "non_billable" && b.type == "billable") {
        return 1;
      } else {
        return 0;
      }
    },
    render: (text: string) => {
      return text.replace('_', ' ');
    },
    defaultSortOrder: "ascend",
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Contract Type",
    dataIndex: "contractType",
    key: "contractType",
    render: (text: string) => {
      return text.replace('_', ' ');
    }
  },
  {
    title: "Project Manager",
    dataIndex: "projectManagerName",
    key: "projectManagerName",
  },
];

class ArchivedProjects extends React.Component<any, any> {
  navigateToLogin(e: any) {
    api.logOut();
  }

  menu = (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          this.props.history.push("/auditordashboard");
        }}
      >
        Auditor Dashboard
      </Menu.Item>
    </Menu>
  );
  userData: any = "";
  formRef = React.createRef<FormInstance>();
  constructor(props: any) {
    super(props);
    this.state = {
      allProjects: [],
      allProjectsCopy: [],
      projectDetails: [],
      allAuditTypes: [],
      archivedProjects: [],
      username: "",
      rolename: "",
      profilePic: "",
      hrAuditCount: 0,
      systemAuditCount: 0,
      selectedStatus: 0,
      searchText: "",
      menuSelectedValue: "",
      sortedInfo: null,
      openModal: false,
      typeNameNew: "",
      editingKey: null,
      loading: false,
      isValid: false,
    };
  }

  async componentDidMount() {
    this.getAllAuditLists();
    this.getArchivedProjects();
    this.setState({ menuSelectedValue: configData[1].key });
  }
  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    allauditType: any,
    projectname: any,
    auditor: any,
    auditee: any,
    auditassignId: any,
    aa_date: any,
    auditstate: any,
    auditStatus: any
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        AllAuditType: allauditType,
        Project_Name: projectname,
        Auditor: auditor,
        Auditee: auditee,
        AuditAssignID: auditassignId,
        Audit_Assign_Date: aa_date,
        Audit_State: auditstate,
        AuditStatus: auditStatus,
      },
    });
  };

 
  searchHandler = async (e: any) => {
    const searchText = e.target.value.trim();
    this.setState({ searchText });
    if (searchText == '') {
      this.getArchivedProjects();
    }
  };

  getAllAuditLists = async () => {
    this.setState({ show: true });
    let auditDate = 0;
    let dashboarddata: any = [];
    let allAuditStatus: never[] = [];

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token);
    }
    allAuditStatus = await api.getAllAuditType();
    await api
      .getAllProject()
      .then((allProject) => {
        this.setState({
          show: false,
          allProjects: allProject,
          allProjectsCopy: allProject,
          projectDetails: allProject,
          hrAuditCount: 0,
          systemAuditCount: 0,
        });
      })
      .then(() => {
        this.state.allProjects.map((project: any, index: number) => {
          if (project.Displayed === 0) {
            auditDate = project.AuditDate;
          }
          if (project.Displayed === 1) {
            let auditee =
              project.AuditTypeId === 4 || project.AuditTypeId === 5
                ? project.Auditee
                : "";
            let projectTeam = JSON.parse(project.ProjectTeam);
            projectTeam?.map((item: any) => {
              if (Number(project.Auditee) === Number(item.id)) {
                auditee = item.Name;
              }
            });

            if (project.AuditTypeId === 4) {
              if (project.LocationId === 1)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
              else if (project.LocationId === 2)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
            } else if (project.AuditTypeId === 5) {
              if (project.LocationId === 1)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
              else if (project.LocationId === 2)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
            }

            dashboarddata.push({
              Project: project.Name,
              AuditType: project.AuditTypeId
                ? project.Audit_Type_Name +
                  (project.Audit_State ? " " + project.Audit_State : "")
                : "Not Planned",
              AuditDate: auditDate
                ? moment(auditDate).format("DD MMM YYYY")
                : project.AuditAssignDate == null
                ? "Not Planned"
                : moment(project.AuditAssignDate).format("DD MMM YYYY"),
              Auditee: auditee ? auditee : "Not Assigned",
              color: (
                <div>
                  <Tooltip
                    title={
                      project.auditstatusId == 1
                        ? "New"
                        : project.auditstatusId == 2
                        ? "In Progress"
                        : "Done"
                    }
                  >
                    <span
                      className="color-status"
                      style={
                        project.auditstatusId == 2
                          ? { backgroundColor: "#ffa64d" }
                          : project.auditstatusId == 3
                          ? { backgroundColor: "#73d13d" }
                          : { backgroundColor: "#ff4d4f" }
                      }
                    />
                  </Tooltip>
                </div>
              ),
            });
          }
        });
      })
      .then(() => {
        this.setState({
          projectDetails: dashboarddata,
          allAuditTypes: allAuditStatus,
          name: this.userData ? this.userData.name : "",
          username: this.userData ? this.userData.email : "",
          rolename: this.userData ? this.userData.roleName : "",
          profilePic: this.userData ? this.userData.profilePic : "",
        });
      });
    this.setState({ loading: false });
  };

  async searchProject(value: any) {
    let auditDate = 0;
    var auditProjects: any = [];
    this.setState({
      searchText: value,
      hrAuditCount: 0,
      systemAuditCount: 0,
    });
    this.state.archivedProjects.map((project: any) => {
      if (project.name.toUpperCase().includes(value.toUpperCase())) {
        auditProjects.push({
          id: project.id,
          name: project.name,
          contractType: project.contractType,
          projectManagerName: project.projectManagerName,
          type: project.type,
        });
      }
    });
    this.setState({
      projectDetails: auditProjects,
      archivedProjects: auditProjects,
    });
  }

  getArchivedProjects = async () => {
    const result = await api.getTaArchivedProjects();
    this.setState({
      archivedProjects: result,
    });
  };

  audittypehandleChange = async (value: any) => {
    let auditDate = 0;
    var auditProjects: any = [];
    this.setState({
      selectedStatus: value,
      hrAuditCount: 0,
      systemAuditCount: 0,
    });
    this.state.allProjects.map((project: any) => {
      if (project.Displayed === 0) {
        auditDate = project.AuditDate;
      }
      if (project.Displayed === 1) {
        let auditee = "";
        let projectTeam = JSON.parse(project.ProjectTeam);
        projectTeam?.map((item: any) => {
          if (Number(project.Auditee) === Number(item.id)) {
            auditee = item.Name;
          }
        });

        if (project.AuditTypeId === 4) {
          if (project.LocationId === 1)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
          else if (project.LocationId === 2)
            this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
        } else if (project.AuditTypeId === 5) {
          if (project.LocationId === 1)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
          else if (project.LocationId === 2)
            this.setState({
              systemAuditCount: this.state.systemAuditCount + 1,
            });
        }

        if (value == 0) {
          auditProjects.push({
            Project: project.Name,
            AuditType: project.AuditTypeId
              ? project.Audit_Type_Name +
                (project.Audit_State ? " " + project.Audit_State : "")
              : "Not Planned",
            AuditDate: auditDate
              ? moment(auditDate).format("DD MMM YYYY")
              : project.AuditAssignDate == null
              ? "Not Planned"
              : moment(project.AuditAssignDate).format("DD MMM YYYY"),
            Auditee: auditee ? auditee : "Not Assigned",
            color: (
              <div>
                <Tooltip
                  title={
                    project.auditstatusId == 1
                      ? "New"
                      : project.auditstatusId == 2
                      ? "In Progress"
                      : "Done"
                  }
                >
                  <span
                    className="color-status"
                    style={
                      project.auditstatusId == 2
                        ? { backgroundColor: "#ffa64d" }
                        : project.auditstatusId == 3
                        ? { backgroundColor: "#73d13d" }
                        : { backgroundColor: "#ff4d4f" }
                    }
                  />
                </Tooltip>
              </div>
            ),
            action: (
              <div className="icon-group">
                <React.Fragment>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    {project.AuditTypeId === 4 || project.AuditTypeId === 5 ? (
                      ""
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    <div
                      onClick={(e) =>
                        this.navigate(
                          "/assignauditor",
                          project.auditstatusId,
                          project.project_Id,
                          project.AuditTypeId,
                          project.ProjectTeam,
                          project.NC,
                          project.PC,
                          project.BNC,
                          project.NA,
                          project.OK,
                          project.Reaudit,
                          project.Audit_Id,
                          this.state.allAuditTypes,
                          project.Name,
                          project.Auditor,
                          project.Auditee,
                          project.Audit_Assign_ID,
                          project.AuditAssignDate,
                          project.Audit_State,
                          project.AuditStatus
                        )
                      }
                    >
                      <Tooltip title="Assign Auditor">
                        <FileAddFilled />
                      </Tooltip>
                    </div>
                  </div>
                  <div hidden>
                    {project.Reaudit === 1 && project.AuditStatus === "Done" ? (
                      <WarningFilled />
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              </div>
            ),
          });
        } else if (project.AuditTypeId === value) {
          auditProjects.push({
            Project: project.Name,
            AuditType: project.AuditTypeId
              ? project.Audit_Type_Name +
                (project.Audit_State ? " " + project.Audit_State : "")
              : "Not Planned",
            AuditDate: auditDate
              ? moment(auditDate).format("DD MMM YYYY")
              : project.AuditAssignDate == null
              ? "Not Planned"
              : moment(project.AuditAssignDate).format("DD MMM YYYY"),
            Auditee: auditee ? auditee : "Not Assigned",
            color: (
              <div>
                <Tooltip
                  title={
                    project.auditstatusId == 1
                      ? "New"
                      : project.auditstatusId == 2
                      ? "In Progress"
                      : "Done"
                  }
                >
                  <span
                    className="color-status"
                    style={
                      project.auditstatusId == 2
                        ? { backgroundColor: "#ffa64d" }
                        : project.auditstatusId == 3
                        ? { backgroundColor: "#73d13d" }
                        : { backgroundColor: "#ff4d4f" }
                    }
                  />
                </Tooltip>
              </div>
            ),
            action: (
              <div className="icon-group">
                <React.Fragment>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    {project.AuditTypeId === 4 || project.AuditTypeId === 5 ? (
                      ""
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="d-inline-block mr-3 align-middle pointer icon-card">
                    <div
                      onClick={(e) =>
                        this.navigate(
                          "/assignauditor",
                          project.auditstatusId,
                          project.project_Id,
                          project.AuditTypeId,
                          project.ProjectTeam,
                          project.NC,
                          project.PC,
                          project.BNC,
                          project.NA,
                          project.OK,
                          project.Reaudit,
                          project.Audit_Id,
                          this.state.allAuditTypes,
                          project.Name,
                          project.Auditor,
                          project.Auditee,
                          project.Audit_Assign_ID,
                          project.AuditAssignDate,
                          project.Audit_State,
                          project.AuditStatus
                        )
                      }
                    >
                      <Tooltip title="Assign Auditor">
                        <FileAddFilled />
                      </Tooltip>
                    </div>
                  </div>
                  <div hidden>
                    {project.Reaudit === 1 && project.AuditStatus === "Done" ? (
                      <WarningFilled />
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              </div>
            ),
          });
        }
      }
    });
    this.setState({
      projectDetails: auditProjects,
    });
  };

  refreshPage = () => {
    this.setState(
      {
        sortedInfo: null,
        selectedStatus: 0,
        searchText: "",
      },
      () => {
        this.getAllAuditLists();
        this.getArchivedProjects();
      }
    );
  };

  dashBoard = async () => {
    this.props.history.push({
      pathname: "/admindashboard",
    });
  };

  changeHandler = (event: any) => {
    let projectDetails = this.state.projectDetails;
    projectDetails[event.target.name] = event.target.value;
    this.setState({ projectDetails: projectDetails });
  };

  render() {
    return (
      <Layout>
        <Header className="header">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <div className="logo is-text">
                <a onClick={(e) => this.dashBoard()}>Auto Audit</a>
              </div>
            </div>
            <div className="flex-auto">
              <div className="section-log-out">
                <div className="group-profile d-flex">
                  <div className="d-flex">
                    <Dropdown overlay={this.menu} trigger={["click"]}>
                      <a
                        className="ant-dropdown-link menu-a d-flex align-items-center"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="flex-auto mr-2">
                          <div className="avathar">
                            <img src={this.state.profilePic} alt="user-pic" />
                          </div>
                        </div>
                        <div className="flex-fill mr-2">
                          <p className="login-text m-0">
                            {" "}
                            {this.state.name} <span>{this.state.rolename}</span>
                          </p>
                        </div>
                        <div className="flex-auto">
                          <CaretDownOutlined />
                        </div>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div className="page-title">
          <div>
            <h1>Archived Projects</h1>
          </div>
        </div>
        <Content
          className="site-layout-background custom-position"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="h-100">
            <div className="main-content h-100">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto mb-3">
                  <Row gutter={16}>
                    <Col>
                      <Search
                        className="custom-field"
                        placeholder="Search Project"
                        value={this.state.searchText}
                        onChange={this.searchHandler}
                        onSearch={(value) => this.searchProject(value)}
                        style={{ width: 250 }}
                        allowClear
                      />
                    </Col>
                    <Col>
                      <Tooltip title="Refresh List">
                        <Button
                          className="custom-icon-btn"
                          type="primary"
                          icon={<ReloadOutlined />}
                          onClick={this.refreshPage}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <Spin
            spinning={this.state.show}
            style={{
              alignContent: "center",
              alignSelf: "center",
              marginTop: "15%",
            }}
          >
            <Table columns={columns} dataSource={this.state.archivedProjects} />
          </Spin>
        </Content>
      </Layout>
    );  
  }
}
export default ArchivedProjects;
const archivedProjects = new ArchivedProjects({});
