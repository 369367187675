import React from "react";
import "antd/dist/antd.css";
import {
  Tag,
  Layout,
  Menu,
  Form,
  Input,
  Button,
  Select,
  Dropdown,
  Row,
  Col,
  notification,
  Space,
  DatePicker,
  Checkbox,
  Modal,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../apiServices/apiServices";
import { FormInstance } from "antd/lib/form";
import { isUndefined, isNullOrUndefined } from "is-what";
import moment from "moment";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Constants } from "../../constants/constants";
import { constants } from "buffer";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { confirm } = Modal;
const { Option } = Select;
const dateFormat = "DD MMM YYYY";
const Context = React.createContext({ name: "Default" });

class AuditForm extends React.Component<any, any> {
  formRef = React.createRef<FormInstance>();
  userData: any = "";
  valuechanged = false;
  fielddisable = false;
  value = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      category: [],
      question: [],
      answers: [],
      auditassignment: [],
      projectTeam: [],
      selectedCategory: 0,
      selectedCategoryName: "",
      selectedQuestion: "",
      selectedindex: 0,
      name: "",
      rolename: "",
      profilePic: "",
      status: "",
      responsibility: null,
      actionstatus: "",
      projectName: "",
      action: "",
      target: "",
      remarks: "",
      selectedProjectId: "",
      selectedauditstatusId: "",
      selectedaudittypeId: "",
      selectedQuestionId: "",
      selectedQuestionIndex: -1,
      selectedAuditId: "",
      totalqn: 0,
      totalansweredqn: 0,
      NC: 0,
      PC: 0,
      OK: 0,
      NA: 0,
      BNC: 0,
      NC_count: 0,
      PC_count: 0,
      BNC_count: 0,
      ReAudit: 0,
      reauditdisplay: false,
      selectedReaudit: 0,
      reauditvalue: false,
      currentStatus: "",
      Auditee: "",
      reauditTagDisplay: false,
      selectedMenuItem: 0,
      selectedSubMenu: 0,
    };
  }

  menu(name: any) {
    return (
      <Menu>
        <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
          Logout
        </Menu.Item>
        {name === "Admin" ? (
          <Menu.Item
            onClick={() => {
              this.props.history.push("/admindashboard");
            }}
            key="1"
          >
            Admin Dashboard
          </Menu.Item>
        ) : null}
      </Menu>
    );
  }

  async componentDidMount() {
    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token);
    }
    if (this.props.location.state.auditstatusId == 3) {
      this.fielddisable = true;
    }
    await this.setState({
      projectTeam: JSON.parse(this.props.location.state.ProjectTeam),
      selectedauditstatusId: this.props.location.state.auditstatusId,
      selectedProjectId: this.props.location.state.projectId,
      auditTypeName: this.props.location.state.auditTypeName,
      auditState: this.props.location.state.auditState,
      selectedaudittypeId: this.props.location.state.AuditTypeId,
      NC: this.props.location.state.NC,
      PC: this.props.location.state.PC,
      OK: this.props.location.state.OK,
      BNC: this.props.location.state.BNC,
      NA: this.props.location.state.NA,
      NC_count: 0,
      PC_count: 0,
      BNC_count: 0,
      reauditvalue: this.props.location.state.ReAudit,
      selectedAuditId: this.props.location.state.AuditId,
      projectName: this.props.location.state.projectName,
      Auditee: Number(this.props.location.state.Auditee),
      responsibility: Number(this.props.location.state.Auditee),
    });

    var a = this.props.location.state.AuditTypeId;
    var x = this.props.location.state.auditstatusId;
    var y = this.props.location.state.projectId;

    var response = await api.getCategoryByAuditType(a, y);

    var question_response = await api.getQuestionByCategory(a, y);

    var audit_assignment = await api.getAuditAssignments(y);

    if (!isNullOrUndefined(audit_assignment.A_Audit_Assign_ID)) {
      var qanswers = await api.getAllQuestionAnswers(
        this.state.selectedAuditId
      );
      await this.setState({
        ProjectTeam: JSON.parse(this.props.location.state.ProjectTeam),
        totalansweredqn: qanswers.length,
        totalqn: question_response.length,
        answers: qanswers,
        selectedQuestionId: question_response[0]?.Id,
        selectedQuestionIndex: 0,
        selectedQuestion: question_response[0]?.Question,
        name: this.userData ? this.userData.name : "",
        rolename: this.userData ? this.userData.roleName : "",
        profilePic: this.userData ? this.userData.profilePic : "",
        category: response,
        question: question_response,
        auditassignment: audit_assignment,
      });
    }

    await this.menuOnSelect(question_response[0]?.Id, 0);
    qanswers?.map((question: any, index: number) => {
      let value = JSON.parse(question.Answer);

      if (
        value.status === "NC" ||
        value.status === "PC" ||
        value.status === "BNC"
      ) {
        this.setState({ reauditTagDisplay: true });
      }
    });
  }

  layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  async onSave(e: any) {
    let NC = 0;
    let PC = 0;
    let BNC = 0;
    let OK = 0;
    let NA = 0;
    let userData = {
      status: this.state.status,
      responsibility: this.state.responsibility
        ? Number(this.state.responsibility)
        : Number(this.state.Auditee),
      actionstatus: this.state.actionstatus,
      action: this.state.action,
      target: this.state.target,
      remarks: this.state.remarks,
    };
    this.state.status === "NC"
      ? (NC = 1)
      : this.state.status === "PC"
      ? (PC = 1)
      : this.state.status === "BNC"
      ? (BNC = 1)
      : this.state.status === "OK"
      ? (OK = 1)
      : this.state.status === "NA"
      ? (NA = 1)
      : console.log("done");
    let data = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditassignment.A_Audit_Assign_ID,
      questionId: this.state.selectedQuestionId,
      answer: userData,
      nc: NC,
      pc: PC,
      bnc: BNC,
      ok: OK,
      na: NA,
      reaudit: this.state.selectedReaudit,
      projectId: this.state.selectedProjectId,
      auditId: this.state.selectedAuditId,
      auditstatus: 2,
      value: 0,
    };
    await api.updateAnswers(data).then(async (updateanswer) => {
      if (!isNullOrUndefined(this.state.auditassignment.A_Audit_Assign_ID)) {
        await api
          .getAllQuestionAnswers(this.state.selectedAuditId)
          .then((qanswers) => {
            this.setState({ reauditTagDisplay: false });
            qanswers.map((question: any, index: number) => {
              let value = JSON.parse(question.Answer);
              if (
                value.status === "NC" ||
                value.status === "PC" ||
                value.status === "BNC"
              ) {
                this.setState({ reauditTagDisplay: true });
              }
            });
            this.setState({
              totalansweredqn: qanswers.length,
              totalqn: this.state.question.length,
              answers: qanswers,
            });
          });
      }
      if (updateanswer) {
        this.openNotification("success");
        this.valuechanged = false;
        let questionIndex = this.state.selectedQuestionIndex + 1;
        let question = this.state.question[questionIndex];
        if (question) {
          let questionCategoryIndex = this.state.category.findIndex(
            (category: any) => category.Id === question.Category_ID
          );
          this.setState({
            selectedMenuItem: questionIndex,
            selectedSubMenu: questionCategoryIndex,
          });
          this.menuChangeHandler(
            question.Question,
            question.Id,
            questionIndex,
            e
          );
        }
      } else {
        this.openNotification("error");
      }
    });
  }

  async onSubmit(e: any) {
    
    e.preventDefault();
    let data = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditassignment.A_Audit_Assign_ID,
      questionId: this.state.selectedQuestionId,
      projectId: this.state.selectedProjectId,
      auditId: this.state.selectedAuditId,
      auditstatus: this.state.selectedauditstatusId,
      projectName: this.state.projectName,
      status: 3,
      reAudit: this.state.selectedReaudit,
      value: 0,
      displayed: 0,
      submitDate: moment().format("YYYY/MM/DD"),
    };
    var projectSubmit = await api.submitProject(data);
    if (projectSubmit) {
      this.openSubmitNotification("success");
      this.props.history.replace("/auditordashboard");
    } else {
      this.openSubmitNotification("error");
    }
  }

  async onSkipAudit(e: any) {
    e.preventDefault();
    let data = {
      audittypeid: this.state.selectedaudittypeId,
      auditassignId: this.state.auditassignment.A_Audit_Assign_ID,
      questionId: this.state.selectedQuestionId,
      projectId: this.state.selectedProjectId,
      auditId: this.state.selectedAuditId,
      auditstatus: 1,
      projectName: this.state.projectName,
      status: 3,
      reAudit: 0,
      value: 0,
      displayed: 0,
      submitDate: moment().format("YYYY/MM/DD"),
    };
    var projectSubmit = await api.skipAudit(data);
    if (projectSubmit) {
      this.openSkipNotification("success");
      this.props.history.replace("/auditordashboard");
    } else {
      this.openSkipNotification("error");
    }
  }

  disabledDate(current: any) {
    return moment().add(-1, "days") >= current;
  }

  statushandleChange = async (value: any) => {
    this.setState({ status: value });
    this.valuechanged = true;
    if (value === "OK" || value === "NA") {
      this.formRef.current?.setFieldsValue({
        actionstatus: "Closed",
      });
      this.setState({
        actionstatus: "Closed",
        reauditvalue: false,
        selectedReaudit: 0,
      });
    } else if (value === "NC" || value === "PC" || value === "BNC") {
      this.formRef.current?.setFieldsValue({
        actionstatus: "Open",
      });
      this.setState({
        actionstatus: "Open",
        reauditvalue: true,
        selectedReaudit: 1,
      });
    } else {
      this.formRef.current?.setFieldsValue({
        actionstatus: null,
      });
      this.setState({
        actionstatus: "",
        reauditvalue: false,
        selectedReaudit: 0,
      });
    }
  };

  actionhandleChange = async (e: any) => {
    this.setState({ action: e.target.value });
    this.valuechanged = true;
  };

  responsibilityhandleChange = async (value: any) => {
    this.setState({ responsibility: Number(value) });
    this.valuechanged = true;
  };

  targethandleChange = async (date: any) => {
    this.setState({ target: date === null ? "" : date });
    this.valuechanged = true;
  };

  actionstatushandleChange = async (value: any) => {
    this.setState({ actionstatus: value });
    this.valuechanged = true;
    if (value === "Open") {
      this.setState({ reauditvalue: true });
    } else {
      this.setState({ reauditvalue: false });
    }
  };

  remarkshandleChange = async (e: any) => {
    this.setState({ remarks: e.target.value });
    this.valuechanged = true;
  };

  questionContent(questionname: any) {
    let data = this.state.ProjectTeam;
    return (
      <Layout>
        <div className="page-title">
          <Row align="middle" className="container">
            <Col flex="auto">
              <h1>
                {this.state?.projectName} -{" "}
                {this.state?.auditTypeName ? this.state.auditTypeName : ""}
                {this.state?.auditState ? "  " + this.state.auditState : ""}
              </h1>
            </Col>

            <Col>
              {this.state?.auditState &&
                this.state.auditState.includes("Re-audit") &&
                this.state.selectedauditstatusId !== Constants.AUDIT_DONE && (
                  <Button onClick={(e) => this.skipConfirmPopup(e)}>
                    Skip Audit
                  </Button>
                )}
            </Col>
          </Row>
        </div>

        <Content
          className="site-layout-background custom-position"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="container">
            <div className="card p-4 form-group-area mb-4">
              <p className="question-p">{questionname}</p>

              <Form
                {...Layout}
                ref={this.formRef}
                name="control-hooks"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                labelAlign="left"
                colon={false}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="status"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          message: "Please select the status",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Status"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        onChange={this.statushandleChange}
                        disabled={this.fielddisable}
                      >
                        <Option value="OK">OK</Option>
                        <Option value="NC">NC</Option>
                        <Option value="PC">PC</Option>
                        <Option value="BNC">BNC</Option>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="action"
                      label="Action"
                      rules={[{ max: 200, message: "Maximum limit reached" }]}
                    >
                      <Input
                        placeholder="Action"
                        onChange={this.actionhandleChange}
                        maxLength={201}
                        disabled={this.fielddisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Responsibility">
                      {this.props.location.state.AuditTypeId === 4 ? (
                        <Select disabled value={"HR Admin"}>
                          <Option selected value={"HR Admin"}>
                            HR Admin
                          </Option>
                        </Select>
                      ) : this.props.location.state.AuditTypeId === 5 ? (
                        <Select disabled value={"System Admin"}>
                          <Option selected value={"System Admin"}>
                            System Admin
                          </Option>
                        </Select>
                      ) : (
                        <Select
                          placeholder="Responsibility"
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            this.state.responsibility
                              ? Number(this.state.responsibility)
                              : Number(this.state.Auditee)
                          }
                          onChange={this.responsibilityhandleChange}
                          disabled={this.fielddisable}
                        >
                          {this.state.projectTeam == undefined ? (
                            <Option value={Number(this.state.Auditee)}>
                              {this.state.Auditee}
                            </Option>
                          ) : (
                            this.state.projectTeam.map(
                              (values: any, index: number) => {
                                return (
                                  <Option value={Number(values.id)}>
                                    {values.Name}
                                  </Option>
                                );
                              }
                            )
                          )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item name="target" label="Target Date">
                      <Space className="w-100" direction="vertical" size={12}>
                        <DatePicker
                          className="w-100"
                          placeholder="Target date"
                          allowClear={true}
                          value={
                            this.state.target !== ""
                              ? moment(this.state.target)
                              : null
                          }
                          size="large"
                          format={dateFormat}
                          onChange={this.targethandleChange}
                          disabledDate={this.disabledDate}
                          disabled={this.fielddisable}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="actionstatus"
                      label="Action Status"
                      rules={[
                        {
                          required: true,
                          message: "Please select the action status",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Action Status"
                        allowClear
                        onChange={this.actionstatushandleChange}
                        disabled={this.fielddisable}
                        value={this.state.actionstatus}
                      >
                        <Option value="Open">Open</Option>
                        <Option value="Closed">Closed</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="remarks"
                      label="Remarks"
                      rules={[{ max: 500, message: "Maximum limit reached" }]}
                    >
                      <Input
                        placeholder="Remarks"
                        value={this.state.remarks}
                        maxLength={501}
                        onChange={this.remarkshandleChange}
                        disabled={this.fielddisable}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  className="text-center mt-4 mb-3"
                  hidden={
                    this.props.location.state.auditstatusId === 3 ? true : false
                  }
                >
                  <div className="d-inline-block mx-2">
                    <Button
                      className="custom-btn mr-2"
                      onClick={this.dashboard}
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                    <Button
                      className="custom-btn"
                      type="primary"
                      htmlType="submit"
                      onClick={(e) => this.onSave(e)}
                      disabled={
                        this.fielddisable
                          ? true
                          : this.state.responsibility != null &&
                            this.state.status.length > 0 &&
                            this.state.actionstatus.length > 0 &&
                            this.valuechanged
                          ? false
                          : true
                      }
                    >
                      Save
                    </Button>
                  </div>
                  <div
                    className="d-inline-block mx-2"
                    hidden={
                      this.props.location.state.auditstatusId === 3
                        ? true
                        : false
                    }
                  >
                    {this.state.totalqn == this.state.totalansweredqn ? (
                      <Button
                        className="custom-btn"
                        type="primary"
                        htmlType="submit"
                        onClick={(e) => this.submitConfirmPopup(e)}
                        disabled={this.fielddisable}
                      >
                        Submit
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Form>
              {this.state.reauditTagDisplay === true &&
              (this.state.selectedaudittypeId < 4|| this.state.selectedaudittypeId>5 )&&
              this.props.location.state.ReAudit === 0 ? (
                <div className="alert alert-danger py-2 px-4 d-inline-block mt-2 text-center m-auto">
                  Project Needs Reaudit
                </div>
              ) : this.state.reauditTagDisplay === true &&
                this.state.selectedaudittypeId === 4 &&
                this.props.location.state.ReAudit === 0 ? (
                <div className="alert alert-danger py-2 px-4 d-inline-block mt-2 text-center m-auto">
                  HR audit Needs Reaudit
                </div>
              ) : this.state.reauditTagDisplay === true &&
                this.state.selectedaudittypeId === 5 &&
                this.props.location.state.ReAudit === 0 ? (
                <div className="alert alert-danger py-2 px-4 d-inline-block mt-2 text-center m-auto">
                  Admin Audit Needs Reaudit
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }

  async navigateToLogin(e: any) {
    api.logOut();
  }

  dashboard = () => {
    const parent = this.props.location.state.parent;
    if (parent === "auditordashboard") {
      this.props.history.push("/auditordashboard");
    } else if (parent === "auditreports") {
      this.props.history.push("/auditreports");
    }
  };

  openNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Audit details saved successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to save audit details",
      });
    }
  };
  openSubmitNotification = (type: any) => {
    const key = "updatable";
    if (type == "success") {
      notification.success({
        key,
        message: "Success",
        description: "Audit details submitted successfully.",
      });
    } else if (type == "error") {
      notification.error({
        key,
        message: "Error",
        description: "Failed to submit audit details",
      });
    }
  };
  openSkipNotification = (type: any) => {
    const key = Constants.UPDATEABLE;
    if (type == Constants.SUCCESS) {
      notification.success({
        key,
        message: Constants.SUCCESS_MESSAGE,
        description: Constants.AUDIT_SKIP,
      });
    } else if (type == Constants.ERROR) {
      notification.error({
        key,
        message: Constants.ERROR_MESSAGE,
        description: Constants.AUDIT_SKIP_FAIL,
      });
    }
  };
  async menuChangeHandler(question: any, questionid: any, index: any, e: any) {
    let questionIndex = this.state.question.findIndex(
      (questions: any) => questions.Id === questionid
    );
    this.setState({ selectedMenuItem: questionIndex });
    this.valuechanged = false;
    this.setState({
      selectedQuestion: question,
      selectedQuestionId: questionid,
      selectedQuestionIndex: questionIndex,
      reauditdisplay: false,
      target: "",
      status: "",
      actionstatus: "",
      responsibility: "",
      remarks: "",
      action: "",
    });
    this.onReset();
    this.menuOnSelect(questionid, index);
  }

  async menuOnSelect(questionId: any, num?: any) {
    var value: any;
    this.state.answers.map((question: any, index: number) => {
      if (question.Question_ID == questionId) {
        value = JSON.parse(question.Answer);
        if (question.Reaudit == 1) {
          this.setState({
            reauditvalue: true,
            reauditdisplay: true,
          });
        }
        this.setState({
          status: value.status,
          action: value.action,
          target: value.target,
          responsibility: Number(value.responsibility),
          actionstatus: value.actionstatus,
          remarks: value.remarks,
          reaudit: this.state.reauditvalue,
          currentStatus: value.status,
        });
        this.formRef.current?.setFieldsValue({
          status: value.status,
          action: value.action,
          target: value.target,
          responsibility: Number(value.responsibility),
          actionstatus: value.actionstatus,
          remarks: value.remarks,
          reaudit: this.state.reauditvalue,
        });
      }
    });
  }

  onReset = () => {
    this.formRef.current?.resetFields();
    this.setState({ targett: null });
  };
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  logoutSavePopup(e: any) {
    confirm({
      title: "You have unsaved changes, Do you want to save them?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Your changes will be lost if you decide to continue without saving",
      okText: "Save",
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      cancelText: "Cancel",
      onOk: async () => {
        await this.onSave(e)
          .then(async (e) => {
            await this.delay(500);
          })
          .then((e) => {
            this.navigateToLogin(e);
          });
      },
      onCancel: async () => {
        await this.delay(700).then(() => this.navigateToLogin(e));
      },
    });
  }

  async skipConfirmPopup(e: any) {
    confirm({
      title: "Do you want to continue?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ whiteSpace: "pre-line" }}>Your audit will be skipped</div>
      ),
      okText: "Continue",
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      cancelText: "Cancel",
      centered: true,
      onOk: async () => {
        await this.onSkipAudit(e).then(async (e) => {
          await this.delay(500);
        });
      },
      onCancel: () => {
        this.setState({ NC_count: 0, PC_count: 0, BNC_count: 0 });
      },
    });
  }

  DashboardSavePopup(e: any) {
    confirm({
      title: "You have unsaved changes, Do you want to save them?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Your changes will be lost if you decide to continue without saving",
      okText: "Save",
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      centered: true,
      cancelText: "Cancel",
      onOk: async () => {
        await this.onSave(e)
          .then(async (e) => {
            await this.delay(500);
          })
          .then((e) => {
            this.dashboard();
          });
      },
      onCancel: async () => {
        await this.delay(400).then(() => this.dashboard());
      },
    });
  }

  async submitConfirmPopup(e: any) {
    await api
      .getAllQuestionAnswers(this.state.selectedAuditId)
      .then((qanswers) => {
        qanswers.map((question: any, index: number) => {
          let value = JSON.parse(question.Answer);
          switch (value.status) {
            case "NC":
              this.setState({
                NC_count: this.state.NC_count + 1,
              });
              break;
            case "PC":
              this.setState({
                PC_count: this.state.PC_count + 1,
              });
              break;
            case "BNC":
              this.setState({
                BNC_count: this.state.BNC_count + 1,
              });

              break;
            default:
              break;
          }
        });
      });
    confirm({
      title: "Do you want to continue?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          Your audit will be submitted and saved permanently if you decide to
          continue.
          <div style={{ display: "flex", paddingTop: "5px" }}>
            <b>NC</b> : &nbsp;
            <p style={{ color: "#ec7468", marginRight: 5, padding: 0 }}>
              {this.state.NC_count}
            </p>
            &nbsp; &nbsp;
            <b>PC</b>: &nbsp;
            <p style={{ color: "#4893bf", marginRight: 5, padding: 0 }}>
              {this.state.PC_count}
            </p>
            &nbsp; &nbsp;
            <b>BNC</b>: &nbsp;
            <p style={{ color: "#4dc59f", margin: 0, padding: 0 }}>
              {this.state.BNC_count}
            </p>
          </div>
        </div>
      ),
      okText: "Continue",
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      cancelText: "Cancel",
      centered: true,
      onOk: async () => {
        await this.onSubmit(e).then(async (e) => {
          await this.delay(500);
        });
      },
      onCancel: () => {
        this.setState({ NC_count: 0, PC_count: 0, BNC_count: 0 });
      },
    });
  }

  onSubmenuClick = (index: any) => {
    this.setState({ selectedSubMenu: index });
  };

  render() {
    return (
      <Layout className="h-100">
        <Header className="header">
          <div className="d-flex align-items-center">
            <div className="flex-fill">
              <div className="logo is-text">
                <a
                  onClick={(e) =>
                    this.valuechanged
                      ? this.DashboardSavePopup(e)
                      : this.dashboard()
                  }
                >
                  Auto Audit
                </a>
              </div>
            </div>
            <div className="flex-auto">
              <div className="section-log-out">
                <div className="group-profile d-flex">
                  <div className="d-flex">
                    <Dropdown
                      overlay={this.menu(this.state.rolename)}
                      trigger={["click"]}
                    >
                      <a
                        className="ant-dropdown-link menu-a d-flex align-items-center"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="flex-auto mr-2">
                          <div className="avathar">
                            {/* <UserOutlined /> */}
                            <img src={this.state.profilePic} alt="user-pic" />
                          </div>
                        </div>
                        <div className="flex-fill mr-2">
                          <p className="login-text m-0">
                            {" "}
                            {this.state.name} <span>{this.state.rolename}</span>
                          </p>
                        </div>
                        <div className="flex-auto">
                          <CaretDownOutlined />
                        </div>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider className="site-layout-background menu-group" width={480}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["0"]}
              defaultOpenKeys={["0"]}
              selectedKeys={[`${this.state.selectedSubMenu}`]}
              openKeys={[`${this.state.selectedSubMenu}`]}
              style={{ height: "100%", borderRight: 0 }}
            >
              {this.state.category.map((questions1: any, index: number) => {
                return (
                  <SubMenu
                    key={index}
                    icon={<QuestionCircleOutlined />}
                    onTitleClick={() => this.onSubmenuClick(index)}
                    title={questions1.Name}
                  >
                    {this.state.question.map(
                      (questions: any, index1: number) => {
                        if (questions.Category_ID == questions1.Id) {
                          let backgroundColor: string;
                          const answer = this.state.answers.find(
                            (a: any) => a.Question_ID === questions.Id
                          );
                          if (answer) {
                            const status = JSON.parse(answer.Answer).status;
                            switch (status) {
                              case "NC":
                                backgroundColor = Constants.NC_RED;
                                break;
                              case "BNC":
                                backgroundColor = Constants.BNC_ORANGE;
                                break;
                              case "PC":
                                backgroundColor = Constants.PC_YELLOW;
                                break;
                              default:
                                backgroundColor = "";
                            }
                          } else {
                            backgroundColor = "";
                          }

                          return (
                            <Menu.Item
                              key={index1}
                              onClick={(e) =>
                                this.menuChangeHandler(
                                  questions.Question,
                                  questions.Id,
                                  index1,
                                  e
                                )
                              }
                              style={{
                                backgroundColor,
                                fontWeight:
                                  index === this.state.selectedSubMenu &&
                                  index1 === this.state.selectedMenuItem
                                    ? 600
                                    : "normal",
                              }}
                              id={`question-${questions.Id}`}
                            >
                              {questions.Question}
                              {questions.Notes != "undefined" &&
                                questions.Notes && (
                                  <span>
                                    <Tooltip title={questions.Notes}>
                                      <InfoCircleOutlined
                                        style={{ marginLeft: 5 }}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                            </Menu.Item>
                          );
                        }
                      }
                    )}
                  </SubMenu>
                );
              })}
            </Menu>
          </Sider>
          {this.questionContent(this.state.selectedQuestion)}
        </Layout>
      </Layout>
    );
  }
}
export default AuditForm;
