import {
  Col,
  Input,
  Layout,
  Row,
  Space,
  Spin,
  Switch,
  Modal,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import Table, { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { api } from "../../apiServices/apiServices";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Constants } from "../../constants/constants";
const { Search } = Input;
const { confirm } = Modal;

class UserManagementComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchQuery: "",
      filteredData: [],
      allQaUsers: [],
      allQaUsersCopy: [],
      allQaUsersDetails: [],
      selectedQuestion: null,
      userRole: Number,
      selectedUser: Number,
    };
  }
  columns: ColumnProps<any>[] = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      sorter: (a: { Name: string }, b: { Name: string }) =>
        a.Name.localeCompare(b.Name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      sorter: (a: { Email: string }, b: { Email: string }) =>
        a.Email.localeCompare(b.Email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "Role",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record) => {
        if (record.Name == "No results found") {
          return null;
        }
        return (
          <Switch
            checked={record.Action == 1}
            checkedChildren="Admin"
            unCheckedChildren="Auditor"
            onChange={(checked) => {
              this.setState({
                userRole: checked ? 1 : 2,
                selectedUser: record.ID,
              });
            }}
            onClick={(e) => this.userUpdateConfirmPopup(e)}
          />
        );
      },
    },
  ];

  componentDidMount() {
    this.getAllUsersQA();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async userUpdateConfirmPopup(e: any) {
    confirm({
      title: "Do you want to continue?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ whiteSpace: "pre-line" }}>User role will be switched</div>
      ),
      okText: "Continue",
      okButtonProps: {
        type: "primary",
      },
      cancelButtonProps: {
        type: "default",
      },
      cancelText: "Cancel",
      centered: true,
      onOk: async () => {
        await this.switchUser(e).then(async (e: any) => {
          await this.delay(500);
        });
        this.getAllUsersQA();
        this.clearSearch();
      },
      onCancel: () => {
        this.getAllUsersQA();
        this.clearSearch();
      },
    });
  }
  clearSearch = () => {
    this.setState({ filteredData: [], searchQuery: "" });
  };
  handleSearch = (value: any) => {
    const filteredData = this.state.allQaUsersCopy.filter((qaUser: any) => {
      const lowerCaseQuestion = qaUser.QaUserName
        ? qaUser.QaUserName.toLowerCase()
        : "";
      const lowerCaseValue = value ? value.toLowerCase() : "";
      return lowerCaseQuestion.includes(lowerCaseValue);
    });

    const tableData = filteredData.map((users: any) => {
      return {
        Name: users.QaUserName,
        Email: users.UserName,
        Role: users.Name,
        Action: users.RoleId,
        ID: users.ID,
      };
    });

    if (tableData.length === 0) {
      const emptyRow = {
        key: "empty-row",
        Name: "No results found",
        Email: "",
        Role: "",
        Action: "",
      };

      this.setState({ filteredData: [emptyRow], searchQuery: value });
    } else {
      this.setState({ filteredData: tableData, searchQuery: value });
    }
  };

  async switchUser(e: any) {
    let userData = {
      ID: this.state.selectedUser,
      newAction: this.state.userRole,
    };
    var userUpdate = await api.switchUser(userData);
    if (userUpdate) {
      this.openUserRoleSwitchNotification("success");
    } else {
      this.openUserRoleSwitchNotification("error");
    }
  }
  openUserRoleSwitchNotification = (type: any) => {
    const key = Constants.UPDATEABLE;
    if (type == Constants.SUCCESS) {
      notification.success({
        key,
        message: Constants.SUCCESS_MESSAGE,
        description: Constants.USER_SWITCH,
      });
    } else if (type == Constants.ERROR) {
      notification.error({
        key,
        message: Constants.ERROR_MESSAGE,
        description: Constants.USER_SWITCH_FAIL,
      });
    }
  };
  getAllUsersQA = async () => {
    this.setState({ show: true });
    let usersdata: any = [];
    try {
      const users = await api.getAllUsersQA();
      this.setState({
        show: false,
        allQaUsers: users,
        allQaUsersCopy: users,
        allQaUsersDetails: users,
      });

      const userData = this.state.filteredData.length
        ? this.state.filteredData
        : this.state.allQaUsersCopy;
      userData.forEach((userData: any) => {
        if (userData.Name != null) {
          usersdata.push({
            Name: userData.QaUserName,
            Email: userData.UserName,
            Role: userData.Name,
            Action: userData.RoleId,
            ID: userData.ID,
          });
        }
      });

      this.setState({
        allQaUsersDetails: usersdata,
      });
    } catch (error) {
      console.log("Error fetching user details:", error);
      this.setState({ show: false });
    }
  };
  render() {
    return (
      <>
        <Layout>
          <div className="page-title">
            <div>
              <h1>Users</h1>
            </div>
          </div>

          <Content
            className="site-layout-background custom-position"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <div className="h-100">
              <div className="main-content h-100">
                <div className="d-flex flex-column h-100">
                  <div className="flex-auto mb-3">
                    <Row align="middle" justify="end" gutter={[8, 8]}>
                      <Col flex="auto">
                        <Space>
                          <Search
                            className="custom-field"
                            placeholder="Search User"
                            style={{ width: 250 }}
                            allowClear
                            value={this.state.searchQuery}
                            onChange={(e) => this.handleSearch(e.target.value)}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Spin
                    spinning={this.state.show}
                    style={{
                      alignContent: "center",
                      alignSelf: "center",
                      marginTop: "15%",
                    }}
                  />
                  <div className="flex-fill overflow-auto">
                    {!this.state.show && (
                      <Table
                        columns={this.columns}
                        dataSource={
                          this.state.filteredData.length
                            ? this.state.filteredData
                            : this.state.allQaUsersDetails
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}
export default UserManagementComponent;
