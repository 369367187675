/* eslint-disable eqeqeq*/
import React from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Modal,
  notification,
  Collapse,
  Empty,
  Tooltip,
  Menu,
  Col,
  Row,
  Table,
  Typography,
  Popconfirm,
  Space,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  ReloadOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PieChartOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import { api } from "../../apiServices/apiServices";
import Form, { FormInstance } from "antd/lib/form";
import { AddQuestions } from "./AddQuestions";
import configData from "../../config/fieldsConfig.json";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import SubMenu from "antd/lib/menu/SubMenu";
import { ColumnType } from "antd/lib/table";

const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;
const { Panel } = Collapse;
const { confirm } = Modal;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Object;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input maxLength={20} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              pattern: new RegExp(/^[^\s][A-Za-z\s]*$/),
              message: "Please add a meaningful name"
            },
            { max: 19, message: 'Maximum 20 characters allowed' }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

class CheckListComponent extends React.Component<any, any> {
  formRef = React.createRef<FormInstance>();
  valuechanged = false;

  constructor(props: any) {
    super(props);
    this.state = {
      keypath: [],
      category: [],
      questions: [],
      categoryCopy: [],
      questionsCopy: [],
      selectedSubMenu: 0,
      openAddQuestionForm: false,
      selectedAuditType: "",
      selectedCategory: "",
      selectedQuestion: [],
      menuActiveKey: [],
      searchText: "",
      selectedSubCategory: null,
      categoryAddOrNext: false,
      dropdownSelectedCategoryId: 0,
      selectedCategoryQuestions: [],
      activeCollapseKey: 0,
      showAddModal: false,
      auditTypeDetails:[],
      projectAuditTypeList:[],
      selectedId: null,
      assignedAuditType: null,
      allAuditTypes:[],
      editingKey: null,
      isValid:false,
    };
  }

  async componentDidMount() {
    this.getAuditTypeDetails();
    
    this.setState({
      keyPath: this.props.keyPath,
      menuActiveKey: 0,
      auditTypesDetails:this.props.auditTypeDetails,
      projectAuditTypeList:this.props.projectAuditTypeList,
      allAuditTypes:this.props.allAuditTypes,
    });
    if (this.props.keyPath[2] === configData[3].key) {
      await this.getCategoriesAndQuestions();
      this.setState({
        activeCollapseKey: this.state.category
          ? this.state.category[0]?.Category_ID
          : 0,
      });
    }
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.keyPath !== prevProps.keyPath) {
      this.setState({ keyPath: this.props.keyPath });
      if (this.props.keyPath[2] === configData[3].key) {
        await this.getCategoriesAndQuestions();
        this.setState({
          activeCollapseKey: this.state.category
            ? this.state.category[0]?.Category_ID
            : 0,
        });
        this.updateFilterValues();
      }
    }
  }

  getCategoryByAuditType = async (auditTypeId: number, categoryId: number) => {
    let categories = await api.getCategoryByType(auditTypeId, categoryId);
    return categories;
  };

  getQuestionByCategory = async (auditTypeId: string, categoryId: number) => {
    let questions = await api.getQuestionByCategoryType(
      auditTypeId,
      categoryId
    );
    return questions;
  };

  getAuditTypeDetails = async () => {
    let projectAuditTypes = await api.getAllProjectTypes()
    let auditTypeDetails = await api.getAllAuditTypeDetails()
    let allAuditTypes= await api.getAllAuditType();
    this.setState({
      auditTypesDetails: auditTypeDetails,
      projectAuditTypeList: projectAuditTypes,
      allAuditTypes:allAuditTypes
    })
  }

  // getCategoriesAndQuestions = async() =>{
  //     let parsedConfigData = JSON.parse(JSON.stringify(configData));
  //     let auditType = await parsedConfigData.find((item: any) => (item.key === this.props.keyPath[0]));
  //     let category = await parsedConfigData.find((item: any) => (item.key === this.props.keyPath[1]));
  //     // let categories: any = []
  //     // let questions: any = []

  //     let categories = await this.getCategoryByAuditType(auditType.typeId, category.typeId);
  //     let questions = await this.getQuestionByCategory(auditType.typeId, category.typeId);
  //     if (this.state.menuActiveKey !== 0 || this.state.category?.CategoryId) {
  //         let activCategory = categories.filter((item: any) => item.Category_ID === this.state.menuActiveKey);
  //         this.setState({
  //             category: activCategory,
  //             questions: questions,
  //             categoryCopy: categories,
  //             questionsCopy: questions,
  //             selectedAuditType: auditType,
  //             selectedCategory:category
  //         })
  //     } else {
  //         this.setState({
  //             category: categories,
  //             questions: questions,
  //             categoryCopy: categories,
  //             questionsCopy: questions,
  //             selectedAuditType: auditType,
  //             selectedCategory: category
  //         })
  //     }
  // }

  getCategoriesAndQuestions = async () => {
    
    let parsedConfigData = JSON.parse(JSON.stringify(configData));
    let auditType = this.props.keyPath[0];
    let category = this.props.keyPath[1];
    
    
    
    let categories = await this.getCategoryByAuditType(
      // auditType.typeId,
      // category.typeId
      this.props.keyPath[0],this.props.keyPath[1]
    );
    let questions = await this.getQuestionByCategory(
      this.props.keyPath[0],this.props.keyPath[1]
    );
    let filteredQuestions = questions.filter(
      (question: any) =>
        question.Category_ID === Number(categories[0]?.Category_ID)
    );
    this.setState({
      category: categories,
      questions: questions,
      selectedCategoryQuestions: filteredQuestions,
      categoryCopy: categories,
      questionsCopy: questions,
      selectedAuditType: auditType,
      selectedCategory: category,
      // activeCollapseKey: categories[0]?.Category_ID
    });
  };

  // assignedAuditType = async(auditTypeId: Number, projectAuditId: Number) => {
  //   var similar= this.state.auditTypesDetails.filter((data: any) => projectAuditId == data.projectTypeId && auditTypeId==data.auditTypeId)
  //       await api.assignAuditType({auditType_Id:auditTypeId,projectAuditTyp_Id:projectAuditId})
  //   .then((assign)=>{
  //     if(assign){
  //       notification.success({
  //         message: "Success",
  //         description: "Audit type assigned successfully.",
  //       });
  //     }else {
  //       notification.error({
  //         message: "Error",
  //         description: "Failed to assign audit type",
  //       });
  //     }
  //     // this.getAuditTypeDetails()
  //     this.setState({showAddModal:false});
  //   })
  // }

  editOrDeleteCategory = (id: number) => {
    return (
      <div>
        <EditOutlined
          onClick={(event: any) => {
            this.editCategory(event, id);
            event.stopPropagation();
          }}
          className={"mr-2"}
        />
        <DeleteFilled
          onClick={(event: any) => {
            this.deleteCategory(event, id);
            event.stopPropagation();
          }}
          className={"mr-2"}
        />
      </div>
    );
  };

  assigningAuditType = (item: any) => {    
    return (
      <div>
        <PlusOutlined
          onClick={() =>{ 
          this.setState({ showAddModal: true, selectedId: item.project_audit_type_id })
          
            
            // this.assignedAuditType(event, id);
            // event.stopPropagation();
          }}
          className={"mr-2"}
        />
      </div>
    );
  };
  searchHandler = (e: any) => {
    this.setState({ searchText: e.target.value });
  };

  searchQuestion = (value: string) => {
    let searchedCategory: any = [];
    let filteredCategories: any = [];
    let filteredQuestions: any = [];
    if (value !== "") {
      filteredQuestions = this.state.questionsCopy.filter((item: any) =>
        item.Question.toLowerCase().includes(value.toLowerCase())
      );
      filteredQuestions.forEach((question: any) => {
        searchedCategory.push(question.Category_ID);
      });
      if (this.state.dropdownSelectedCategoryId) {
        filteredCategories = this.state.categoryCopy.filter(
          (item: any) =>
            Number(item.Category_ID) ===
            Number(this.state.dropdownSelectedCategoryId)
        );
      } else {
        filteredCategories = this.state.categoryCopy.filter((item: any) =>
          searchedCategory.includes(item.Category_ID)
        );
      }
      this.setState({
        category: filteredCategories,
        questions: filteredQuestions,
        activeCollapseKey:
          this.state.activeCollapseKey > 0
            ? this.state.activeCollapseKey
            : filteredCategories[0]?.Category_ID,
      });
    } else {
      if (this.state.dropdownSelectedCategoryId === 0) {
        this.setState(
          {
            category: this.state.categoryCopy,
            questions: this.state.questionsCopy,
            activeCollapseKey: this.state.categoryCopy[0]?.Category_ID,
          },
          () => {}
        );
      } else {
        filteredQuestions = this.state.questionsCopy.filter(
          (item: any) =>
            Number(item.Category_ID) === Number(this.state.menuActiveKey)
        );
        this.setState({
          questions: filteredQuestions,
          activeCollapseKey: this.state.menuActiveKey,
        });
      }
    }
  };

  navigateToAddCategory = () => {
    this.setState({ openAddQuestionForm: true });
  };

  manageAddCategory = () => {
    this.setState({ openAddCategoryForm: true, selectedSubCategory: null });
  };

  editCategory = (e: any, id: number) => {
    let selectedSubCategory = this.state.category.find(
      (item: any) => item.Category_ID === id
    );
    this.setState({
      selectedSubCategory: selectedSubCategory,
      openAddCategoryForm: true,
    });
  };

  deleteCategory = (e: any, id: number) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Do you really want to delete this Category? This process cannot be undone.",
      okText: "Yes",
      okType: "ghost",
      cancelText: "No",
      onOk: () => {
        this.handleCategoryDelete(id);
      },
      onCancel() {},
    });
  };

  handleCategoryDelete = async (id: number) => {
    await api.deleteCategory(id).then(async (response) => {
      if (!(response.status && response.status === 500) && !response.errors) {
        this.showSuccessToaster("Category deleted successfully");
        await this.getCategoriesAndQuestions();
        // this.updateFilterValues();
        this.setFiltersAfterRefresh();
      } else {
        this.props.onError();
      }
    });
  };
  onSubmenuClick = (index: any) => {
    this.setState({ selectedSubMenu: index });
  };

  showSuccessToaster = (msg: string) => {
    const key = "updatable";
    notification.success({
      key,
      // message: msg.concat('\n successfully'),
      message: msg,
    });
  };

  showErrorToaster = (msg: string) => {
    const key = "updatable";
    notification.error({
      key,
      // message: msg.concat('\n Error occured'),
      message: msg,
    });
  };
  
  typeNameChange = async (e: any) => {
    const value = e.target.value;
    this.setState({ isValid: (/^[^\s][A-Za-z\s]*$/.test(value)) });
    this.setState({ typeNameNew: e.target.value });
  };

  showValidatorToaster = (msg: string) => {
    const key = "updatable";
    notification.error({
      key,
      message: msg,
    });
  };

  async onQuestionClick(question: any, questionid: any, index: any, e: any) {
    let questionIndex = this.state.question.findIndex(
      (questions: any) => questions.Id === questionid
    );
    this.setState({ selectedMenuItem: questionIndex });
    this.setState({
      selectedQuestion: question,
      selectedQuestionId: questionid,
    });
  }

  onPopupCancel = () => {
    this.setState({ openAddQuestionForm: false, selectedQuestion: [] });
  };

  onCategoryAddPopupCancel = () => {
    this.setState({
      openAddCategoryForm: false,
      selectedSubCategory: null,
    });
  };

  // listItemRenderer = (item: any, index: number): JSX.Element=> {
  //     return (
  //         <React.Fragment>
  //                 <ul className="m-0 p-0 content-group-draggable">
  //                     <li className={'bg-white'}>
  //                         <div className="d-flex align-item-center">
  //                             <div className="flex-fill col-12 text-truncate">
  //                                 <Tooltip placement="topLeft" title={item.Question}>
  //                                     <span>{item.Question}</span>
  //                                 </Tooltip>
  //                                 </div>
  //                             <div className="d-flex">
  //                                 <div
  //                                     className="d-inline-block align-middle pr-2 border-right mr-2 text-primary pointer">
  //                                     <EditFilled onClick={() => this.handleQuestionEdit(item.id)}/></div>
  //                                 <div className="d-inline-block align-middle text-secondary pointer">
  //                                     <DeleteFilled onClick={() => this.showItemDeleteConfirm(item.id)}/></div>
  //                             </div>
  //                         </div>
  //                     </li>
  //                 </ul>
  //         </React.Fragment>
  //     );
  // }

  // categoryListRenderer = (item: any, index: number): JSX.Element=> {
  //     let filteredQuestions = this.state.questions.filter((question: any)=> (question.Category_ID === item.Category_ID))
  //     return (
  //         <React.Fragment>
  //             <Collapse className="is-accordion"
  //                 onChange={this.collapseOnChange}
  //                 // defaultActiveKey={this.state.category.length === 1 ? [item.Category_ID] : [0]}
  //                 // activeKey={this.state.menuActiveKey > 0 ? [this.state.menuActiveKey] : this.state.category[0].Category_ID}
  //                 defaultActiveKey={this.state.menuActiveKey > 0 ? this.state.menuActiveKey : this.state.category[0]?.Category_ID}
  //             >
  //                 <Panel header={item.Category_Name} key={item.Category_ID} extra={this.editOrDeleteCategory(item.Category_ID)}>
  //                     {filteredQuestions.length === 0 && <Empty/> }
  //                 <RLDD
  //                     items={filteredQuestions}
  //                     itemRenderer={this.listItemRenderer}
  //                     onChange={this.handleQuestionsRLDDChange}
  //                 />
  //                 </Panel>
  //             </Collapse>
  //      </React.Fragment>
  //     );
  // }

  EmptySearch = (props: { noResult: boolean }) => {
    const noResult = props.noResult;
    return <div className="m-auto">{noResult && <Empty />}</div>;
  };

  handleQuestionsRLDDChange = async (newItems: any) => {
    let response = await api.updateQuestionsListByCategory(newItems);
    if (!(response.status && response.status === 500) && !response.errors) {
      this.showSuccessToaster("Question order rearranged successfully");
      await this.getCategoriesAndQuestions();
      this.setFiltersAfterRefresh();
    } else {
      this.showErrorToaster("Something went wrong");
    }
  };

  handleCategoryRLDDChange = async (newItems: any) => {
    let response = await api.updateCategoryListByOrder(newItems);
    if (!(response.status && response.status === 500) && !response.errors) {
      this.showSuccessToaster("Category order rearranged successfully");
      await this.getCategoriesAndQuestions();
      this.setFiltersAfterRefresh();
    } else {
      this.showErrorToaster("Something went wrong");
    }
  };

  handleQuestionEdit = (id: number) => {
    let selectedData = this.state.questions.find(
      (item: { id: any }) => item.id === id
    );
    this.setState(
      {
        selectedQuestion: selectedData,
      },
      () => {
        this.navigateToAddCategory();
      }
    );
  };

  handleCategoryEdit = (id: number) => {
    let selectedData = this.state.categoty.find(
      (item: { id: any }) => item.id === id
    );
    this.setState(
      {
        selectedCategory: selectedData,
      },
      () => {
        this.navigateToAddCategory();
      }
    );
  };

  isEditing = (record: any) => {
    return record.Id == this.state.editingKey;
  };
  

  edit = (record: any) => {
    this.formRef.current?.setFieldsValue({
      Id: "",
      name: "",
      ...record,
    });
    this.setState({ editingKey: record.Id });
    this.getAuditTypeDetails()
    this.props.getAuditTypeDetail();
  };

  delete = async (record: any) => {
    await api.deleteAditTypeName(record)
      .then((deleteChanges: any) => {
        const key = "updatable";
        if (deleteChanges) {
          notification.success({
            key,
            message: "Success",
            description: "Audit type deleted successfully.",
          });
        } else {
          notification.error({
            key,
            message: "Error",
            description: "Failed to delete audit type",
          });
        }
      });
    this.setState({ editingKey: null });
    this.setState({ loading: true });
    this.getAuditTypeDetails()
    this.props.getAuditTypeDetail();
  }
  cancel = () => {
    this.setState({ editingKey: null });
  };

  save = async (key: number) => {
    const selectedType = await this.formRef.current?.validateFields();
    await api.updateAditTypeName(key, selectedType.Name)
      .then((updateName: any) => {
        const key = "updatable";
        if (updateName) {
          notification.success({
            key,
            message: "Success",
            description: "Audit type updated successfully.",
          });
        } else {
          notification.error({
            key,
            message: "Error",
            description: "Failed to update audit type",
          });
        }
      });
    this.setState({ editingKey: null });
    this.setState({ loading: true });
    this.getAuditTypeDetails()
    this.props.getAuditTypeDetail();
  };

  showItemDeleteConfirm = (id: number) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Do you really want to delete this question? This process cannot be undone.",
      okText: "Yes",
      okType: "ghost",
      cancelText: "No",
      onOk: () => {
        this.handleQuestionDelete(id);
      },
      onCancel() {},
    });
  };

  handleQuestionDelete = async (id: number) => {
    await api.deleteQuestion(id).then(async (response) => {
      if (!(response.status && response.status === 500) && !response.errors) {
        this.showSuccessToaster("Question deleted successfully");
        await this.getCategoriesAndQuestions();
        // this.updateFilterValues();
        this.setFiltersAfterRefresh();
      } else {
        this.props.onError();
      }
    });
  };

  auditTypeColums: ColumnType<any>[] = [
    {
      title: "Sl.no",
      dataIndex: "Index",
      key: "Id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filtered: true,
    },

    {

      dataIndex: "operation",
      render: (_: any, record: any) => {
        const editable = this.isEditing(record);

        return editable ? (
          <span>
            <Typography.Link
              // onClick={() => this.save(record.Id)}
              style={{ marginRight: 8 }}
            >
              <Popconfirm title="Sure to save?" onConfirm={() => this.save(record.Id)}>
                Save
              </Popconfirm>
            </Typography.Link>

            <Typography.Link style={{ color: "#2c2c2c" }} onClick={this.cancel}>
              Cancel
            </Typography.Link>

          </span>
        ) : (
          <Space>

            <Button
              icon={<EditTwoTone />}
              type="text"
              disabled={this.state.editingKey !== null}
              onClick={() => this.edit(record)}
            />


            <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(record)}>
              <Typography.Link style={{ color: "#2c2c2c" }}>
                <DeleteOutlined />
              </Typography.Link>
            </Popconfirm>
            {/* <Button 
          icon={<DeleteOutlined />}
          type="text"
          onClick={()=>this.delete(record)}
          /> */}

          </Space>
        );
      },
    },
  ];

  mergedColumns: any = this.auditTypeColums.map((col) => {
    if (!col.filtered) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: this.isEditing(record),
      }),
    };
  });

  collapseOnChange = (value: any) => {
    let active =
      value[value?.length - 1] === this.state.activeCollapseKey
        ? 0
        : value[value?.length - 1];
    let filteredQuestions = this.state.questions.filter(
      (question: any) =>
        question.Category_ID ===
        Number(value.length === 2 ? value[1] : value[0])
    );
    this.setState(
      {
        menuActiveKey: value.length === 2 ? value[1] : value[0],
        activeCollapseKey: active,
        selectedCategoryQuestions: filteredQuestions,
      },
      () => {}
    );
  };
  getActivePanel = (id: any) => {
    if (id !== 0) {
      return [id];
    } else {
      return [];
    }
  };

  removeAuditAssigned= async(auditTypeId:Number,projectAuditType:Number)=>{   
    await api.removeAuditAssign({auditTypeId:auditTypeId,projectAuditTypeId:projectAuditType})
    .then((addName: string) => {
      if (addName) {
        notification.success({
          message: "Success",
          description: "Audit type deleted successfully.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete audit type",
        });
      }
      this.getAuditTypeDetails();
      this.props.getAuditTypeDetail()
    });
  }

  addAuditType = async (name: string) => {
    await api.addAuditType({ auditType: name })
      .then((addName: string) => {
        const key = "updatable";
        if (addName) {
          notification.success({
            key,
            message: "Success",
            description: "Audit type added successfully.",
          });
        } else {
          notification.error({
            key,
            message: "Error",
            description: "Failed to add audit type",
          });
        }
      });
      this.getAuditTypeDetails();
      this.props.getAuditTypeDetail()
    this.setState({ typeNameNew: "", isValid:false });
    
  };

  assignedAuditType = async(auditTypeId: Number, projectAuditId: Number) => {
    var similar= this.state.auditTypesDetails.filter((data: any) => projectAuditId == data.projectTypeId && auditTypeId==data.auditTypeId)
        await api.assignAuditType({auditType_Id:auditTypeId,projectAuditTyp_Id:projectAuditId})
    .then((assign)=>{
      if(assign){
        notification.success({
          message: "Success",
          description: "Audit type assigned successfully.",
        });
      }else {
        notification.error({
          message: "Error",
          description: "Failed to assign audit type",
        });
      }
      this.setState({showAddModal:false});
      this.getAuditTypeDetails();
      this.props.getAuditTypeDetail()
    })
  }
  assignAuditType = (value: any) => {
    this.setState({ assignedAuditType: value });
    
  }

  categoryHandleChange = (categoryId: number) => {
    this.setState(
      {
        dropdownSelectedCategoryId: categoryId,
        category: this.state.categoryCopy,
        questions: this.state.questionsCopy,
        menuActiveKey: 0,
        searchText: null,
        activeCollapseKey: this.state.categoryCopy[0]?.Category_ID,
      },
      () => {
        if (categoryId !== 0) {
          let filteredCategories = this.state.categoryCopy.filter(
            (item: any) => item.Category_ID === categoryId
          );
          let filteredQuestions = this.state.questionsCopy.filter(
            (item: any) => item.Category_ID === categoryId
          );
          this.setState({
            category: filteredCategories,
            questions: filteredQuestions,
            selectedCategoryQuestions: filteredQuestions,
            menuActiveKey: categoryId,
            activeCollapseKey: filteredCategories[0]?.Category_ID,
          });
        }
      }
    );
  };

  setFiltersAfterRefresh = () => {
    this.state.dropdownSelectedCategoryId
      ? this.categoryHandleChange(this.state.dropdownSelectedCategoryId)
      : console.log();
    this.state.searchText
      ? this.searchQuestion(this.state.searchText)
      : console.log();
  };

  validateField = (value: string) => {
    let regex = /^[ A-Za-z0-9_@./#()&+-]*$/;
    if (!regex.test(value)) {
      // this.props.onValidationError();
      this.showValidatorToaster("Input validation failed");
      return false;
    } else return true;
  };

  OnCreateorUpdateCategory = async () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(async (values) => {
        await this.createorUpdateCategory(values);
        this.setFiltersAfterRefresh();
      });
    }
  };

  OnCreateNextCategory = async () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(async (values) => {
        await this.createorUpdateCategory(values);
        this.formRef.current?.resetFields(["categoryName"]);
        this.setFiltersAfterRefresh();
      });
    }
    this.setState({
      openAddCategoryForm: true,
    });
  };

  createorUpdateCategory = async (data: any) => {
    try {
      if (this.validateField(data.categoryName)) {
        data.auditType = Number(this.state.selectedAuditType);
        data.projectAuditType = Number(this.state.selectedCategory);
        const idCheck: boolean = data?.id;
        let response = data?.id
          ? await api.updateCategory(data.id, data)
          : await api.addCategory(data);
        if (!(response.status && response.status === 500) && !response.errors) {
          if (idCheck) {
            this.showSuccessToaster("Category updated successfully");
          } else {
            this.showSuccessToaster("Category added successfully");
          }
          if (!this.state.openAddCategoryForm) {
            this.onCategoryAddPopupCancel();
          }
          await this.getCategoriesAndQuestions();
          // this.updateFilterValues();
          this.setState({
            openAddCategoryForm: false,
          });
        } else {
          this.showErrorToaster("Something went wrong");
        }
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        this.showErrorToaster(`Category '${data.categoryName}' already exists`);
      } else {
        this.showErrorToaster("Something went wrong");
      }
    }
  };

  updateFilterValues = () => {
    this.setState({
      dropdownSelectedCategoryId: 0,
      searchText: null,
    });
  };

  reOrder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    } else {
      if (result?.type === "category") {
        const items = this.reOrder(
          this.state.category,
          result.source.index,
          result.destination.index
        );
        this.handleCategoryRLDDChange(items);
      } else {
        const items = this.reOrder(
          this.state.selectedCategoryQuestions,
          result.source.index,
          result.destination.index
        );
        this.handleQuestionsRLDDChange(items);
      }
    }
  };

  questionsList = (questions: any, index: number, categoryId: number) => {
    return (
      <Droppable droppableId={`droppable${categoryId}`} type={`${index}`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {questions.map((question: any, index: number) => {
              return (
                <Draggable
                  key={`${index}`}
                  draggableId={`${index}${categoryId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ul className="m-0 p-0 content-group content-group-draggable">
                        <li
                          className={"bg-white"}
                          {...provided.dragHandleProps}
                        >
                          <div className="d-flex align-item-center">
                            <div className="flex-fill">
                              <span>{question.Question}</span>
                            </div>
                            <div className="d-flex">
                              <div className="d-inline-block align-middle pr-2 border-right mr-2 text-primary pointer">
                                <EditFilled
                                  onClick={() =>
                                    this.handleQuestionEdit(question.id)
                                  }
                                />
                              </div>
                              <div className="d-inline-block align-middle text-secondary pointer">
                                <DeleteFilled
                                  onClick={() =>
                                    this.showItemDeleteConfirm(question.id)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  auditTypeList = (filteredTypes: any, index: number, item: any) => {
    return (
      <div>
        
            {filteredTypes.map((types: any, index: number) => {
              return (
                <div>
                    <ul className="m-0 p-0 content-group ">
                        <li
                          className={"bg-white"}
                          
                        >
                          <div className="d-flex align-item-center">
                            <div className="flex-fill">
                              <span>{types.auditTypeName}</span>
                            </div>
                            <div className="d-flex">
                              <div className="d-inline-block align-middle pr-2 border-right mr-2 text-primary pointer">
                                
                              </div>
                              <div className="d-inline-block align-middle text-secondary pointer">

<Popconfirm title="Sure to delete?" onConfirm={() => this.removeAuditAssigned(types.auditTypeId,types.projectTypeId)}>
              <Typography.Link style={{ color: "#2c2c2c" }}>
                                <DeleteFilled
                                 key={`${types.auditTypeId}`} 
                                />
                                </Typography.Link>
                                </Popconfirm>
                              </div>
                            </div>
                          </div>
                          </li>
                      </ul>
                </div>
              );
            })}
           
      <div>

{this.state.showAddModal && this.state.selectedId === item.project_audit_type_id ? (<div key="new-sub-menu">
          <Select
            style={{ width: 150 }}
            placeholder="Select Audit Type"
            //  optionFilterProp="children"
            onSelect={this.assignAuditType}
          //  defaultValue={0}
          // value={this.state.selectedStatus}
          >
            {this.state.allAuditTypes.map(
              (
                auditstatus: {
                  Id: number;
                  Name: string;
                },
                index: number
              ) => {
                return (
                  <React.Fragment key={index}>
                    <Option value={auditstatus.Id}>
                      {auditstatus.Name}
                    </Option>
                    
                  </React.Fragment>
                  
                );
              }
            )}

          </Select>
          <span>
          <Button onClick={() => {
            this.assignedAuditType(this.state.assignedAuditType, item.project_audit_type_id)
          }}>
            Save
          </Button>
          <Button onClick={()=>this.setState({showAddModal:false})}>Cancel</Button>
          </span>
        </div>) : null}
</div>
      </div>
    );
  };

  render() {
    return (
      
      <Layout>
        
        <div className="page-title">
          <div className="container-fluid">
            <h1>
              {this.props.keyPath[1] == configData[4].key
                ? `${configData[4].title} - `
                : this.props.keyPath[1] == configData[9].key
                ? `${configData[9].title} - `
                : this.props.keyPath[1] == configData[14].key
                ? `${configData[14].title} - `
                : this.props.keyPath[1] == configData[17].key
                ? `${configData[17].title} - `
                : this.props.keyPath[1] == configData[19].key
                ? `${configData[19].title} - `
                : this.props.keyPath[0]== "addAuditType"
                ? `Add Audit Type  `
                : ""}

              {this.props.keyPath[0] == configData[5].key ||
              this.props.keyPath[0] == configData[10].key
                ? `${configData[5].title}`
                : ""}
              {this.props.keyPath[0] == configData[6].key ||
              this.props.keyPath[0] == configData[11].key
                ? `${configData[6].title}`
                : ""}
              {this.props.keyPath[0] == configData[8].key ||
              this.props.keyPath[0] == configData[13].key
                ? `${configData[8].title}`
                : ""}
              {this.props.keyPath[0] == configData[7].key ||
              this.props.keyPath[0] == configData[12].key
                ? `${configData[7].title}`
                : ""}
              {this.props.keyPath[0] == configData[15].key
                ? `${configData[15].title}`
                : ""}
              {this.props.keyPath[0] == configData[16].key
                ? `${configData[16].title}`
                : ""}
              {this.props.keyPath[0] == configData[18].key
                ? `${configData[18].title}`
                : ""}
              {this.props.keyPath[0] == configData[20].key
                ? `${configData[20].title}`
                : ""}
            </h1>
          </div>
        </div>
        {this.props.keyPath[0]!== "addAuditType" ? (
          <>
        <Content
          className="site-layout-background custom-position"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="container-fluid h-100">
            <div className=" mb-4 h-100 border-0">
              <div className="main-content h-100">
                <div className="d-flex flex-column h-100">
                  <div className="flex-auto mb-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-fill">
                        <div className="d-inline-block mr-3 align-middle">
                          <Select
                            style={{ width: 300 }}
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onSelect={this.categoryHandleChange}
                            defaultValue={0}
                            value={this.state.dropdownSelectedCategoryId}
                          >
                            <Option value={0}>All Categories</Option>
                            {this.state.categoryCopy.map(
                              (
                                category: {
                                  Category_ID: number;
                                  Category_Name: string;
                                },
                                index: number
                              ) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Option value={category.Category_ID}>
                                      {category.Category_Name}
                                    </Option>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Select>
                        </div>

                        <div className="d-inline-block mr-3 align-middle">
                          <Search
                            className="custom-field"
                            placeholder="Search Question"
                            value={this.state.searchText}
                            onChange={this.searchHandler}
                            onSearch={(value) => this.searchQuestion(value)}
                            style={{ width: 300 }}
                            allowClear
                          />
                        </div>
                        <div className="d-inline-block mr-3 align-middle">
                          <Button
                            className="custom-icon-btn"
                            type="primary"
                            icon={<ReloadOutlined />}
                            onClick={this.getCategoriesAndQuestions}
                          ></Button>
                        </div>
                      </div>
                      <div className="flex-auto">
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={this.navigateToAddCategory}
                        >
                          Add Question
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "flex-fill overflow-auto" +
                      (this.state.category.length == 0 ? " d-flex" : "")
                    }
                  >
                    <this.EmptySearch
                      noResult={this.state.category.length == 0}
                    />
                    {/* <RLDD
                                            items={this.state.category}
                                            itemRenderer={this.categoryListRenderer}
                                            onChange={this.handleCategoryRLDDChange}
                                        /> */}
                    {/* {this.state.category.map((category: any, index: number) => {
                                            let filteredQuestions = this.state.questions.filter((item: any)=> (category.Category_ID === item.Category_ID))

                                            return (
                                                <Collapse className="is-accordion"
                                                onChange={this.collapseOnChange}
                                                defaultActiveKey={'[1]'}
                                                >

                                                    <Panel header={category.Category_Name} key={category.Category_ID} extra={this.editOrDeleteCategory(category.Category_ID)}>
                                                        {filteredQuestions.length === 0 && <Empty/> }
                                                    <RLDD
                                                        items={filteredQuestions}
                                                        itemRenderer={this.listItemRenderer}
                                                        onChange={this.handleRLDDChange}
                                                    />
                                                    </Panel>
                                                </Collapse>
                                            )
                                       })} */}
                    <div>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" type="category">
                          {(
                            provided: DroppableProvided,
                            snapshot: DroppableStateSnapshot
                          ) => (
                            <div ref={provided.innerRef}>
                              {this.state.category.map(
                                (category: any, index: any) => {
                                  let filteredQuestions: any = [];
                                  filteredQuestions =
                                    this.state.questions.filter(
                                      (question: any) =>
                                        question.Category_ID ===
                                        category.Category_ID
                                    );
                                  return (
                                    <Draggable
                                      key={category.id}
                                      draggableId={String(category.id)}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Collapse
                                            className="is-accordion"
                                            onChange={this.collapseOnChange}
                                            // defaultActiveKey={[`${this.state.category[0]?.Category_ID}`]}
                                            activeKey={
                                              this.state.activeCollapseKey
                                            }
                                          >
                                            <Panel
                                              header={category.Category_Name}
                                              key={category.Category_ID}
                                              extra={this.editOrDeleteCategory(
                                                category.Category_ID
                                              )}
                                            >
                                              {filteredQuestions.length ===
                                                0 && <Empty />}
                                              {this.questionsList(
                                                filteredQuestions,
                                                index,
                                                category.id
                                              )}
                                            </Panel>
                                          </Collapse>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {this.state.openAddQuestionForm && (
              <AddQuestions
                selectedQuestion={this.state.selectedQuestion}
                category={this.state.category}
                visible={this.state.openAddQuestionForm}
                onCancel={this.onPopupCancel}
                validateField={(value: any) => this.validateField(value)}
                manageAddCategory={this.manageAddCategory}
                questionAddOpen={this.navigateToAddCategory}
                onSuccess={(message: any) => this.showSuccessToaster(message)}
                onError={() => this.showErrorToaster("Something went wrong")}
                onValidationError={() =>
                  this.showValidatorToaster("Category phase validation failed")
                }
                selectedAuditType={Number(this.state.selectedAuditType)}
                selectedCategory={Number(this.state.selectedCategory)}
                filterList={this.categoryHandleChange}
                onRefresh={this.getCategoriesAndQuestions}
                updateFilterValues={this.updateFilterValues}
                setFiltersAfterRefresh={this.setFiltersAfterRefresh}
                dropdownSelectedCategoryId={
                  this.state.dropdownSelectedCategoryId
                }
              ></AddQuestions>
            )}
            {this.state.openAddCategoryForm && (
              <Modal
                visible={this.state.openAddCategoryForm}
                title={
                  (this.state.selectedSubCategory?.Category_ID
                    ? "Update"
                    : "Add") + " Category"
                }
                maskClosable={false}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.OnCreateorUpdateCategory}
                  >
                    {this.state.selectedSubCategory?.Category_ID
                      ? "Update"
                      : "Add"}
                  </Button>,
                  !this.state.selectedSubCategory?.Category_ID ? (
                    <Button
                      key="submit"
                      type="primary"
                      onClick={this.OnCreateNextCategory}
                    >
                      Add & Next
                    </Button>
                  ) : null,
                  <Button key="cancel" onClick={this.onCategoryAddPopupCancel}>
                    Cancel
                  </Button>,
                ]}
                onCancel={this.onCategoryAddPopupCancel}
              >
                <Form
                  layout="vertical"
                  ref={this.formRef}
                  name="categoryAddModal"
                  initialValues={{
                    id: this.state.selectedSubCategory?.Category_ID,
                    categoryName: this.state.selectedSubCategory?.Category_Name,
                  }}
                >
                  <Form.Item style={{ display: "none" }} name="id">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="categoryName"
                    label="Category"
                    rules={[
                      { required: true, message: "Please enter a Category" },
                    ]}
                  >
                    <Input allowClear placeholder="Category name" />
                  </Form.Item>
                </Form>
              </Modal>
            )}
          </div>
        </Content>
        </>):<div>
          <Content
        className="site-layout-background custom-position"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
>
        <div className="text-right mb-3">
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            this.setState({ openModal: true })
                          }
                        >
                          Add Audit Type
                        </Button>
                      </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" type="category">
                          {(
                            provided: DroppableProvided,
                            snapshot: DroppableStateSnapshot
                          ) => (
                            <div ref={provided.innerRef}>
<Menu
mode="inline"
defaultSelectedKeys={[configData[1].key]}
defaultOpenKeys={[configData[0].key]}
style={{ height: "100%", borderRight: 0 }}>
  {this.state.projectAuditTypeList.map((item: any,index:number) => {
    let filteredTypes =
    this.state.auditTypesDetails.filter((data: any) => item.project_audit_type_id == data.projectTypeId
    );
    
                    return (
                      <Collapse
                                            className="is-accordion"
                                            onChange={this.collapseOnChange}
                                            // defaultActiveKey={[`${this.state.category[0]?.Category_ID}`]}
                                            activeKey={
                                              this.state.activeCollapseKey
                                            }
                                          >
                                            <Panel
                                              header={item.project_audit_type_name}
                                              key={item.project_audit_type_id}
                                              extra={this.assigningAuditType(
                                                item
                                              )}
                                            >
                                              {filteredTypes.length ===
                                                0 && <Empty />}
                                              {this.auditTypeList(
                                                filteredTypes,
                                                index,
                                                item)
                                                }
                                              
                                            </Panel>
                      
                      </Collapse>
                      )
                  }
                  )}
                  </Menu>
                  {provided.placeholder}
                            </div>
                          )}
                  </Droppable>
                      </DragDropContext>
                      </Content>
        </div>
         }
         <Modal
                  title="Add Audit Type"
                  centered
                  visible={this.state.openModal}
                  okButtonProps={{ style: { display: "none" } }}
                  cancelButtonProps={{ style: { display: "none" } }}
                  onCancel={() => this.setState({ openModal: false })}
                  width={500}
                  footer={false}
                // bodyStyle={{ height: 500 }}
                >
                  <Form>
                    
                    <Form.Item
                      name={"Audit Type Name"}
                      rules={[
                        {
                          pattern: new RegExp(/^[^\s][A-Za-z\s]*$/),
                          message: "Please add a meaningful name"
                        },
                        { max: 20, message: 'Maximum 20 characters allowed' }
                      ]}>
                      {<Row gutter={8}>
                        <Col>
                          <Input
                            style={{ width: "200px" }}
                            placeholder="New Audit Type"
                            maxLength={20}
                            value={this.state.typeNameNew}
                            onChange={this.typeNameChange}
                          />
                        </Col>
                        <Col>
                          <Tooltip title="Add" placement="top">
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              className="custom-icon-btn"
                              onClick={() =>
                                this.addAuditType(this.state.typeNameNew)
                              }
                              disabled={!this.state.isValid}
                            />

                          </Tooltip>
                        </Col>
                      </Row>
                      }
                    </Form.Item>
                  </Form>

                  <Form ref={this.formRef} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      columns={this.mergedColumns}
                      dataSource={this.state.allAuditTypes}
                      loading={this.state.show}
                      pagination={{ onChange: this.cancel }}
                    />
                  </Form>
                </Modal>
      </Layout>
     
    );
  };
}

export default CheckListComponent;
