import React from "react";
import {
  Layout,
  Menu,
  Input,
  Button,
  Select,
  Dropdown,
  Table,
  Tooltip,
  Spin,
  Modal,
  Row,
  Col,
  Typography,
  Popconfirm,
  Form,
  InputNumber,
  Space,
  notification,
  Alert,
} from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  DownOutlined,
  PlusOutlined,
  EditFilled,
  FileAddFilled,
  ReloadOutlined,
  WarningFilled,
  AppstoreOutlined,
  ContainerOutlined,
  PieChartOutlined,
  CloudServerOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  EditOutlined,
  DeleteOutlined,
  EditTwoTone,
  CaretDownOutlined,
  UserSwitchOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { api } from "../../apiServices/apiServices";
import { Link } from "react-router-dom";
import moment from "moment";
import CheckListComponent from "./Content";
import configData from "../../config/fieldsConfig.json";
import { ColumnProps } from "antd/es/table";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { ColumnType } from "antd/lib/table";
import Item from "antd/lib/descriptions/Item";
import form, { FormInstance } from "antd/lib/form";
import { Pie } from "@ant-design/plots";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Constants } from "../../constants/constants";
import { async } from "q";
// import {
//   NotificationContainer,
//   NotificationManager,
// } from "react-notifications";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import UpcomingAudits from "./AdminDashboardComponents/UpcomingAudits";
import UserManagementComponent from "./UserManagement";

const { SubMenu } = Menu;
const { Option } = Select;
const { Search } = Input;
const { Header, Content, Sider } = Layout;



const AuditAssign = () => (
  <svg width="24px" height="24px" fill="white" viewBox="0 0 24 24">
    <path
      d="M9.31 19.5c-.41 0-.75-.34-.75-.75v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 .41-.34.75-.75.75z"
      fill="#fff"
    />
    <path
      d="M11.75 17h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75zM14 6.75h-4c-.96 0-2.75 0-2.75-2.75S9.04 1.25 10 1.25h4c.96 0 2.75 0 2.75 2.75 0 .96 0 2.75-2.75 2.75zm-4-4c-.99 0-1.25 0-1.25 1.25S9.01 5.25 10 5.25h4c1.25 0 1.25-.26 1.25-1.25 0-1.25-.26-1.25-1.25-1.25h-4z"
      fill="#fff"
    />
    <path
      d="M15 22.75H9c-5.62 0-6.75-2.58-6.75-6.75v-6c0-4.56 1.65-6.51 5.71-6.72.4-.02.77.29.79.71.02.42-.3.76-.71.78C5.2 4.93 3.75 5.78 3.75 10v6c0 3.7.73 5.25 5.25 5.25h6c4.52 0 5.25-1.55 5.25-5.25v-6c0-4.22-1.45-5.07-4.29-5.23a.757.757 0 0 1-.71-.79c.02-.41.38-.73.79-.71 4.06.22 5.71 2.17 5.71 6.72v6c0 4.18-1.13 6.76-6.75 6.76z"
      fill="#fff"
    />
  </svg>
);

const AuditReport = () => (
  <svg width="24px" height="24px" fill="white" viewBox="0 0 24 24">
    <path
      d="M15 12.95H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7c.41 0 .75.34.75.75s-.34.75-.75.75zM12.38 16.95H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.38c.41 0 .75.34.75.75s-.34.75-.75.75zM14 6.75h-4c-.96 0-2.75 0-2.75-2.75S9.04 1.25 10 1.25h4c.96 0 2.75 0 2.75 2.75 0 .96 0 2.75-2.75 2.75zm-4-4c-.99 0-1.25 0-1.25 1.25S9.01 5.25 10 5.25h4c1.25 0 1.25-.26 1.25-1.25 0-1.25-.26-1.25-1.25-1.25h-4z"
      fill="#fff"
    />
    <path
      d="M15 22.75H9c-5.62 0-6.75-2.58-6.75-6.75v-6c0-4.56 1.65-6.51 5.71-6.72.4-.02.77.29.79.71.02.42-.3.76-.71.78C5.2 4.93 3.75 5.78 3.75 10v6c0 3.7.73 5.25 5.25 5.25h6c4.52 0 5.25-1.55 5.25-5.25v-6c0-4.22-1.45-5.07-4.29-5.23a.757.757 0 0 1-.71-.79c.02-.41.38-.73.79-.71 4.06.22 5.71 2.17 5.71 6.72v6c0 4.18-1.13 6.76-6.75 6.76z"
      fill="#fff"
    />
  </svg>
);

const AuditUpcoming = () => (
  <svg width="24px" height="24px" fill="white" viewBox="0 0 24 24">
    <path
      d="M14 6.75h-4c-.96 0-2.75 0-2.75-2.75S9.04 1.25 10 1.25h4c.96 0 2.75 0 2.75 2.75 0 .96 0 2.75-2.75 2.75zm-4-4c-.99 0-1.25 0-1.25 1.25S9.01 5.25 10 5.25h4c1.25 0 1.25-.26 1.25-1.25 0-1.25-.26-1.25-1.25-1.25h-4z"
      fill="#fff"
    />
    <path
      d="M14 22.75H9c-5.62 0-6.75-2.58-6.75-6.75v-6c0-4.56 1.65-6.51 5.71-6.72.39-.02.77.29.79.71.02.42-.3.76-.71.78C5.2 4.93 3.75 5.78 3.75 10v6c0 3.7.73 5.25 5.25 5.25h5c.41 0 .75.34.75.75s-.34.75-.75.75zM21 15.75c-.41 0-.75-.34-.75-.75v-5c0-4.22-1.45-5.07-4.29-5.23a.757.757 0 0 1-.71-.79c.02-.41.38-.73.79-.71 4.06.22 5.71 2.17 5.71 6.72v5c0 .42-.34.76-.75.76zM15 19.75c-.41 0-.75-.34-.75-.75v-3c0-.41.34-.75.75-.75h3c.41 0 .75.34.75.75s-.34.75-.75.75h-2.25V19c0 .41-.34.75-.75.75z"
      fill="#fff"
    />
    <path
      d="M21 22.75c-.19 0-.38-.07-.53-.22l-5.96-5.96a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l5.96 5.96c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
      fill="#fff"
    />
  </svg>
);

const AuditArchived = () => (
  <svg width="24px" height="24px" fill="white" viewBox="0 0 24 24">
    <path
      d="M10.81 16.95c-.19 0-.38-.07-.53-.22l-1.5-1.5a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l.97.97 3.47-3.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-4 4c-.14.15-.34.22-.53.22zM14 6.75h-4c-.96 0-2.75 0-2.75-2.75S9.04 1.25 10 1.25h4c.96 0 2.75 0 2.75 2.75 0 .96 0 2.75-2.75 2.75zm-4-4c-.99 0-1.25 0-1.25 1.25S9.01 5.25 10 5.25h4c1.25 0 1.25-.26 1.25-1.25 0-1.25-.26-1.25-1.25-1.25h-4z"
      fill="#fff"
    />
    <path
      d="M15 22.75H9c-5.62 0-6.75-2.58-6.75-6.75v-6c0-4.56 1.65-6.51 5.71-6.72.41-.02.77.29.79.71.02.42-.3.76-.71.78C5.2 4.93 3.75 5.78 3.75 10v6c0 3.7.73 5.25 5.25 5.25h6c4.52 0 5.25-1.55 5.25-5.25v-6c0-4.22-1.45-5.07-4.29-5.23a.75.75 0 1 1 .08-1.5c4.06.22 5.71 2.17 5.71 6.72v6c0 4.18-1.13 6.76-6.75 6.76z"
      fill="#fff"
    />
  </svg>
);

const Assign = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AuditAssign} {...props} />
);

const Report = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AuditReport} {...props} />
);

const Upcoming = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AuditUpcoming} {...props} />
);

const Archived = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AuditArchived} {...props} />
);

class AdminDashboard extends React.Component<any, any> {
  navigateToLogin(e: any) {
    api.logOut();
  }
  navigateToEditProject(projectId: number, taProjectId: number) {
    this.props.history.push({
      pathname: "/editproject",
      state: { projectId: projectId, TaProjectId: taProjectId },
    });
  }

  menu = (
    <Menu>
      <Menu.Item onClick={(e) => this.navigateToLogin(e)} key="0">
        Logout
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          this.props.history.push("/auditordashboard");
        }}
      >
        Auditor Dashboard
      </Menu.Item>
    </Menu>
  );

  userData: any = "";
  upcomingAudits: any = [];
  // formRef = React.createRef<FormInstance>();
  constructor(props: any) {
    super(props);
    this.state = {
      allProjects: [],
      allProjectsCopy: [],
      projectDetails: [],
      allAuditTypes: [],
      username: "",
      rolename: "",
      profilePic: "",
      hrAuditCount: 0,
      systemAuditCount: 0,
      selectedStatus: 0,
      searchText: "",
      menuSelectedValue: "dashboard",
      sortedInfo: null,
      openModal: false,
      typeNameNew: "",
      editingKey: null,
      loading: true,
      isValid: false,
      totalCount: 0,
      auditStarted: 0,
      auditNotStarted: 0,
      auditNotStartedProjects: [],
      upcomingAudits: 0,
      archivedProjects: 0,
      auditTypesDetails: [],
      projectAuditTypeList: [],
      showAddModal: false,
      selectedId: null,
      assignedAuditType: null,
      show:false

    };
  }

  async componentDidMount() {
    await this.getAllAuditLists();
    await this.getAuditCount();
    await this.getAuditTypeDetails();
    this.setState({
      loading: false,
    });
  }

  navigate = (
    path: string,
    auditstatusid: number,
    projectid: number,
    audittypeid: number,
    projectTeam: any,
    nc: any,
    pc: any,
    bnc: any,
    na: any,
    ok: any,
    reaudit: any,
    auditId: any,
    allauditType: any,
    auditTypesDetails:any,
    projectname: any,
    auditor: any,
    auditee: any,
    auditassignId: any,
    aa_date: any,
    auditstate: any,
    auditStatus: any
  ) => {
    this.props.history.push({
      pathname: path,
      state: {
        auditstatusId: auditstatusid,
        projectId: projectid,
        AuditTypeId: audittypeid,
        auditstatusList: this.state.allAuditStatuses,
        ProjectTeam: projectTeam,
        NC: nc,
        PC: pc,
        BNC: bnc,
        NA: na,
        OK: ok,
        ReAudit: reaudit,
        AuditId: auditId,
        AllAuditType: allauditType,
        auditTypesDetails:auditTypesDetails,
        Project_Name: projectname,
        Auditor: auditor,
        Auditee: auditee,
        AuditAssignID: auditassignId,
        Audit_Assign_Date: aa_date,
        Audit_State: auditstate,
        AuditStatus: auditStatus,
      },
    });
  };

  dataSource = [
    {
      key: "1",
      project: "Project",
      process: 32,
      auditee: "sample text",
      status: "sample text",
    },
    {
      key: "2",
      project: "Project",
      process: 32,
      auditee: "sample text",
      status: "sample text",
    },
    {
      key: "3",
      project: "Project",
      process: 32,
      auditee: "sample text",
      status: "sample text",
    },
    {
      key: "4",
      project: "Project",
      process: 32,
      auditee: "sample text",
      status: "sample text",
    },
  ];

  columns = [
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
    },
    {
      title: "Process Stage",
      dataIndex: "process",
      key: "process ",
    },
    {
      title: "Auditee",
      dataIndex: "auditee",
      key: "auditee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];


  handleClick = (e: any) => { 
    let id = e.key.split("_");
    e.keyPath[0] = id[0];
    if (id[0] === configData[1].key){
      
      this.setState({ menuSelectedValue: id[0] });}
      else if (id[0] === configData[27].key) {
        this.setState({ menuSelectedValue: e.key });
      }
    else {
      this.setState({ menuSelectedValue: e.keyPath });
    }
  };
    


  getAuditTypeDetails = async () => {
    
    let projectAuditTypes = await api.getAllProjectTypes()
    let auditTypeDetails = await api.getAllAuditTypeDetails()
    this.setState({
      auditTypesDetails: auditTypeDetails,
      projectAuditTypeList: projectAuditTypes
    }
    )}

  getAllAuditLists = async () => {
    this.setState({ show: true });
    let auditDate = 0;
    let dashboarddata: any = [];
    let allAuditStatus: never[] = [];

    const token = Cookies.get("token") as string;
    if (token) {
      this.userData = jwt_decode(token);
    }
    allAuditStatus = await api.getAllAuditType();
    await api
      .getAllProject()
      .then((allProject: any) => {
        localStorage.setItem("allProjects", JSON.stringify(allProject));
        this.setState({
          show: false,
          allProjects: allProject,
          allProjectsCopy: allProject,
          projectDetails: allProject,
          hrAuditCount: 0,
          systemAuditCount: 0,
        });
      })
      .then(() => {
        this.state.allProjects.map((project: any, index: number) => {
          if (project.Displayed === 0) {
            auditDate = project.AuditDate;
          }
          if (project.Displayed === 1) {
            let auditee =
              project.AuditTypeId === 4 || project.AuditTypeId === 5
                ? project.Auditee
                : "";
            let projectTeam = JSON.parse(project.ProjectTeam);
            projectTeam?.map((item: any) => {
              if (Number(project.Auditee) === Number(item.id)) {
                auditee = item.Name;
              }
            });

            if (project.AuditTypeId === 4) {
              if (project.LocationId === 1)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
              else if (project.LocationId === 2)
                this.setState({ hrAuditCount: this.state.hrAuditCount + 1 });
            } else if (project.AuditTypeId === 5) {
              if (project.LocationId === 1)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
              else if (project.LocationId === 2)
                this.setState({
                  systemAuditCount: this.state.systemAuditCount + 1,
                });
            }

            dashboarddata.push({
              Project: project.Name,
              AuditType: project.AuditTypeId
                ? project.Audit_Type_Name +
                  (project.Audit_State ? " " + project.Audit_State : "")
                : "Not Planned",
              AuditDate: auditDate
                ? moment(auditDate).format("DD MMM YYYY")
                : project.AuditAssignDate == null
                ? "Not Planned"
                : moment(project.AuditAssignDate).format("DD MMM YYYY"),
              Auditee: auditee ? auditee : "Not Assigned",
              color: (
                <div>
                  <Tooltip
                    title={
                      project.auditstatusId == 1
                        ? "New"
                        : project.auditstatusId == 2
                        ? "In Progress"
                        : "Done"
                    }
                  >
                    <span
                      className="color-status"
                      style={
                        project.auditstatusId == 2
                          ? { backgroundColor: "#ffa64d" }
                          : project.auditstatusId == 3
                          ? { backgroundColor: "#73d13d" }
                          : { backgroundColor: "#ff4d4f" }
                      }
                    />
                  </Tooltip>
                </div>
              ),
              action: (
                <div className="icon-group">
                  <React.Fragment>
                    <div className="d-inline-block mr-3 align-middle pointer icon-card">
                      {project.AuditTypeId === 4 ||
                      project.AuditTypeId === 5 ? (
                        ""
                      ) : (
                        <div
                          onClick={(e) => {
                            this.navigateToEditProject(
                              project.project_Id,
                              project.TaProjectId
                            );
                          }}
                        >
                          <ReloadOutlined title="Reload">
                            <EditFilled />
                          </ReloadOutlined>
                        </div>
                      )}
                    </div>

                    <div className="d-inline-block mr-3 align-middle pointer icon-card">
                      <div
                        onClick={(e) =>
                          this.navigate(
                            "/assignauditor",
                            project.auditstatusId,
                            project.project_Id,
                            project.AuditTypeId,
                            project.ProjectTeam,
                            project.NC,
                            project.PC,
                            project.BNC,
                            project.NA,
                            project.OK,
                            project.Reaudit,
                            project.Audit_Id,
                            this.state.allAuditTypes,
                            this.state.auditTypesDetails,
                            project.Name,
                            project.Auditor,
                            project.Auditee,
                            project.Audit_Assign_ID,
                            project.AuditAssignDate,
                            project.Audit_State,
                            project.AuditStatus
                          )
                        }
                      >
                        <Tooltip title="Assign Auditor">
                          <FileAddFilled />
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      {project.Reaudit === 1 &&
                      project.AuditStatus === "Done" ? (
                        <WarningFilled />
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                </div>
              ),
            });
          }
        });
      })
      .then(() => {
        this.setState({
          projectDetails: dashboarddata,
          allAuditTypes: allAuditStatus,
          name: this.userData ? this.userData.name : "",
          username: this.userData ? this.userData.email : "",
          rolename: this.userData ? this.userData.roleName : "",
          profilePic: this.userData ? this.userData.profilePic : "",
        });
      });
    this.setState({ loading: false });
  };

  getAuditCount = async () => {
    this.setState({ show: true });
    var result = await api.getAllTaProjects();
    let totalCount = result.length;
    var countResult = await api.auditStartedCount(JSON.stringify(result));
    var auditStartedProjects = countResult.auditStartedProjects;
    var auditNotStartedProjects = result.filter(
      (obj1: { ProjectId: any }) =>
        !auditStartedProjects.some(
          (obj2: { TaProjectId: any }) =>
            Number(obj1.ProjectId) == Number(obj2.TaProjectId)
        )
    );
    this.setState({
      auditNotStartedProjects: auditNotStartedProjects,
    });
    var auditNotStartedProjectsCount = auditNotStartedProjects.length;
    var projects: any = localStorage.getItem("allProjects");
    var projectlist = JSON.parse(projects);
    projectlist.map(async (x: any) => {
      if (x.auditstatusId === 1 || x.auditstatusId === 2) {
        this.upcomingAudits.push(x);
      }
    });
    const archivedProjects = await api.getTaArchivedProjects();
    this.setState({
      totalCount: totalCount,
      auditStarted: countResult.count,
      auditNotStarted: auditNotStartedProjectsCount,
      upcomingAudits: this.upcomingAudits.length,
      archivedProjects: archivedProjects.length,
      show:false
    });

  };

  render() {
    let data = [
      {
        type: Constants.AUDITS_STARTED,
        value: this.state.auditStarted,
      },
      {
        type: Constants.AUDITS_NOT_STARTED,
        value: this.state.auditNotStarted,
      },
    ];

    let config: any = {
      appendPadding: 10,
      data,
      angleField: "value",
      colorField: "type",
      color: ["#2985db", "#f48b0b"],
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 15,
          },
          content: `<div>Total Projects<br/>${this.state.totalCount}<br/></div>`,
        },
      },
      onReady: (plot: {
        on: (arg0: string, arg1: (...args: any[]) => void) => void;
      }) => {
        plot.on("element:click", (...args) => {
          if (args[0].data.data.type == Constants.AUDITS_NOT_STARTED) {
            this.props.history.push({
              pathname: "/auditsnotstarted",
              state: this.state.auditNotStartedProjects,
            });
          }
        });
      },
    };
    return (
      <>
        <Spin className="h-100" spinning={this.state.show}>
          <Layout className="h-100">
            <Header className="header">
              <div className="d-flex align-items-center">
                <div className="flex-fill">
                  <div className="logo is-text">Auto Audit</div>
                </div>
                <div className="flex-auto">
                  <div className="section-log-out">
                    <div className="group-profile d-flex">
                      <div className="d-flex">
                        <Dropdown overlay={this.menu} trigger={["click"]}>
                          <a
                            className="ant-dropdown-link menu-a d-flex align-items-center"
                            onClick={(e) => e.preventDefault()}
                          >
                            <div className="flex-auto mr-2">
                              <div className="avathar">
                                <img
                                  src={this.state.profilePic}
                                  alt="user-pic"
                                />
                              </div>
                            </div>
                            <div className="flex-fill mr-2">
                              <p className="login-text m-0">
                                {" "}
                                {this.state.name}{" "}
                                <span>{this.state.rolename}</span>
                              </p>
                            </div>
                            <div className="flex-auto">
                              <CaretDownOutlined />
                            </div>
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Header>
            <Layout>
               <Sider width={250}>
                 <Menu
                  mode="inline"
                  defaultSelectedKeys={[configData[1].key]}
                  defaultOpenKeys={[configData[0].key]}
                  style={{ height: "100%", borderRight: 0 }}
                  onClick={this.handleClick}
                >
                  <Menu.Item icon={<LaptopOutlined />} key={configData[1].key}>
                    {configData[1].title}
                  </Menu.Item>
                  <Menu.Item
                    key={configData[2].key}
                    icon={<AppstoreOutlined />}
                    hidden={
                      this.state.hrAuditCount >= 2 &&
                      this.state.systemAuditCount >= 2
                        ? true
                        : false
                    }
                  >
                    <Link
                      to={{
                        pathname: "/assignauditor",
                        state: {
                          AllAuditType: this.state.allAuditTypes,
                          Audit_Assign_Date: new Date(),
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      {configData[2].title}
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key={configData[3].key}
                    icon={<AuditOutlined />}
                    title={
                      <Tooltip title={configData[3].title}>
                        {configData[3].title}
                      </Tooltip>
                    }
                  >
                    {this.state.projectAuditTypeList.map((item: any) => {
                    return (
                      <SubMenu
                        key={item.project_audit_type_id}
                        icon={<PieChartOutlined />}
                        title={<span className="custom-menu-item">
                          <Tooltip title={item.project_audit_type_name}>
                            {item.project_audit_type_name}
                          </Tooltip>
                        </span>
                        }
                      >
                        {this.state.auditTypesDetails.filter((data: any) => item.project_audit_type_id == data.projectTypeId).map((datas: any) => {
                          
                          return (
                            
                          <Menu.Item key={`${datas.auditTypeId}_${item.project_audit_type_id}`}>
                            <div className="custom-menu-item">
                              <p>{datas.auditTypeName}</p>
                              
                            </div>
                          </Menu.Item>
                          
                          )
                        })
                        }

                      </SubMenu>)
                  }
                  )}
                  </SubMenu>
                <SubMenu
                  key={"settings"}
                  icon={<SettingFilled />}
                  title={
                    <Tooltip title={"Settings"}>
                      {"Settings"}
                    </Tooltip>
                  }
                >
                 
                  <Menu.Item key={"addAuditType"}>
                    {"Add Audit Type"}
                  </Menu.Item>
                 
                </SubMenu>

                  {/* <SubMenu
                    key={configData[4].key}
                    icon={<PieChartOutlined />}
                    title={
                      <Tooltip title={configData[4].title}>
                        {configData[4].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[5].key}>
                      {configData[5].title}
                    </Menu.Item>
                    <Menu.Item key={configData[6].key}>
                      {configData[6].title}
                    </Menu.Item>
                    <Menu.Item key={configData[7].key}>
                      {configData[7].title}
                    </Menu.Item>
                    <Menu.Item key={configData[8].key}>
                      {configData[8].title}
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={configData[9].key}
                    icon={<ContainerOutlined />}
                    title={
                      <Tooltip title={configData[9].title}>
                        {configData[9].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[10].key}>
                      {configData[10].title}
                    </Menu.Item>
                    <Menu.Item key={configData[11].key}>
                      {configData[11].title}
                    </Menu.Item>
                    <Menu.Item key={configData[12].key}>
                      {configData[12].title}
                    </Menu.Item>
                    <Menu.Item key={configData[13].key}>
                      {configData[13].title}
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={configData[14].key}
                    icon={<NotificationOutlined />}
                    title={
                      <Tooltip title={configData[14].title}>
                        {configData[14].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[15].key}>
                      {configData[15].title}
                    </Menu.Item>
                    <Menu.Item key={configData[16].key}>
                      {configData[16].title}
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={configData[17].key}
                    icon={<LaptopOutlined />}
                    title={
                      <Tooltip title={configData[17].title}>
                        {configData[17].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[18].key}>
                      {configData[18].title}
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={configData[19].key}
                    icon={<CloudServerOutlined />}
                    title={
                      <Tooltip title={configData[19].title}>
                        {configData[19].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[20].key}>
                      {configData[20].title}
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={configData[21].key}
                    icon={<ReconciliationOutlined />}
                    title={
                      <Tooltip title={configData[21].title}>
                        {configData[21].title}
                      </Tooltip>
                    }
                  >
                    <Menu.Item key={configData[22].key}>
                      {configData[22].title}
                    </Menu.Item>
                    <Menu.Item key={configData[23].key}>
                      {configData[23].title}
                    </Menu.Item>
                    <Menu.Item key={configData[24].key}>
                      {configData[24].title}
                    </Menu.Item>
                    <Menu.Item key={configData[25].key}>
                      {configData[25].title}
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
                */}
    <SubMenu
                  key={configData[26].key}
                    icon={<UserSwitchOutlined/>}
                  title={
                    <Tooltip title={configData[26].title}>
                      {configData[26].title}
                    </Tooltip>
                  }
                >
                  <Menu.Item key={configData[27].key}>
                    {configData[27].title}
                  </Menu.Item> 
                  </SubMenu>
                
              </Menu>
            </Sider>
              {this.state.menuSelectedValue === configData[1].key ? (
                <Content style={{ width: "100%" }}>
                  <section className="h-100">
                    <div className="at-ov-wrapper">
                      <div className="at-ov-card-wrapper overflow-auto">
                        <div className="count">
                          <Row gutter={[16, 16]} className="w-100">
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <div
                                className="at-ov-card"
                                onClick={(e) => {
                                  this.props.history.push("/assignaudits");
                                }}
                              >
                                <div className="content">
                                  <p>{this.state.auditStarted}</p>
                                  <text>{Constants.ASSIGN_AUDITS}</text>
                                </div>
                                <span className="status-icon red">
                                  <Assign />
                                </span>
                              </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <div
                                className="at-ov-card"
                                onClick={(e) => {
                                  this.props.history.push("/auditreports");
                                }}
                              >
                                <div className="content">
                                  <p>{this.state.auditStarted}</p>
                                  <text>{Constants.AUDIT_REPORTS}</text>
                                </div>
                                <span className="status-icon blue">
                                  <Report />
                                </span>
                              </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <div
                                className="at-ov-card"
                                onClick={(e) => {
                                  this.props.history.push("/upcomingaudits");
                                }}
                              >
                                <div className="content">
                                  <p>{this.state.upcomingAudits}</p>
                                  <text>{Constants.UPCOMING_AUDITS}</text>
                                </div>
                                <span className="status-icon orange">
                                  <Upcoming />
                                </span>
                              </div>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                              <div
                                className="at-ov-card"
                                onClick={(e) => {
                                  this.props.history.push("/archivedprojects");
                                }}
                              >
                                <div className="content">
                                  <p>{this.state.archivedProjects}</p>
                                  <text>{Constants.ARCHIVED_PROJECTS}</text>
                                </div>
                                <span className="status-icon green">
                                  <Archived />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="chart me-3">
                          <div className="at-ov-card">
                            <Pie {...config} />
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </section>
                </Content>
              ) : (
                <React.Fragment>
                {this.state.menuSelectedValue === configData[27].key && (
                  <UserManagementComponent />
                )}
                {this.state.menuSelectedValue !== configData[27].key && (    
                  <CheckListComponent keyPath={this.state.menuSelectedValue} 
                  auditTypeDetails={this.state.auditTypesDetails}
                  projectAuditTypeList={this.state.projectAuditTypeList}
                  allAuditTypes={this.state.allAuditTypes}
                  getAuditTypeDetail={this.getAuditTypeDetails}
  
                  />
                )}
              </React.Fragment>  
              )}
            </Layout>
          </Layout>
        </Spin>
      </>
    );
  }
}
export default AdminDashboard;
