import * as React from "react";
import { Component } from "react";
import { Form, Input, Modal, Select, Button } from "antd";
import { FormInstance } from "antd/lib/form";
import { PlusOutlined } from "@ant-design/icons";
import { api } from "../../apiServices/apiServices";

const { Option } = Select;
const { TextArea } = Input;

export class AddQuestions extends Component<any, any> {
    formRef = React.createRef<FormInstance>();
    constructor(props:any) {
      super(props);
      this.state = {
        openForm: this.props.visible,
        activeCategories: [],
        openAddCategoryForm: false,
        hiddenProjectTypes: true,
        selectedQuestion: this.props.selectedQuestion,
        selectedCategory: this.props.dropdownSelectedCategoryId
      };
    }
  
    componentDidMount() {
      this.setState({
          activeCategories: this.props.category,
          selectedQuestion: this.props.selectedQuestion,
          openForm: this.props.visible,   
      });
    }

    componentDidUpdate() {
      if(this.state.activeCategories?.length !== this.props.category?.length){
        this.setState({
          activeCategories: this.props.category,
          selectedQuestion: this.props.selectedQuestion,
          openForm: this.props.visible,
          selectedCategory: this.props.dropdownSelectedCategoryId,
    
      });
      }
    }

    onCategoryAddCancel = () =>{
      this.setState({ openAddCategoryForm: false });
    }

    navigateToAddCategory = async (e: any) => {
      // this.setState({ openAddCategoryForm: true });
      this.props.manageAddCategory();
      this.setState({selectedCategory: this.state.selectedCategory});
      this.formRef.current?.resetFields(['categoryName']);
    }

    OnAddorUpdateQuestion = async() => {
      if (this.formRef.current) {
        this.formRef.current
          .validateFields()
          .then(async(values) => {
            
              await this.addorUpdateQuestion(values);
            // this.props.filterList(values.category)
            this.props.setFiltersAfterRefresh();
          });
      }  
    }

    OnAddorUpdateQuestionAgain = async() => {
        if (this.formRef.current) {
          this.formRef.current
            .validateFields()
            .then(async(values) => {

              if(this.props.validateField(values.Question)){
                  let response;
                  values.projectAuditType = this.props.selectedCategory;
                  
                  response = values.id ? await api.updateQuestion(values.id, values) : await api.addQuestionByCategory(values);
                  response = await api.addQuestionByCategory(values);

                  if (!(response.status && response.status === 500) && !(response.errors)) {
                      this.props.onSuccess("Question added successfully");
                      await this.props.onRefresh();
                      this.formRef.current?.resetFields(['question']);
                      this.formRef.current?.resetFields(['notes']);
                      // this.props.updateFilterValues();
                      this.props.setFiltersAfterRefresh();
                  }
                  else{
                      this.props.onError();
                  }
                }
            });
        }
    }

    addorUpdateQuestion = async(data: any) =>{
      if(this.props.validateField(data.question)){
        let response;
        data.projectAuditType = this.props.selectedCategory;
        

        response = data.id ? await api.updateQuestion(data.id, data) : await api.addQuestionByCategory(data);

        if (!(response.status && response.status === 500) && !(response.errors)) {
          await this.props.onRefresh();
          data.id ? this.props.onSuccess("Question updated successfully") : this.props.onSuccess("Question added successfully");
          this.props.onCancel();
          // this.props.updateFilterValues();
        }
        else{
          this.props.onError();
        }
      }

    }

    pickCategory = (value: any) => {
        this.setState({
            selectedCategory: value
        })
    }

    // validateFields = (value: string) =>{
    //   let regex = /^[ A-Za-z0-9_@./#&+-]*$/;
    //   if(!regex.test(value)){
    //     this.props.onValidationError();
    //     return false
    //   }
    //   else
    //     return true;
    // }

    render() {
        return (
          <div>
            <Modal
              visible={this.state.openForm}
              maskClosable={false}
              title={(this.state.selectedQuestion?.id ? "Update" : "Add") + " Question"}
              onCancel={this.props.onCancel}
              footer={[
                <Button key="category"
                        icon={<PlusOutlined />}
                        onClick={this.navigateToAddCategory}
                        style={{ float: "left" }} type="primary"
                        >Add Category</Button>,
                <Button key="save"
                          onClick={this.OnAddorUpdateQuestion}
                          type="primary"
                  >{this.state.selectedQuestion?.id ? "Update" : "Add"}
                </Button>,
                <Button key="next"
                        hidden={this.state.selectedQuestion?.id ? true : false}
                        onClick={this.OnAddorUpdateQuestionAgain}
                        type="primary"
                        >Add & Next</Button>,
                <Button key="cancel"
                        onClick={this.props.onCancel}
                        >Cancel</Button>,
                ]}
              >
              <Form
                layout="vertical"
                ref={this.formRef}
                name="form_in_modal"
                initialValues={{
                id: this.state.selectedQuestion?.id,
                category: this.state.selectedCategory ? this.state.selectedCategory : this.state.selectedQuestion?.Category_ID,
                question: this.state.selectedQuestion?.Question  ,
                notes: this.state.selectedQuestion?.Notes != "undefined" ? this.state.selectedQuestion?.Notes : ""         
                }}
                >
                <Form.Item style={{ display: 'none' }} name="id" >
                  <Input />
                </Form.Item>
                <Form.Item name="category" label="Category"
                  rules={[{ required: true, message: "Please select a Category" }]}>
                  <Select onChange={this.pickCategory}
                      placeholder="Select an option"
                      >
                      {this.state.activeCategories.map((category: { Category_ID: number; Category_Name: string; }, index: number) => {
                        return <React.Fragment key={index}>
                        <Option value={category.Category_ID}>{category.Category_Name}</Option>
                      </React.Fragment>
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="question" label="Question"
                  rules={[
                      { required: true , message: 'Please enter a question'},
                      { max: 500 , message: 'Maximum 500 characters allowed'}
                      ]}>
                  <TextArea allowClear placeholder="Question"/>
                </Form.Item>
                <Form.Item name="notes" label="Notes"
                  rules={[
                      {   message: 'Please add notes'},
                          ]}>
                  <TextArea allowClear placeholder="Notes"  />
                </Form.Item>
              </Form>
            </Modal>

            {/* <div>
              <Modal
                visible={this.state.openAddCategoryForm}
                title="Add Category"
                maskClosable={false}
                onCancel={this.onCategoryAddCancel}
                onOk={this.OnCreateCategory}
                okText="Add"
              >
              <Form
                layout="vertical"
                ref={this.formRef}
                name="categoryAddModal"
                >
                <Form.Item name="categoryName" label="Category"
                  rules={[{ required: true, message: 'Category name required' }]}>
                  <Input allowClear placeholder="Category name"/>
                </Form.Item>
              </Form>
            </Modal>
            </div> */}
          </div>
        );
      }
    }
    